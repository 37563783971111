import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  SecondaryActionButton,
} from "../../reusableStyles";
import { Button, Skeleton } from "antd";
import { Divider } from "@mui/material";
import {
  BarChartRounded,
  Close,
  Download,
  DownloadOutlined,
  EmailOutlined,
  Mail,
  PersonAddRounded,
  Phone,
  PhoneOutlined,
  Search,
} from "@mui/icons-material";
import InstaLogo from "../../ImageAssets/insta_logo.png";
import user from "../../ImageAssets/user.jpg";
import { UsernameText } from "./InstagramContactNameAndUsername";
import {
  handleOpenInNew,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../../utils";
import SectionHeader from "../../CreateOffering/Components/SectionHeader";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchInstagramContacts } from "../../Api/ApiEndPoints";
import { Parser } from "@json2csv/plainjs";
import { formatPartialContactData } from "./ContactListTable";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import InstagramContactsStats from "./InstagramContactsStats";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { automationTypes } from "./InstaCommentToDMOtherAutomations";

function ResponsiveContactsList({
  mediaId,
  partial,
  setShowContacts,
  headerText,
  subText,
  automationId,
  otherAutomations,
  showContactInformation,
}) {
  const array = new Array(3).fill(0);
  const [tempList, setTempList] = useState([]);
  const [mainList, setMainList] = useState([]);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const { integrations } = useSelector((state) => state.user.integrations);
  const [statsData, setStatsData] = useState({});
  const [stats, setStats] = useState(
    isNullOrUndefined(automationId)
      ? [
          {
            name: "Total People Engaged",
            icon: <BarChartRounded style={{ fontSize: "18px" }} />,
            color: primaryActionColor,
            backgroundColor: primaryActionColorLight,
            key: "total",
          },
          {
            name: "Followers gained",
            icon: <PersonAddRounded style={{ fontSize: "18px" }} />,
            color: "#6A1E55",
            key: "newFollowers",
            backgroundColor: "#6A1E5533",
          },
          {
            name: "E-Mails collected",
            icon: <Mail style={{ fontSize: "18px" }} />,
            color: primaryActionColor,
            key: "emails",
            backgroundColor: primaryActionColorLight,
          },
          {
            name: "Numbers collected",
            icon: <Phone style={{ fontSize: "18px" }} />,
            color: "#684EA6",
            key: "numbers",
            backgroundColor: "#E2D9F7",
          },
        ]
      : []
  );
  const clientInstaIntegration = integrations.find(
    (integration) => integration.id === "instagram_connect"
  );

  const handleSearch = (text) => {
    if (isStringNullOrUndefined(text)) return setTempList(mainList);

    const arr = mainList?.filter((item) =>
      item.name?.toLowerCase()?.includes(text.toLowerCase())
    );
    setTempList(arr);
  };

  const handleExportContacts = async () => {
    try {
      const parser = new Parser();
      const mainList = formatPartialContactData(tempList);
      if (mainList.length === 0) {
        return enqueueSnackbar("No data to export", {
          variant: "error",
        });
      }
      const csv = parser.parse(mainList);
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute(
        "download",
        partial
          ? `Instagram_Comment_to_DM_Automation_Contacts`
          : `Instagram_Comment_to_DM_Automation_All_Contacts`
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const handleSetStats = () => {
    const stats = [
      {
        name: "People Engaged",
        icon: <BarChartRounded style={{ fontSize: "18px" }} />,
        color: primaryActionColor,
        key: "total",
        backgroundColor: primaryActionColorLight,
      },
    ];
    if (
      otherAutomations.some(
        (automation) => automation.type === automationTypes.REQUEST_TO_FOLLOW
      )
    ) {
      stats.push({
        name: "Followers gained",
        icon: <PersonAddRounded style={{ fontSize: "18px" }} />,
        color: "#6A1E55",
        key: "newFollowers",
        backgroundColor: "#6A1E5533",
      });
    }
    if (showContactInformation) {
      stats.push({
        name: "E-Mails collected",
        icon: <Mail style={{ fontSize: "18px" }} />,
        color: primaryActionColor,
        key: "emails",
        backgroundColor: primaryActionColorLight,
      });
    }
    if (showContactInformation) {
      stats.push({
        name: "Numbers collected",
        icon: <Phone style={{ fontSize: "18px" }} />,
        color: "#684EA6",
        key: "numbers",
        backgroundColor: "#E2D9F7",
      });
    }

    setStats(stats);
  };

  useEffect(() => {
    const handleGetContactList = async () => {
      const response = await callNonStoreApi(FetchInstagramContacts, {
        mediaId: mediaId,
        instaUserId: clientInstaIntegration.instaUserId,
      });
      setMainList(response.data.contacts || []);
      setTempList(response.data.contacts || []);
      setStatsData(response.data.stats || {});

      setIsApiLoading(false);
    };
    handleGetContactList();
    if (automationId) handleSetStats();
  }, []);

  return (
    <AddFlex flexDirection="column" gap="20px" padding="20px" width="100%">
      {/* <InstagramContactsStats statsData={statsData} stats={stats} /> */}

      <AddFlex gap="20px" wrap="wrap">
        <AddFlex grow={1}>
          <SectionHeader
            headerText={headerText ? headerText : `Contacts`}
            style={{
              width: "100%",
              paddingLeft: "10px",
              borderLeft: "4px solid #FFA500",
            }}
            subHeaderText={
              subText ? subText : "People who engaged with this automation"
            }
            maxSubTextWidth={"100%"}
          />
          {setShowContacts && <Close onClick={() => setShowContacts(false)} />}
        </AddFlex>
        <AddFlex gap="10px" width="100%">
          <CustomInput
            width="70%"
            placeholder={"Search by name or username"}
            onChange={(text) => {
              handleSearch(text);
            }}
            leftWidget={<Search sx={{ color: "#888888" }} />}
          />
          <Button style={{ height: "36px" }} onClick={handleExportContacts}>
            Export
          </Button>
        </AddFlex>
      </AddFlex>
      <AddFlex
        flexDirection="column"
        gap="10px"
        style={{ overflow: "scroll", paddingBottom: "10vh" }}
      >
        {isApiLoading
          ? array.map(() => <ContactCardSkeleton />)
          : tempList?.map((contact) => <ContactCard contact={contact} />)}
      </AddFlex>
    </AddFlex>
  );
}

export default ResponsiveContactsList;

const ContactCard = ({ contact }) => {
  return (
    <AddFlex
      padding="20px"
      borderRadius="24px"
      width="100%"
      height="max-content"
      gap="10px"
      border="2px solid #ececec"
      flexDirection="column"
      backgroundColor="white"
    >
      <AddFlex gap="10px" width="100%">
        <img
          src={contact.profile_pic || user}
          style={{ width: "32px", height: "32px", borderRadius: "50%" }}
        />
        <AddFlex gap="5px" flexDirection="column" width="100%">
          <CustomText color="black">
            {contact.name || "Instagram User"}
          </CustomText>
          {contact?.username && (
            <AddFlex gap="4px" alignItems="center">
              <img src={InstaLogo} style={{ width: "15px", aspectRatio: 1 }} />
              <UsernameText
                color="black"
                fontSize="14px"
                onClick={() => {
                  handleOpenInNew(`https://instagram.com/${contact?.username}`);
                }}
              >
                {contact?.username}
              </UsernameText>
            </AddFlex>
          )}{" "}
        </AddFlex>
      </AddFlex>
      <Divider sx={{ flexShrink: 0 }} />
      <AddFlex alignItems="center" gap="10px">
        <EmailOutlined style={{ height: "16px", width: "16px" }} />
        <CustomText color="black">{contact.email || "-"}</CustomText>
      </AddFlex>
      <AddFlex alignItems="center" gap="10px">
        <PhoneOutlined style={{ height: "16px", width: "16px" }} />
        <CustomText color="black">{contact.phoneNumber || "-"}</CustomText>
      </AddFlex>
    </AddFlex>
  );
};

const ContactCardSkeleton = () => {
  return (
    <AddFlex
      padding="20px"
      borderRadius="24px"
      width="100%"
      height="max-content"
      gap="10px"
      border="2px solid #ececec"
      flexDirection="column"
      backgroundColor="white"
    >
      <AddFlex gap="10px" width="100%">
        <Skeleton.Avatar />
        <AddFlex gap="5px" flexDirection="column" width="100%">
          <Skeleton.Input
            active={true}
            size="large"
            style={{ height: "16px" }}
          />
          <Skeleton.Input size="small" style={{ height: "14px" }} />
        </AddFlex>
      </AddFlex>
      <Divider sx={{ flexShrink: 0 }} />
      <AddFlex alignItems="center" gap="10px">
        <Skeleton.Node style={{ height: "16px", width: "16px" }} />
        <Skeleton.Node
          size="small"
          style={{ height: "16px", width: "160px", margin: 0 }}
        />
      </AddFlex>
      <AddFlex alignItems="center" gap="10px">
        <Skeleton.Node style={{ height: "16px", width: "16px" }} />
        <Skeleton.Node
          size="small"
          style={{ height: "16px", width: "160px", margin: 0 }}
        />
      </AddFlex>
    </AddFlex>
  );
};
