import styled from "styled-components";
import {
  headingSectionLeftBorderColor,
  inputHeight,
  primaryActionColor,
  primaryActionColorLight,
} from "./constants";
import { isNullOrUndefined } from "./utils";

import { Calendar } from "react-multi-date-picker";
import {
  Box,
  Button,
  CircularProgress,
  Tooltip,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { Close } from "@mui/icons-material";
import TextEditor from "./CreateOffering/TextEditor";
import TextArea from "antd/es/input/TextArea";
import { Input } from "antd";

export const AddFlexCol = styled.div`
  display: flex;
  position: ${(props) => props.position && props.position};
  padding: ${(props) => props.padding && props.padding};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  cursor: ${(props) => props.cursor && props.cursor};
  flex-direction: column;
  justify-content: ${(props) => props.justify && props.justify};
  align-items: ${(props) => props.align && props.align};
  margin: ${(props) => props.margin && props.margin};
  max-width: ${(props) => props.width && props.width};
  min-width: ${(props) => props.width && props.width};
  min-height: ${(props) => props.height && props.height};
  flex-grow: ${(props) => props.flexGrow && props.flexGrow};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};
  border: ${(props) => props.border && props.border};
  transition: ${(props) =>
    props.transition ? props.transition : "all 0.3s ease-in-out"};
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
`;

export const AddFlex = styled.div`
  display: flex;
  flex-direction: ${(props) =>
    props.flexDirection ? props.flexDirection : "row"};
  align-items: ${(props) => props.alignItems && props.alignItems};
  justify-content: ${(props) => props.justify && props.justify};
  flex-wrap: ${(props) => props.wrap && props.wrap};
  flex-grow: ${(props) => props.grow && props.grow};
  position: ${(props) => (props.position ? props.position : "relative")};
  color: ${(props) => props.color && props.color};
  cursor: ${(props) => props.pointer && "pointer"};
  padding: ${(props) => props.padding && props.padding};
  margin-top: ${(props) => props.marginTop && props.marginTop};
  margin-bottom: ${(props) => props.marginBottom && props.marginBottom};
  margin-left: ${(props) => props.marginLeft && props.marginLeft};
  margin-right: ${(props) => props.marginRight && props.marginRight};
  margin: ${(props) => props.margin && props.margin};
  transition: ${(props) => (props.transition ? props.transition : "none")};
  background-color: ${(props) =>
    props.backgroundColor && props.backgroundColor};
  border-bottom: ${(props) => props.borderBottom && props.borderBottom};
  border: ${(props) => props.border && props.border};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  z-index: ${(props) => props.zIndex && props.zIndex};
  min-width: ${(props) => props.minWidth && props.minWidth};
  min-height: ${(props) => props.minHeight && props.minHeight};
  width: ${(props) => props.width && props.width};
  height: ${(props) => props.height && props.height};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  gap: ${(props) => props.gap && props.gap};
  bottom: ${(props) => props.bottom && props.bottom};
  /* &:last-child {
    border-bottom: none;
    border: ${(props) => props.border && props.border};
  } */
  &:hover {
    color: ${(props) => props.hoverColor && props.hoverColor};
  }
  &::-webkit-scrollbar {
    display: none;
    width: 0px;
  }
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  /* width: 100%; */
  margin: ${(props) => props.margin && props.margin};
  padding: 0.5em;
  margin-top: 0.5em;
  border-radius: 5px;
  border: none;
  outline: none;
  border: ${(props) =>
    props.cancel
      ? "1px solid #ececec"
      : props.border
      ? props.border
      : "1px solid black"};
  background-color: ${(props) =>
    props.secondary
      ? "#fff"
      : props.backgroundColor
      ? props.backgroundColor
      : "#000"};
  color: ${(props) => (props.secondary ? "#000" : "#fff")};
  cursor: pointer;
`;

export const Group = styled.div`
  padding: ${(props) =>
    props.padding ? props.padding : "27px 41px 16px 41px"};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "white"};
  border-radius: ${(props) =>
    props.borderRadius ? props.borderRadius : "8px"};
  box-shadow: ${(props) =>
    props.boxShadow ? props.boxShadow : "0px 0px 4px 1px rgba(0, 0, 0, 0.15)"};
  margin-top: ${(props) => (props.marginTop ? props.marginTop : "25px")};
  margin: ${(props) => props.margin && props.margin};
  @media screen and (max-width: 1000px) {
    padding: ${(props) => (props.padding ? props.padding : "15px 10px")};
  }
`;

export const FullPageParent = styled(AddFlex)`
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  overflow: "scroll";
`;

export const InputLabel = styled.label`
  color: ${(props) => (props.labelColor ? props.labelColor : "#101828")};
  font-weight: 500;
  font-size: 14px;
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "12px"};
`;
export const CustomText = styled.p`
  position: ${(props) => props.position && props.position};
  top: ${(props) => props.top && props.top};
  left: ${(props) => props.left && props.left};
  right: ${(props) => props.right && props.right};
  bottom: ${(props) => props.bottom && props.bottom};
  color: ${(props) => (props.color ? props.color : "#667085")};
  font-size: ${(props) => props.fontSize && props.fontSize};
  font-weight: ${(props) => props.fontWeight && props.fontWeight};
  font-style: ${(props) => props.fontStyle && props.fontStyle};
  margin: ${(props) => props.margin && props.margin};
  text-decoration: ${(props) => props.textDecoration && props.textDecoration};
  text-align: ${(props) => props.textAlign && props.textAlign};
  display: -webkit-box;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
  line-clamp: ${(props) => props.lineClamp && props.lineClamp};
  -webkit-line-clamp: ${(props) => props.lineClamp && props.lineClamp};
  -webkit-box-orient: vertical;
`;

export const Page = styled.div`
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  position: relative;
  width: 100%;
  min-height: ${(props) => props.minHeight && props.minHeight};
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin && props.margin};
`;

export function TextAreaType({
  value,
  label,
  height,
  required,
  onChange,
  isPreview,
  style,
}) {
  const textareaRef = useRef(null);

  const handleInput = () => {
    textareaRef.current.style.height = "auto";
    textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
  };

  useEffect(() => {
    handleInput(); // Adjust the height initially
  }, []);

  return (
    <AddFlex
      flexDirection="column"
      ref={textareaRef}
      style={style}
      height={height + "px"}
    >
      {/* {label && <InputLabel>{label}</InputLabel>} */}
      <TextEditor
        value={value}
        height={height + "px"}
        label={label}
        getTextEditorInput={(text) => {
          onChange(text);
        }}
      />
    </AddFlex>
  );
}

export const CustomInput = ({
  value,
  onClick,
  onChange,
  label,
  type,
  hideCursor,
  max,
  min,
  placeholder,
  widget,
  width,
  footerText,
  onLeftWidgetClick,
  leftBackgroundColor,
  footerTextColor,
  leftWidget,
  margin,
  inputStyle,
  style,
  disabled,
  required,
  backgroundColor,
  borderColor,
  onRightWidgetClick,
  labelColor,
}) => {
  const inputRef = useRef();
  const handleWheel = (e) => {
    if (type === "number") e.preventDefault(); // Prevent the default scroll behavior
  };
  useEffect(() => {
    const inputElement = inputRef.current;

    if (inputElement) {
      inputElement.addEventListener("wheel", handleWheel);

      return () => {
        inputElement.removeEventListener("wheel", handleWheel);
      };
    }
  }, []);
  return (
    <AddFlex
      margin={margin}
      flexDirection="column"
      width={width ? width : "100%"}
      onClick={(e) => {
        if (isNullOrUndefined(onClick)) {
          return;
        } else {
          inputRef.current.focus();
          onClick(e);
        }
      }}
      style={style}
    >
      {label && (
        <InputLabel labelColor={labelColor ? labelColor : "#101828"}>
          {label}{" "}
          {required && (
            <span style={{ color: "red", fontSize: "16px" }}>*</span>
          )}
        </InputLabel>
      )}
      <AddFlex
        width="100%"
        margin="0px 0 0 0"
        borderRadius="5px"
        border={
          disabled
            ? "1px solid #D9D9D9"
            : `1px solid ${borderColor ? borderColor : "#d0d5dd"}`
        }
        style={inputStyle}
      >
        {leftWidget && (
          <AddFlex
            justify="center"
            alignItems="center"
            backgroundColor={leftBackgroundColor ? leftBackgroundColor : "#fff"}
            style={{
              padding: "5px",
              minWidth: "40px",
              borderRadius: "5px 0px 0px 5px",
              height: inputHeight,
              // backgroundColor: "#ececec",
              borderRight: "1px solid #ececec",
              overflow: "hidden",
            }}
            onClick={() => {
              if (onLeftWidgetClick) onLeftWidgetClick();
            }}
          >
            {leftWidget}
          </AddFlex>
        )}
        <input
          value={value}
          ref={inputRef}
          type={type}
          max={max}
          min={min}
          disabled={disabled}
          required={required}
          placeholder={placeholder}
          style={{
            flexGrow: 1,
            borderRadius: "5px",
            outline: "none",
            backgroundColor: backgroundColor ? backgroundColor : "white",
            border: "none",
            padding: "10px 15px",
            caretColor: hideCursor && "transparent",
            color: disabled && "#B8B9BB",
            height: inputHeight,
            cursor: disabled && "not-allowed",
          }}
          onChange={(e) => {
            if (onChange) onChange(e.target.value);
          }}
        />
        {widget && (
          <AddFlex
            justify="center"
            alignItems="center"
            style={{
              padding: "10px",
              minWidth: "40px",
              borderRadius: "0 5px 5px 0",
              height: inputHeight,
              backgroundColor: backgroundColor ? backgroundColor : "white",
              borderLeft: "1px solid #ececec",
              cursor: "pointer",
            }}
            onClick={() => {
              if (onRightWidgetClick) onRightWidgetClick();
            }}
          >
            {widget}
          </AddFlex>
        )}
      </AddFlex>
      {footerText && (
        <CustomText fontSize="13px" margin="8px 0 0 0" color={footerTextColor}>
          {footerText}
        </CustomText>
      )}
    </AddFlex>
  );
};

export const CustomCalendarComponent = ({
  setSelectedDates,
  isLoading,
  closeDialog,
  selectedDates,
  onSubmit,
  minDate,
  maxDate,
  multiple,
}) => {
  return (
    <Calendar
      onChange={(dates) => setSelectedDates(dates)}
      inline
      multiple={multiple}
      minDate={minDate ? minDate : new Date()}
      maxDate={maxDate}
      value={selectedDates}
      showPreviousMonths={false}
      sort={true}
      plugins={[
        <Footer
          position="bottom"
          isLoading={isLoading}
          closeDialog={closeDialog}
          onSubmit={onSubmit}
        />,
      ]}
    />
  );
};

const Footer = ({ isLoading, onSubmit, closeDialog }) => {
  return (
    <AddFlex gap="10px" width="100%" padding="10px" flexDirection="column">
      <PrimaryActionButton onClick={onSubmit}>
        {isLoading && (
          <CircularProgress
            size={18}
            sx={{ color: "white", marginRight: "10px" }}
          />
        )}
        <CustomText color="white">Select date</CustomText>
      </PrimaryActionButton>
      <SecondaryActionButton textOnly onClick={closeDialog} secondary>
        Cancel
      </SecondaryActionButton>
    </AddFlex>
  );
};
export const StyledLink = styled(Link)`
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => props.fontSize && props.fontSize};
  display: flex;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : "6px 6px 6px 14px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 0")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  border-radius: 4px;
  font-family: Inter;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  color: white;
  text-decoration: none;
  &:hover {
    /* background-color: #f9f5ff; */
  }
`;

export const CustomInputArea = styled.textarea`
  resize: none;
  width: ${(props) => (props.width ? props.width : "100%")};
  height: 90px;
  color: #252b3a;
  /* font-size: 16px; */
  padding: 10px;
  border: 1px solid #dddddd; // Initial border color
  border-radius: 5px; // Rounded corners for the textarea
  resize: none; // Removes the default resize handle
  outline: none; // Removes the outline on focus
  transition: border-color 0.3s; // Smooth transition for border color change

  /* &:focus {
    border-color: #26a68b; // Change border color on focus
  } */
  &::placeholder {
    font-size: 14px;
  }
`;

export const CustomSimpleInput = styled.input`
  width: ${(props) => (props.width ? props.width : "100%")};
  font-size: 16px;
  padding: 10px;
  border: 1px solid #dddddd; // Initial border color
  border-radius: 5px; // Rounded corners for the textarea
  resize: none; // Removes the default resize handle
  outline: none; // Removes the outline on focus
  transition: border-color 0.3s; // Smooth transition for border color change
  &:focus {
    border-color: ${(props) =>
      props.borderColor
        ? props.borderColor
        : primaryActionColor}; // Change border color on focus
  }
`;

export const FieldLabelText = styled.label`
  font-weight: 500;
  color: ${"#344054"};
  font-size: 14px;
  margin-bottom: 0px;
  margin: ${(props) => props.margin && props.margin};
`;

export const CustomInputLabel = styled.label`
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-weight: 500;
  color: ${(props) => (props.color ? props.color : "#344054")};
  display: ${(props) => (props.display ? props.display : "block")};
  margin-bottom: ${(props) =>
    props.marginBottom ? props.marginBottom : "8px"};
  font-family: ${(props) => (props.font ? props.font : "inter")};
`;
export const CustomButton = styled.button`
  font-size: 16px;
  cursor: pointer;
  padding: 6px 25px;
  width: max-content;
  background-color: ${(props) =>
    props.buttonType === "solid" ? props.color : "#ffffff"};
  border: 0.5px solid
    ${(props) => (props.buttonType === "solid" ? props.color : "#444444")};
  color: ${(props) => (props.buttonType === "solid" ? "#ffffff" : "#444444")};
`;

export const PrimaryActionButton = styled.button`
  display: flex;
  outline: none;
  border: 1px solid transparent;
  padding: ${(props) => (props.padding ? props.padding : "8px 32px")};
  transition: all 0.3s ease-in-out;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: ${(props) =>
    props.backgroundColor ? props.backgroundColor : primaryActionColor};
  color: #fff;
  font-size: 14px;
  cursor: pointer;
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 600)};
  line-height: 18px; /* 128.571% */
  margin: ${(props) => props.margin && props.margin};
  &:hover {
    background-color: ${primaryActionColorLight};
    color: ${primaryActionColor};
    border: 1px solid ${primaryActionColor};
  }
  &:disabled {
    background-color: #f5f5f5;
    color: #b8b8b8;
    border: 1px solid #b8b8b8;
  }
`;

export const SecondaryActionButton = styled.button`
  display: flex;
  padding: ${(props) =>
    props.textOnly ? 0 : props.padding ? props.padding : "8px 32px"};
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: ${(props) =>
    props.textOnly ? "none" : props.borderRadius ? props.borderRadius : "4px"};
  cursor: pointer;
  border: 1px solid
    ${(props) =>
      props.textOnly
        ? "transparent"
        : props.borderColor
        ? props.borderColor
        : primaryActionColor};
  background: ${(props) =>
    props.textOnly
      ? "transparent"
      : props.background
      ? props.background
      : "#fff"};
  color: ${(props) => (props.color ? props.color : primaryActionColor)};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "14px")};
  font-style: normal;
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 500)};
  line-height: 18px; /* 128.571% */
  margin: ${(props) => props.margin && props.margin};
`;

export const Gap = styled.div`
  flex-grow: 1;
`;

export const Header = styled.div`
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px; /* 90% */
  letter-spacing: -0.4px;
`;

export const SubHeader = styled.div`
  color: #000;
  font-size: 12px;
  font-weight: 500;
  /* line-height: 18px; 90% */
  letter-spacing: 0.3px;
`;

export const Icon = styled.img`
  width: 24px;
  height: 24px;
  @media screen and (max-width: 1024px) {
    width: 18px;
    height: 18px;
  }
`;

export const Seperator = styled.div`
  width: 100%;
  border-bottom: 1px solid ${(props) => (props.color ? props.color : "#eaecf0")};
`;

export const CustomToolTip = styled(Tooltip)`
  & .${tooltipClasses.tooltip} {
    font-family: "Outfit";
  }
`;

export const Arrow = styled.span`
  color: ${primaryActionColor};
  font-size: 1.3rem;
`;

export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 18px;
`;
const HeaderDescription = styled.div`
  font-size: 14px;
  margin: 4px 0 0 0;
  width: 70%;
  color: #667085;
`;

const Wrapper = styled.div`
  padding-left: 16px;
  border-left: 4px solid ${primaryActionColor};
`;

export const SectionHeaderFancy = ({ Title, Description }) => {
  return (
    <Wrapper>
      <HeaderTitle>{Title}</HeaderTitle>
      <HeaderDescription>{Description}</HeaderDescription>
    </Wrapper>
  );
};

export const ContentHeader = styled.p`
  margin-bottom: 5px;
  padding: ${(props) => props.padding && props.padding};
  margin: ${(props) => props.margin && props.margin};
  color: ${(props) => (props.color ? props.color : "#101828")};
  font-size: ${(props) => (props.fontSize ? props.fontSize : "16px")};
  font-weight: ${(props) => (props.fontWeight ? props.fontWeight : 400)};
`;

export const GradientOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  background-image: ${(props) =>
    props.gradient
      ? props.gradient
      : "linear-gradient(to top,rgba(1, 1, 1, 0),rgba(0, 0, 0, 0.72))"};
`;

export const ImageContainer = styled.div`
  position: relative;
  width: ${(props) =>
    props.width
      ? props.width
      : "100%"}; /* Adjust the width and height to match your image dimensions */
  height: ${(props) => (props.height ? props.height : "300px")};
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
  box-shadow: ${(props) => props.boxShadow};
`;

export const EventBannerImage = styled.img`
  width: 100%;
  object-fit: ${(props) => (props.objectFit ? props.objectFit : "cover")};
  height: 100%;
  z-index: 0;
  border-radius: ${(props) => props.borderRadius && props.borderRadius};
`;

export function CircularProgressWithLabel(props) {
  return (
    <Box
      sx={{
        position: "relative",
        display: "inline-flex",
      }}
    >
      <CircularProgress size={30} variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <CustomText
          fontSize="9px"
          variant="caption"
          component="div"
          color={props.sx?.color ? props.sx.color : primaryActionColor}
          fontWeight="700"
        >
          {`${Math.round(props.value)}%`}
        </CustomText>
      </Box>
    </Box>
  );
}

export const ButtonWithLoader = ({ loading, title, onClick }) => {
  return (
    <PrimaryActionButton
      onClick={onClick}
      padding="10px 30px"
      style={{ width: "85%", fontWeight: "600", height: inputHeight }}
    >
      {loading ? <CircularProgress size={16} sx={{ color: "white" }} /> : title}
    </PrimaryActionButton>
  );
};

export const HalfunderlineHeader = ({
  color,
  margin,
  title,
  fontSize,
  lineWidth,
  lineHeight,
}) => {
  return (
    <AddFlex gap="8px" margin={margin} flexDirection="column">
      <CustomText
        fontWeight="500"
        color={color ? color : "black"}
        fontSize={fontSize ? fontSize : "18px"}
      >
        {title}
      </CustomText>
      <div
        style={{
          height: lineHeight ? lineHeight : "2px",
          width: lineWidth ? lineWidth : "36px",
          borderRadius: "2px",
          backgroundColor: "rgba(252, 163, 17, 1)",
        }}
      ></div>
    </AddFlex>
  );
};

export const SelectFormDialogCard = styled(AddFlex)`
  width: 100%;
  flex-direction: column;
  padding: 12px 16px;
  border-radius: 4px;
  gap: 4px;
  border: ${(props) =>
    props.isSelected ? "1px solid " + primaryActionColor : "1px solid #d0d5dd"};
  box-shadow: ${(props) =>
    props.isSelected && "0 0 10px 1px rgba(0,102,218,0.1)"};
  &:hover {
    /* border: 1px solid ${primaryActionColor}; */
    background-color: ${primaryActionColorLight};
  }
`;

export const LeadCardWrapper = styled(AddFlex)`
  flex-direction: column;
  gap: 20px;
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.1);
  border-radius: 12px;
  padding-bottom: 12px;
  cursor: pointer;
  background-color: white;
  padding: 12px;
`;

export const YoutubeEmbeddIframe = styled.iframe`
  width: 580px;
  height: 313px;
  max-width: 600px;
  max-height: 400px;
  border-radius: 12px;
  @media screen and (max-width: 1000px) {
    width: 90vw;
    height: 30vh;
    /* border-radius: 24px; */
  }
`;

export const DialogHeader = ({
  mainText,
  subText,
  padding,
  style,
  onClose,
  subTextColor,
  subTextWeight,
  mainTextWeight,
  lineHeight,
}) => {
  return (
    <AddFlex
      alignItems="center"
      style={
        style
          ? style
          : {
              borderBottom: "1px solid #ececec",
              padding: padding,
              paddingBottom: "8px",
            }
      }
      justify="space-between"
    >
      <AddFlex gap="6px" width="100%" flexDirection="column">
        <AddFlex alignItems="center">
          <CustomText
            fontSize="18px"
            color="black"
            fontWeight={mainTextWeight ? mainTextWeight : "500"}
            style={{ lineHeight: lineHeight ? lineHeight : "18px" }}
          >
            {mainText}
          </CustomText>
          <Gap />
          <Close style={{ cursor: "pointer" }} onClick={onClose} />
        </AddFlex>
        {subText && (
          <CustomText
            color={subTextColor ? subTextColor : "#667085"}
            fontSize="14px"
            fontWeight={subTextWeight ? subTextWeight : "300"}
            style={{
              lineHeight: "18px",
              letterSpacing: "-0.14px",
              width: "80%",
            }}
          >
            {subText}
          </CustomText>
        )}
      </AddFlex>
    </AddFlex>
  );
};

export const HeaderWithLeftBorder = ({
  header,
  headerColor,
  borderColor,
  headerFontSize,
  headerFontWeight,
  leftStripBorderRadius,
}) => {
  return (
    <AddFlex gap="10px">
      <AddFlex
        width="3px"
        backgroundColor={
          borderColor ? borderColor : headingSectionLeftBorderColor
        }
        borderRadius={leftStripBorderRadius}
      ></AddFlex>
      <CustomText
        fontWeight={headerFontWeight ? headerFontWeight : 600}
        fontSize={headerFontSize}
        color={headerColor}
      >
        {header}
      </CustomText>
    </AddFlex>
  );
};

export const CustomTextArea = ({
  label,
  value,
  disabled,
  placeholder,
  onChange,
  showChar,
  maxCharLimit,
  footerTextColor,
  footerText,
  style,
}) => {
  return (
    <AddFlex
      flexDirection="column"
      onClick={(e) => {
        e.stopPropagation();
      }}
    >
      {label && <InputLabel>{label}</InputLabel>}
      <TextArea
        placeholder={placeholder}
        style={style}
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
        autoSize={{
          minRows: 5,
        }}
        disabled={disabled}
        className="textarea"
      />
      {footerText && (
        <CustomText fontSize="13px" margin="8px 0 0 0" color={footerTextColor}>
          {footerText}
        </CustomText>
      )}{" "}
    </AddFlex>
  );
};

export const CreateInstaAutomationInput = styled(Input)``;
