import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import {
  Instagram,
  Mail,
  Phone,
  QuestionAnswerOutlined,
} from "@mui/icons-material";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { capitalizeWords } from "../../utils";
import useWindowSize from "../../Hooks/useWindowSize";

const stats = {
  followers: {
    name: "Followers Gained",
    bg: "#8319FE0D",
    color: "#8319FE",
    icon: <Instagram />,
  },
  comments: {
    name: "Total Comments",
    bg: primaryActionColorLight,
    color: primaryActionColor,
    icon: <QuestionAnswerOutlined />,
  },
  phoneNumber: {
    name: "Phone numbers",
    bg: "#E2D9F759",
    color: "#684EA6",
    icon: <Phone />,
  },
  email: {
    name: "Emails",
    bg: "#8D0B410D",
    color: "#8D0B41",
    icon: <Mail />,
  },
};

function AutomationCard({ media, onClick }) {
  const { isMobileView } = useWindowSize();
  return (
    <AddFlex
      padding="8px"
      borderRadius="8px"
      gap="10px"
      width={isMobileView() ? "100%" : "31%"}
      style={{
        border: "1px solid #ececec",
        boxShadow: "0 0 1px 2px rgba(0,0,0,0.05)",
        cursor: "pointer",
      }}
      onClick={onClick}
    >
      <CustomText
        style={{
          position: "absolute",
          right: "10px",
          bottom: "10px",
          fontSize: "12px",
        }}
      >
        Edit
      </CustomText>
      <AddFlex
        borderRadius="25px"
        padding="5px 10px"
        alignItems="center"
        style={{
          position: "absolute",
          right: "10px",
          fontSize: "12px",
          fontWeight: "600",
          backgroundColor: media.status === "live" ? "#DCFCE8" : "#BCCCDC80",
          color: media.status === "live" ? "#166534" : "grey",
        }}
        gap="3px"
      >
        {capitalizeWords(media.status)}
      </AddFlex>
      <img
        src={media.mediaData.mediaUrl}
        style={{
          width: "72px",
          height: "80px",
          borderRadius: "8px",
          objectFit: "cover",
        }}
      />
      {media.stats && (
        <AddFlex gap="4px" flexDirection="column">
          {Object.entries(media.stats).map(([key, value]) =>
            stats[key] ? (
              <AddFlex
                gap="6px"
                padding="3px 10px"
                borderRadius="25px"
                backgroundColor={stats[key].bg}
                style={{ width: "max-content" }}
              >
                {React.cloneElement(stats[key].icon, {
                  sx: {
                    color: stats[key].color,
                    fontSize: "12px",
                  },
                })}
                <CustomText color={stats[key].color} fontSize="12px">
                  {`${stats[key].name} : ${value.total?.count}`}
                </CustomText>
              </AddFlex>
            ) : (
              <></>
            )
          )}
        </AddFlex>
      )}
      {/* <AddFlex gap="4px" flexDirection="column">
        {media.stats?.followers ? (
          <AddFlex
            gap="6px"
            padding="3px 10px"
            borderRadius="25px"
            backgroundColor={"#8319FE0D"}
            style={{ width: "max-content" }}
          >
            <Instagram sx={{ color: "#8319FE", fontSize: "12px" }} />
            <CustomText color={"#8319FE"} fontSize="12px">
              Followers gained : {media.stats?.followers?.count}
            </CustomText>
          </AddFlex>
        ) : (
          <></>
        )}
        <AddFlex
          gap="6px"
          padding="3px 10px"
          borderRadius="25px"
          backgroundColor={primaryActionColorLight}
          style={{ width: "max-content" }}
        >
          <QuestionAnswerOutlined
            sx={{ color: primaryActionColor, fontSize: "12px" }}
          />
          <CustomText fontSize="12px" color={primaryActionColor}>
            Total comments : {media.stats?.start?.count || 0}
          </CustomText>
        </AddFlex>
        {media.stats?.phoneNumber ? (
          <AddFlex
            gap="6px"
            padding="3px 10px"
            borderRadius="25px"
            backgroundColor={"#E2D9F759"}
            style={{ width: "max-content" }}
          >
            <Phone sx={{ color: "#684EA6", fontSize: "12px" }} />
            <CustomText color={"#684EA6"} fontSize="12px">
              Phone numbers: {media.stats?.phoneNumber.count}
            </CustomText>
          </AddFlex>
        ) : (
          <></>
        )}
        {media.stats?.email ? (
          <AddFlex
            gap="6px"
            padding="3px 10px"
            borderRadius="25px"
            backgroundColor={"#8D0B410D"}
            style={{ width: "max-content" }}
          >
            <Mail sx={{ color: "#8D0B41", fontSize: "12px" }} />
            <CustomText color={"#8D0B41"} fontSize="12px">
              Emails : {media.stats?.email}
            </CustomText>
          </AddFlex>
        ) : (
          <></>
        )}
      </AddFlex> */}
    </AddFlex>
  );
}

export default AutomationCard;
