import React, { useEffect, useState } from "react";
import { AddFlex, FullPageParent } from "../reusableStyles";
import OnboardingHeader from "./OnboardingHeader";
import OnboardingBody from "./OnboardingBody";
import OnboardingFooter from "./OnboardingFooter";
import styled from "styled-components";
import { useSelector } from "react-redux";
import useUsernameAvailabilityCheck from "../CustomHooks/useUsernameAvailabilityCheck";
import { callNonStoreApi } from "../NonStoreApis";
import { FetchUserLoginMetaData } from "../Api/ApiEndPoints";
import Loading from "../Loading";
import { enqueueSnackbar } from "notistack";
import { clientLoginSources } from "../utils";
import InstaDmIntegration from "./InstaDmIntegration";

const OnboardingWrapper = styled(AddFlex)`
  width: 35vw;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  @media screen and (max-width: 1024px) {
    width: 100%;
    margin: 0;
  }
`;

function OnboardingMain() {
  const [newUser, setNewUser] = useState({});
  const [loading, setLoading] = useState(true);
  const [stage, setStage] = useState(0);
  const {
    isAvailable,
    loading: isCheckingUsernameAvailability,
    isErr,
    errMsg,
    usernameSuggestions,
  } = useUsernameAvailabilityCheck("", newUser.userName);

  const handleSetNewUserAdditionalDetails = (key, value) => {
    setNewUser((prev) => ({
      ...prev,
      UserAdditionalDetails: {
        ...prev.UserAdditionalDetails,
        [key]: value,
      },
    }));
  };

  useEffect(() => {
    const fetchUserLoginMeta = async () => {
      setLoading(true);
      await callNonStoreApi(FetchUserLoginMetaData)
        .then((response) => {
          setNewUser({
            userId: response.data.uid,
            userName: "",
            Name: response.data.userPayload.displayName,
            userEmail: response.data.userPayload.email,
            photoURL: response.data.userPayload.photoURL,
            domain: response.data.userPayload.domain,
            source: response.data.userPayload.source,
          });
        })
        .catch((error) => {
          console.log(error);
          enqueueSnackbar("Something went wrong.", {
            variant: "error",
          });
        })
        .finally(() => {
          setLoading(false);
        });
    };
    fetchUserLoginMeta();
  }, []);

  return loading ? (
    <FullPageParent>
      <Loading />
    </FullPageParent>
  ) : (
    <AddFlex padding="30px" flexDirection="column" margin="20px 0 0 0">
      <OnboardingWrapper>
        <OnboardingHeader stage={stage} displayName={newUser.Name} />
        <OnboardingBody
          stage={stage}
          userAdditionalDetails={newUser.UserAdditionalDetails}
          handleSetNewUserAdditionalDetails={handleSetNewUserAdditionalDetails}
          name={newUser.Name}
          setNewUser={setNewUser}
          username={newUser.userName}
          isErr={!isCheckingUsernameAvailability && isErr}
          errMsg={isCheckingUsernameAvailability ? "" : errMsg}
          isAvailable={isAvailable}
          isCheckingUsernameAvailability={isCheckingUsernameAvailability}
        />
        <OnboardingFooter
          stage={stage}
          setStage={setStage}
          newUser={newUser}
          isErr={isErr}
          isCheckingUsernameAvailability={isCheckingUsernameAvailability}
        />
      </OnboardingWrapper>
    </AddFlex>
  );
}

export default OnboardingMain;
