import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  AddFlex,
  CustomInputLabel,
  CustomText,
  SecondaryActionButton,
} from "../../reusableStyles";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import { Dialog, Drawer } from "@mui/material";
import { Button, Dropdown, Radio, Select, Skeleton } from "antd";
import { primaryActionColor } from "../../constants";
import styled from "styled-components";
import { clearPosts, fetchInstagramMedia } from "../../Store/instagramSlice";
import { useDispatch } from "react-redux";
import useWindowSize from "../../Hooks/useWindowSize";
import PostsContent from "./PostsContent";
import useIntegrationHooks from "../../CustomHooks/useIntegrationHooks";
import AutomationMediaTypeStories from "./AutomationMediaTypeStories";
import AutomationMediaTypePostsOrReels from "./AutomationMediaTypePostsOrReels";

export const Image = styled.img`
  overflow: hidden;
  transition: transform 0.3s ease-in-out; /* Smooth scaling */
  object-fit: cover;
  &:hover {
    border-radius: 8px;
    transform: scale(1.2);
  }
`;

function UserInstaPosts({
  isEdit,
  disabled,
  automationData,
  setStage,
  handleSetAutomationData,
  handleCheckCommentAutomationValidations,
  errors,
  stage,
  setErrors,
}) {
  const dispatch = useDispatch();
  const [mediaCategory, setMediaCategory] = useState(
    automationData.mediaData?.isStory === true ? "stories" : "posts"
  );
  const [mediaData, setMediaData] = useState(automationData.mediaData || {});
  const { apiLoading, isIntegrationActivated } = useIntegrationHooks(
    "instagram_connect",
    () => {},
    () => {}
  );
  const { isMobileView } = useWindowSize();

  useEffect(() => {
    handleSetAutomationData("mediaData", mediaData);
  }, [mediaData]);

  useEffect(() => {
    return () => {
      dispatch(clearPosts()); // Clear posts when the component mounts
    };
  }, []);

  return (
    <>
      <AddFlex flexDirection="column" gap="10px">
        <AddFlex flexDirection="column">
          <CustomText
            fontSize="20px"
            color="black"
            margin="0 0 5px 0"
            fontWeight="700"
          >
            When someone comments on
          </CustomText>
          <Select
            disabled={disabled}
            style={{
              width: "100%",
            }}
            value={mediaCategory}
            onChange={(value) => {
              setMediaCategory(value);
              setMediaData((prev) => ({
                ...prev,
                isStory: value === "stories" ? true : false,
                type: "specific",
                mediaId: null,
              }));
            }}
            options={[
              { value: "posts", label: <span>Posts or Reels</span> },
              { value: "stories", label: <span>Stories</span> },
            ]}
          />
          {errors.mediaData && (
            <CustomText margin="5px 0 0 0" color="red" fontSize="14px">
              {errors.mediaData}
            </CustomText>
          )}
        </AddFlex>
        {!mediaData.isStory ? (
          <AutomationMediaTypePostsOrReels
            isIntegrationActivated={isIntegrationActivated}
            mediaData={mediaData}
            disabled={disabled}
            setMediaData={setMediaData}
          />
        ) : (
          <AutomationMediaTypeStories
            isIntegrationActivated={isIntegrationActivated}
            mediaData={mediaData}
            disabled={disabled}
            setMediaData={setMediaData}
          />
        )}
        {!isEdit && stage <= 1 && (
          <Button
            onClick={() => {
              const errors = handleCheckCommentAutomationValidations(
                automationData,
                1
              );
              if (Object.keys(errors).length === 0) {
                setStage(2);
                setErrors(errors);
              } else {
                setErrors(errors);
              }
            }}
            variant="primary"
            style={{
              width: "max-content",
              color: "white",
              backgroundColor: primaryActionColor,
            }}
          >
            Next
          </Button>
        )}
      </AddFlex>
    </>
  );
}

export default UserInstaPosts;
