import React, { useState } from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import { Button } from "antd";
import { Close } from "@mui/icons-material";
import { callNonStoreApi } from "../NonStoreApis";
import { InstagramIntegrationApi } from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { CircularProgress } from "@mui/material";

function IntegrateInstagramMessage() {
  const [hide, setHide] = useState(false);
  const [loading, setLoading] = useState(false);
  const userState = useSelector((state) => state.user);

  return (
    !hide && (
      <AddFlex
        padding="10px 48px"
        backgroundColor="#C62300"
        style={{
          zIndex: 111111,
        }}
        gap="10px"
        alignItems="center"
      >
        <CustomText fontSize="14px" fontWeight="600" margin="0px" color="white">
          {"No account linked"}
        </CustomText>
        <Button
          onClick={() => {
            setLoading(true);
            callNonStoreApi(InstagramIntegrationApi, null, {
              params: {
                username: userState.apiUserData.userName,
              },
            })
              .then((response) => {
                window.location.href = response.data.instagramAuthUrl;
              })
              .catch((error) => {
                console.error(error);
                enqueueSnackbar(
                  "There is some error in connecting to Instagram",
                  { variant: "error" }
                );
                setLoading(false);
              });
          }}
        >
          {loading ? (
            <CircularProgress size={14} />
          ) : (
            "Connect instagram account now"
          )}
        </Button>
        <Gap />
        <Close
          sx={{ color: "white", cursor: "pointer" }}
          onClick={() => {
            setHide(true);
          }}
        />
      </AddFlex>
    )
  );
}

export default IntegrateInstagramMessage;
