import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  CustomTextArea,
} from "../../reusableStyles";
import { Button } from "antd";
import { primaryActionColor } from "../../constants";

function InstaCommentToDMAutomationMessage({
  isEdit,
  disabled,
  automationData,
  setStage,
  stage,
  setErrors,
  errors,
  handleCheckCommentAutomationValidations,
  handleSetAutomationData,
}) {
  const [dmMessageDetails, setDmMessageDetails] = useState(
    automationData.dmMessage || {}
  );

  useEffect(() => {
    handleSetAutomationData("dmMessage", dmMessageDetails);
  }, [dmMessageDetails]);
  return (
    <AddFlex flexDirection="column" gap="10px">
      <AddFlex flexDirection="column">
        <CustomText fontSize="20px" color="black" fontWeight="700">
          Then they'll get the DM.
        </CustomText>
      </AddFlex>
      <CustomTextArea
        value={dmMessageDetails.message}
        disabled={disabled}
        placeholder={"Customise your message."}
        style={{ backgroundColor: "#f6f7f9" }}
        onChange={(text) => {
          setDmMessageDetails((prev) => ({ ...prev, message: text }));
        }}
        footerTextColor={"red"}
        footerText={errors["dmMessageMessage"]}
      />
      <CustomInput
        value={dmMessageDetails.link}
        disabled={disabled}
        placeholder={"Add the link"}
        backgroundColor="#f6f7f9"
        onChange={(text) => {
          setDmMessageDetails((prev) => ({ ...prev, link: text }));
        }}
        footerTextColor={"red"}
        footerText={errors["dmMessageLink"]}
        borderColor={"transparent"}
      />
      <CustomInput
        value={dmMessageDetails.buttonText}
        disabled={disabled}
        placeholder={"Customise the button text."}
        backgroundColor="#f6f7f9"
        onChange={(text) => {
          setDmMessageDetails((prev) => ({ ...prev, buttonText: text }));
        }}
        footerTextColor={"red"}
        footerText={errors["dmMessageButtonText"]}
        borderColor={"transparent"}
      />
      {!isEdit && stage <= 4 && (
        <Button
          onClick={() => {
            const errors = handleCheckCommentAutomationValidations(
              automationData,
              4
            );
            if (Object.keys(errors).length === 0) {
              setStage(5);
              setErrors(errors);
            } else {
              setErrors(errors);
            }
          }}
          variant="primary"
          style={{
            width: "max-content",
            color: "white",
            backgroundColor: primaryActionColor,
          }}
        >
          Next
        </Button>
      )}
    </AddFlex>
  );
}

export default InstaCommentToDMAutomationMessage;
