import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Add, Check, Download } from "@mui/icons-material";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  PrimaryActionButton,
  SecondaryActionButton,
} from "../../reusableStyles";
import {
  capitalizeWords,
  isNullOrUndefined,
  logMetaPixelEvent,
} from "../../utils";
import { Dialog, Drawer } from "@mui/material";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchUserInstaCredits } from "../../Api/ApiEndPoints";
import { useDispatch } from "react-redux";
import { clientInstaPlan } from "../../Store/instagramSlice";
import InstaCreditsPricingDialog from "./InstaCreditsPricingDialog";
import { differenceInDays, isAfter } from "date-fns";
import Tabs from "../../Tabs";
import InstaCreditsTransactionsHistory from "./InstaCreditsTransactionsHistory";
import { useSelector } from "react-redux";
import useWindowSize from "../../Hooks/useWindowSize";

// Styled Components
const Container = styled.div`
  margin: 20px 48px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media screen and (max-width: 1000px) {
    margin: 20px;
  }
`;

const Title = styled.h2`
  font-weight: bold;
  margin-bottom: 1rem;
`;

const Badge = styled.span`
  background: ${(props) => (props.variant === "blue" ? "#dbeafe" : "#dcfce7")};
  color: ${(props) => (props.variant === "blue" ? "#1e40af" : "#166534")};
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
`;

// Current Plan Components
const StatusSection = styled.div`
  border-radius: 12px;
  padding: 1.5rem;
  width: 40%;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  border: 1px solid #ececec;
  margin-bottom: 1.5rem;
  @media screen and (max-width: 1000px) {
    width: 100%;
  }
`;

const ProgressContainer = styled.div`
  margin-top: 10px;
`;

const ProgressBar = styled.div`
  width: 100%;
  height: 0.5rem;
  background: #e2e8f0;
  border-radius: 9999px;
  overflow: hidden;
  margin-top: 10px;
`;

const ProgressFill = styled.div`
  height: 100%;
  background: #3b82f6;
  border-radius: 9999px;
  width: ${(props) => props.progress}%;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #e2e8f0;
  }

  th {
    font-weight: 500;
    color: #4b5563;
  }
`;

const DownloadButton = styled.button`
  display: flex;
  align-items: center;
  color: #3b82f6;

  &:hover {
    color: #2563eb;
  }

  svg {
    margin-right: 0.25rem;
  }
`;

/**
 *
 * @returns
 * current plan:
 *  Free / Pro.
 *  billing cycle.
 */

// Main Component
export default function PricingPage() {
  const { isMobileView } = useWindowSize();
  const [showCurrentPlan, setShowCurrentPlan] = useState(false);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [currentSubscription, setCurrentSubscription] = useState({});
  const { plan: userPlan } = useSelector((state) => state.instagram.otherData);
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  const showUpgradeNowButtons = () => {
    if (currentSubscription.planType === "free") return true;
    if (currentSubscription.billingCycle === "monthly") return true;

    const today = new Date();
    if (isAfter(today, currentSubscription.planEndDate)) return true;

    return false;
  };

  const handleSetClientUserCredits = async () => {
    try {
      const response = await callNonStoreApi(FetchUserInstaCredits);
      dispatch(clientInstaPlan(response.data));
    } catch (error) {
      console.log(error);
    } finally {
      setLoading(false);
    }
  };

  const handleGetTotalDays = () => {
    const daysDiff = differenceInDays(
      currentSubscription.planEndDate,
      currentSubscription.planStartDate
    );
    return daysDiff;
  };

  const handleGetDaysLeft = () => {
    const today = new Date();
    const daysRemaining = Math.max(
      0,
      differenceInDays(currentSubscription.planEndDate, today)
    );
    return daysRemaining;
  };

  useEffect(() => {
    logMetaPixelEvent("ViewContent");
    handleSetClientUserCredits();
  }, []);

  useEffect(() => {
    if (isNullOrUndefined(userPlan)) return;
    setCurrentSubscription(userPlan);
  }, [userPlan]);

  return (
    <Container>
      <Tabs
        tabs={[
          { name: "Current plan details" },
          { name: "Transaction History" },
        ]}
        selectedTabIndex={selectedTabIndex}
        setSelectedTabIndex={(index) => {
          setSelectedTabIndex(index);
        }}
      />
      {selectedTabIndex === 0 ? (
        <StatusSection>
          <Title>Current Plan Status</Title>

          <AddFlex gap="10px" flexDirection="column">
            <AddFlex gap="8px" alignItems="center">
              <CustomText color="black">Current Plan</CustomText>
              <Gap />
              <Badge variant="blue">
                {capitalizeWords(currentSubscription.planType)}
              </Badge>
            </AddFlex>

            <AddFlex>
              <CustomText color="black">Billing Cycle</CustomText>
              <Gap />
              <CustomText color="black">
                {currentSubscription.planType === "free"
                  ? "-"
                  : capitalizeWords(currentSubscription.billingCycle)}
              </CustomText>
            </AddFlex>

            <ProgressContainer>
              <AddFlex>
                <CustomText color="black">
                  {currentSubscription.planType === "free"
                    ? "Free Credits"
                    : "Subscription Period"}
                </CustomText>
                <Gap />
                <CustomText color="black">
                  {currentSubscription.planType === "free"
                    ? currentSubscription.totalCreditsRemaining +
                      " credits remaining"
                    : handleGetDaysLeft() + " days left"}
                </CustomText>
              </AddFlex>
              <ProgressBar>
                <ProgressFill
                  progress={
                    currentSubscription.planType === "free"
                      ? (currentSubscription.totalCreditsRemaining /
                          currentSubscription.totalCredits) *
                        100
                      : (handleGetDaysLeft() / handleGetTotalDays()) * 100
                  }
                />
              </ProgressBar>
              {currentSubscription.planType === "free" && (
                <CustomText margin="10px 0 0 0" fontSize="14px" color="red">
                  1 credit automates 1 comment
                </CustomText>
              )}
            </ProgressContainer>

            {showUpgradeNowButtons() && (
              <PrimaryActionButton
                onClick={() => {
                  logMetaPixelEvent("ButtonClick", true, {
                    button_name: "upgrade plan",
                  });
                  setShowCurrentPlan(!showCurrentPlan);
                }}
                style={{ width: "max-content", borderRadius: "30px" }}
              >
                Upgrade Now
              </PrimaryActionButton>
            )}
          </AddFlex>
        </StatusSection>
      ) : (
        <InstaCreditsTransactionsHistory
          transactionHistory={currentSubscription?.transactionHistory}
        />
      )}
      {isMobileView() ? (
        <Drawer
          onClose={() => setShowCurrentPlan(false)}
          open={showCurrentPlan}
          anchor="bottom"
          PaperProps={{
            sx: {
              padding: "8px 20px 20px 20px",
              borderRadius: "24px 24px 0 0",
              height: "70vh",
            },
          }}
        >
          <InstaCreditsPricingDialog
            currentSubscription={currentSubscription}
            onClose={() => setShowCurrentPlan(false)}
          />
        </Drawer>
      ) : (
        <Dialog
          open={showCurrentPlan}
          onClose={() => setShowCurrentPlan(false)}
          PaperProps={{
            sx: {
              padding: "8px 20px 20px 20px",
              height: "70vh",
              maxWidth: "unset",
              borderRadius: "24px",
            },
          }}
        >
          <InstaCreditsPricingDialog
            currentSubscription={currentSubscription}
            onClose={() => setShowCurrentPlan(false)}
          />
        </Dialog>
      )}
    </Container>
  );
}
