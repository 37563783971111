import { useEffect } from "react";

const useUTMTracking = () => {
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const utmSource = urlParams.get("utm_source");
    const utmMedium = urlParams.get("utm_medium");
    const utmCampaign = urlParams.get("utm_campaign");
    const fbclid = urlParams.get("fbclid");

    // Save UTM parameters to sessionStorage
    if (utmSource || utmMedium || utmCampaign || fbclid) {
      sessionStorage.setItem("utm_source", utmSource || "direct");
      sessionStorage.setItem("utm_medium", utmMedium || "none");
      sessionStorage.setItem("utm_campaign", utmCampaign || "none");
      sessionStorage.setItem("fbclid", fbclid || "");
    }
  }, []);
};

export default useUTMTracking;
