import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import UserInstaPosts from "./UserInstaPosts";
import {
  AddFlex,
  CustomText,
  DialogHeader,
  Gap,
  SecondaryActionButton,
} from "../../reusableStyles";
import InstaCommentToDMAutomationKeywords from "./InstaCommentToDMAutomationKeywords";
import InstaCommentToDMAutomationOpeningMessage from "./InstaCommentToDMAutomationOpeningMessage";
import InstaCommentToDMAutomationMessage from "./InstaCommentToDMAutomationMessage";
import InstaCommentToDMOtherAutomations, {
  automationTypes,
} from "./InstaCommentToDMOtherAutomations";
import { Button } from "antd";
import {
  capitalizeFirstLetter,
  generateUUID,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  isStringNullOrUndefined,
  isValidURL,
  logAnalytics,
  logMetaPixelEvent,
} from "../../utils";
import { CircularProgress, Dialog } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { primaryActionColor } from "../../constants";
import InstagramDMPreview from "./InstagramDMPreview";
import useWindowSize from "../../Hooks/useWindowSize";
import ButtonWithAlertDialog from "../../CreateOffering/Components/ButtonWithAlertDialog";
import ContactListTable from "./ContactListTable";
import { Close } from "@mui/icons-material";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  PauseOrResumeAutomationApi,
  RegisterCommentToDm,
} from "../../Api/ApiEndPoints";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import Tabs from "../../Tabs";
import ResponsiveContactsList from "./ResponsiveContactsList";
import AutomationTags from "./AutomationTags";
import useIntegrationHooks from "../../CustomHooks/useIntegrationHooks";

const handleCheckCommentAutomationValidations = (automationData, stage) => {
  const errors = {};
  if (
    isNullOrUndefined(automationData.mediaData) ||
    (automationData.mediaData.type !== "all" &&
      isStringNullOrUndefined(automationData.mediaData.mediaId))
  ) {
    errors["mediaData"] = "Select add a media";
  }
  if (stage === 1) return errors;

  // Start of keywords validations
  if (
    isNullOrUndefined(automationData.keywords) ||
    (automationData.keywords.type !== "any" &&
      isArrayNullOrUndefined(automationData.keywords.keywords))
  ) {
    errors["keywords"] = "Add the words that suit best for automation.";
  }
  if (stage === 2) return errors;

  // Start of openingMessage validations
  if (isStringNullOrUndefined(automationData?.openingMessage?.message)) {
    errors["openingMessageMessage"] = "Add an intro message";
  }
  if (isStringNullOrUndefined(automationData?.openingMessage?.buttonText)) {
    errors["openingMessageButtonText"] = "Add button label to continue";
  }
  if (stage === 3) return errors;

  // Start of dmMessage validations
  if (isStringNullOrUndefined(automationData?.dmMessage?.message)) {
    errors["dmMessageMessage"] = "Add a DM Message";
  }
  if (
    !isStringNullOrUndefined(automationData?.dmMessage?.link) ||
    !isStringNullOrUndefined(automationData?.dmMessage?.buttonText)
  ) {
    if (
      (automationData?.dmMessage?.link &&
        !isValidURL(automationData?.dmMessage?.link)) ||
      isStringNullOrUndefined(automationData?.dmMessage?.link)
    ) {
      errors["dmMessageLink"] = "Enter a valid url";
    }
    if (isStringNullOrUndefined(automationData?.dmMessage?.buttonText)) {
      errors["dmMessageButtonText"] = "Add button label to continue";
    }
  }

  if (stage === 4) return errors;
  if (
    automationData.otherAutomations?.some(
      (automation) => automation.type === "reply-to-comments"
    )
  ) {
    const replyToComments = automationData.otherAutomations?.find(
      (automation) => automation.type === "reply-to-comments"
    );
    let replies = replyToComments.body.filter((word) => word.length > 0);
    replies = [...new Set(replies)];
    if (replies.length !== 3) {
      errors["reply-to-comments-data"] =
        "Add 3 unique replies so they feel natural.";
    }
    // Add validatoions for other remaining automations.
  } else {
    automationData.otherAutomations?.forEach((automation) => {
      if (isStringNullOrUndefined(automation.body)) {
        errors[automation.type] = "Enter this message";
      }
    });
  }
  return errors;
};

const getValidationForCurrentStep = (automationType) => {
  switch (automationType) {
    case automationTypes.REQUEST_MOBILE_NUMBER:
      return "^\\+\\d{10,15}$";
    case automationTypes.REQUEST_EMAIL:
      return "^[^\\s@]+@[^\\s@]+\\.[^\\s@]+$";
    default:
      return null;
  }
};

const initialAutomationData = (selectedAutomationData) => {
  return (
    selectedAutomationData || {
      id: generateUUID(),
      mediaData: {
        type: "specific",
      },
      keywords: {
        type: "specific",
      },
      openingMessage: {
        message:
          "Hey there! I'm so happy you're here, thanks so much for your interest. Click below and I'll send you the link in just a sec",
        buttonText: "Send me the link",
      },
      dmMessage: {
        message: "",
        link: "",
        buttonText: "",
      },
    }
  );
};

function CreateUpdateInstaCommentAutomation({
  selectedAutomationData,
  setSelectedAutomationData,
  onClose,
  handleRefresh,
}) {
  const { integrations } = useSelector((state) => state.user.integrations);
  const [isEdit, setIsEdit] = useState(false);
  const [errors, setErrors] = useState({});
  const { isMobileView, windowSize } = useWindowSize();
  const [staticAutomationData] = useState(
    initialAutomationData(selectedAutomationData)
  );
  const [automationData, setAutomationData] = useState(
    initialAutomationData(selectedAutomationData)
  );
  const [stage, setStage] = useState(selectedAutomationData ? 5 : 1);
  const [isApiLoading, setIsApiLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState(0);
  const { closeDialog, openDialog, isDialogOpen } = useDialogActionsHook();
  const handleSetAutomationData = (key, value) => {
    setAutomationData((prev) => ({ ...prev, [key]: value }));
  };
  const [showContacts, setShowContacts] = useState(false);

  const clientInstaIntegration = integrations.find(
    (integration) => integration.id === "instagram_connect"
  );

  const { isIntegrationActivated } = useIntegrationHooks(
    "instagram_connect",
    () => {},
    () => {}
  );

  const getFlowObject = (automationData) => {
    const flow = {};
    flow.start = {
      message: {
        type: "buttons",
        text: automationData.openingMessage.message,
        buttons: [
          {
            type: "postback",
            title: automationData.openingMessage.buttonText,
            payload: `${
              automationData.mediaData.mediaId
            }_start_${generateUUID()}_${automationData.id}`,
            action: "end",
          },
        ],
      },
    };
    const otherAutomations = automationData.otherAutomations?.filter(
      (automation) => automation.type !== automationTypes.REPLY_TO_COMMENTS
    );

    if (!isArrayNullOrUndefined(otherAutomations)) {
      let i = 0;
      do {
        const nextStep = otherAutomations[i + 1]?.type || "end";
        const currentStep = otherAutomations[i].type;
        const validationOfCurrentStep =
          getValidationForCurrentStep(currentStep);
        if (i === 0) {
          flow.start.message.buttons[0].action = currentStep;
        }
        if (currentStep === automationTypes.REQUEST_TO_FOLLOW) {
          flow[automationTypes.REQUEST_TO_FOLLOW] = {
            condition: "followerCheck",
            // trueStep: "end",
            trueStep: nextStep,
            falseStep: "followPrompt",
          };
          flow.followPrompt = {
            message: {
              type: "buttons",
              text: otherAutomations[i].body,
              buttons: [
                {
                  type: "web_url",
                  title: "Go to my profile",
                  url: `https://instagram.com/${clientInstaIntegration?.username}`,
                },
                {
                  type: "postback",
                  title: "I am following you",
                  payload: `${
                    automationData.mediaData.mediaId
                  }_${currentStep}_${generateUUID()}_${automationData.id}`,
                  action: automationTypes.REQUEST_TO_FOLLOW,
                },
              ],
            },
          };
        } else {
          flow[currentStep] = {
            message: {
              type: "text",
              text: otherAutomations[i].body,
              validation: validationOfCurrentStep,
              nextStep,
            },
          };
        }
        i = i + 1;
      } while (i < otherAutomations.length);
    }
    flow.end = !isStringNullOrUndefined(automationData.dmMessage.link)
      ? {
          message: {
            type: "buttons",
            text: automationData.dmMessage.message,
            buttons: [
              {
                type: "web_url",
                title: automationData.dmMessage.buttonText,
                url: automationData.dmMessage.link,
              },
            ],
          },
        }
      : {
          message: {
            type: "text",
            text: automationData.dmMessage.message,
          },
        };
    return flow;
  };

  const handleGetPayload = () => {
    const data = {},
      mediaData = automationData.mediaData;

    //A final check to make sure weather the media is updated or not.
    if (
      staticAutomationData.mediaData.mediaId ===
      automationData.mediaData.mediaId
    ) {
      mediaData.isMediaUpdated = false;
    } else {
      mediaData.isMediaUpdated = true;
    }
    const flowObject = getFlowObject(automationData); // building the flow object
    data.flow = flowObject;
    data.tags = automationData.tags;
    data.keywords = automationData.keywords;
    if (
      automationData.otherAutomations?.some(
        (automation) => automation.type === automationTypes.REPLY_TO_COMMENTS
      )
    ) {
      data.commentReplies = automationData.otherAutomations.find(
        (automation) => automation.type === automationTypes.REPLY_TO_COMMENTS
      ).body; // this is always the 1st object in the otherAutomations
    }
    return {
      data,
      mediaData,
      id: automationData.id,
      isEdit,
      status: automationData.status,
    };
  };

  const handleGoLive = async (forceUpdate) => {
    setIsApiLoading(true);
    const _errors = handleCheckCommentAutomationValidations(automationData);
    if (Object.keys(_errors).length > 0) {
      setErrors(_errors);
      setIsApiLoading(false);
      return;
    }
    try {
      // do live api
      const payload = handleGetPayload();
      payload.forceUpdate = forceUpdate;
      const response = await callNonStoreApi(RegisterCommentToDm, payload);
      enqueueSnackbar("Automation Live", {
        variant: "success",
      });
      setAutomationData((prev) => ({
        ...prev,
        mediaData: {
          ...prev.mediaData,
          isMediaUpdated: false,
        },
      }));

      if (!isEdit) {
        await logAnalytics("automation_creation", {
          flow_id: automationData.id,
        });
        logMetaPixelEvent("AutomationCreated", true);
      }

      await handleRefresh();
      setIsEdit(false);
      onClose();
      return;
    } catch (error) {
      if (error.response.status === 403) {
        openDialog();
      } else {
        enqueueSnackbar("Something went wrong", {
          variant: "error",
        });
      }
    } finally {
      setIsApiLoading(false);
    }
  };

  const handlePauseOrResumeAutomation = async () => {
    try {
      setIsApiLoading(true);
      const response = await callNonStoreApi(PauseOrResumeAutomationApi, {
        id: automationData.id,
        status: automationData.status === "live" ? "paused" : "live",
      });
      if (response.data.message === "success") {
        setSelectedAutomationData((prev) => ({
          ...prev,
          status: response.data.status,
        }));
        setAutomationData((prev) => ({
          ...prev,
          status: response.data.status,
        }));
        await handleRefresh();
        enqueueSnackbar(
          `Automation ${
            response.data.status === "live" ? "is back live!" : "paused"
          }`,
          {
            variant: "success",
          }
        );
      } else {
        enqueueSnackbar("something went wrong", {
          variant: "error",
        });
      }
    } catch (error) {
      enqueueSnackbar("something went wrong", {
        variant: "error",
      });
    } finally {
      setIsApiLoading(false);
    }
  };

  useEffect(() => {
    if (isEdit) {
      const _errors = handleCheckCommentAutomationValidations(automationData);
      setErrors(_errors);
    }
  }, [automationData]);

  const getTopBarButtons = () => {
    if (selectedAutomationData && !isEdit) {
      if (
        selectedAutomationData &&
        selectedAutomationData.status === "paused"
      ) {
        return (
          <AddFlex gap="10px">
            <Button
              variant="solid"
              onClick={() => {
                if (!isIntegrationActivated()) {
                  enqueueSnackbar("Please integrate an instagram account", {
                    variant: "error",
                  });
                  return;
                }
                logMetaPixelEvent("AutomationUpdate", true);
                setIsEdit(true);
              }}
              size="medium"
            >
              {"Edit"}
            </Button>
            <ButtonWithAlertDialog
              component={
                <Button
                  variant="solid"
                  style={{
                    backgroundColor: primaryActionColor,
                    color: "white",
                  }}
                  size="medium"
                >
                  {isApiLoading ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Resume"
                  )}
                </Button>
              }
              buttons={[
                {
                  title: "Proceed Anyway",
                  action: async () => {
                    if (!isIntegrationActivated()) {
                      enqueueSnackbar("Please integrate an instagram account", {
                        variant: "error",
                      });
                      return;
                    }
                    logMetaPixelEvent("AutomationResumed", true);
                    handlePauseOrResumeAutomation();
                  },
                },
              ]}
              title={"Are You Sure?"}
            />
          </AddFlex>
        );
      }
      return (
        <AddFlex gap="10px">
          <Button
            variant="solid"
            onClick={() => {
              if (!isIntegrationActivated()) {
                enqueueSnackbar("Please integrate an instagram account", {
                  variant: "error",
                });
                return;
              }
              setIsEdit(true);
            }}
            size="medium"
          >
            {"Edit"}
          </Button>
          <ButtonWithAlertDialog
            component={
              <Button
                variant="solid"
                style={{
                  color: "red",
                }}
                size="medium"
              >
                {isApiLoading ? (
                  <CircularProgress size={14} sx={{ color: "red" }} />
                ) : (
                  "Stop"
                )}
              </Button>
            }
            buttons={[
              {
                title: "Proceed Anyway",
                action: async () => {
                  if (!isIntegrationActivated()) {
                    enqueueSnackbar("Please integrate an instagram account", {
                      variant: "error",
                    });
                    return;
                  }
                  logMetaPixelEvent("AutomationPaused", true);
                  handlePauseOrResumeAutomation();
                },
              },
            ]}
            title={"Are You Sure?"}
          />
        </AddFlex>
      );
    }
    if (selectedAutomationData && isEdit) {
      return (
        <AddFlex gap="10px">
          <Button
            variant="solid"
            onClick={() => {
              setIsEdit(false);
            }}
            size="medium"
          >
            {"Cancel"}
          </Button>
          <Button
            variant="solid"
            // color={primaryActionColor}
            style={{
              backgroundColor: primaryActionColor,
              color: "white",
            }}
            onClick={() => {
              if (!isIntegrationActivated()) {
                enqueueSnackbar("Please integrate an instagram account", {
                  variant: "error",
                });
                return;
              }
              handleGoLive();
            }}
            size="medium"
          >
            {isApiLoading ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : (
              "Update"
            )}
          </Button>
        </AddFlex>
      );
    }
    return (
      <AddFlex gap="10px">
        <Button
          variant="solid"
          onClick={() => {
            onClose(false);
          }}
          size="medium"
        >
          {"Cancel"}
        </Button>
        <Button
          variant="solid"
          // color={primaryActionColor}
          style={{
            backgroundColor: primaryActionColor,
            color: "white",
          }}
          onClick={() => {
            handleGoLive();
          }}
          size="medium"
        >
          {isApiLoading ? (
            <CircularProgress size={14} sx={{ color: "white" }} />
          ) : (
            "Go Live"
          )}
        </Button>
      </AddFlex>
    );
  };

  if (showContacts) {
    return (
      <AddFlex
        position="absolute"
        top="0px"
        right="0px"
        height={windowSize.screenHeight + "px"}
        width="100%"
        backgroundColor="#F6F7F9"
        style={{
          boxShadow: "-10px 0px 40px 20px rgba(0,0,0,1)",
          overflow: "hidden",
        }}
      >
        <ResponsiveContactsList
          setShowContacts={setShowContacts}
          mediaId={automationData.mediaData.mediaId}
          showContactInformation={selectedAutomationData.otherAutomations.some(
            (automation) =>
              automation.type === automationTypes.REQUEST_EMAIL ||
              automation.type === automationTypes.REQUEST_MOBILE_NUMBER
          )}
          otherAutomations={selectedAutomationData.otherAutomations}
          automationId={selectedAutomationData.id}
        />
      </AddFlex>
    );
  }

  return (
    <>
      <AddFlex
        position="absolute"
        top="0px"
        right="0px"
        height={windowSize.screenHeight + "px"}
        width="100%"
        backgroundColor="#F6F7F9"
        style={{
          boxShadow: "-10px 0px 40px 20px rgba(0,0,0,1)",
          overflow: "hidden",
        }}
      >
        <AddFlex
          flexDirection="column"
          width={isMobileView() ? "100%" : "35%"}
          gap="20px"
          backgroundColor="white"
          style={{
            zIndex: 111111111111,
            borderLeft: isMobileView() ? "none" : "1px solid #ececec",
            boxShadow: "0px 0 5px rgba(0, 0, 0, 0.1)",
            cursor: "pointer",
            overflow: "hidden",
            flexShrink: 0,
            maxWidth: isMobileView() ? "unset" : "376px",
          }}
        >
          <div>
            {isMobileView() && automationData.status && (
              <AddFlex
                alignItems="center"
                padding="10px"
                backgroundColor={
                  automationData.status === "live" ? "#249640" : "orange"
                }
                color="white"
                borderRadius="0 0px 12px 12px"
              >
                {automationData.status
                  ? "Automation Live!"
                  : "Automation Paused"}
                <Gap />
                <Button
                  onClick={() => {
                    setShowContacts(true);
                  }}
                >
                  View Contacts
                </Button>
              </AddFlex>
            )}
            <AddFlex
              alignItems="center"
              padding="15px"
              gap="10px"
              style={{ borderBottom: "1px solid #ececec" }}
            >
              <Close onClick={onClose} sx={{ cursor: "pointer" }} />
              <CustomText color="#242B39" fontSize="15px">
                {isMobileView()
                  ? "Comment to DM"
                  : "Auto-DM links from comments"}
              </CustomText>
              {!isMobileView() && automationData.status && (
                <AddFlex
                  padding="4px"
                  backgroundColor="#EC5038"
                  color="white"
                  borderRadius="4px"
                  style={{ fontSize: "15px" }}
                >
                  {capitalizeFirstLetter(automationData.status)}
                </AddFlex>
              )}
              <Gap />
              {isMobileView() && (
                <AddFlex gap="10px">{getTopBarButtons()}</AddFlex>
              )}
            </AddFlex>
          </div>
          <AddFlex
            flexDirection="column"
            padding={
              isMobileView() ? "0px 15px 15vh 15px" : "0px 15px 30px 15px"
            }
            gap="20px"
            style={{ overflowY: "scroll" }}
          >
            {stage >= 1 && (
              <UserInstaPosts
                automationData={automationData}
                handleSetAutomationData={handleSetAutomationData}
                isEdit={isEdit}
                disabled={!isNullOrUndefined(selectedAutomationData) && !isEdit}
                handleCheckCommentAutomationValidations={
                  handleCheckCommentAutomationValidations
                }
                setStage={setStage}
                errors={errors}
                stage={stage}
                setErrors={setErrors}
              />
            )}
            {stage >= 2 && (
              <>
                <div></div>
                <InstaCommentToDMAutomationKeywords
                  automationData={automationData}
                  handleSetAutomationData={handleSetAutomationData}
                  isEdit={isEdit}
                  handleCheckCommentAutomationValidations={
                    handleCheckCommentAutomationValidations
                  }
                  disabled={
                    !isNullOrUndefined(selectedAutomationData) && !isEdit
                  }
                  setStage={setStage}
                  stage={stage}
                  errors={errors}
                  setErrors={setErrors}
                />
              </>
            )}
            {stage >= 3 && (
              <>
                <div></div>
                <InstaCommentToDMAutomationOpeningMessage
                  automationData={automationData}
                  handleSetAutomationData={handleSetAutomationData}
                  isEdit={isEdit}
                  handleCheckCommentAutomationValidations={
                    handleCheckCommentAutomationValidations
                  }
                  disabled={
                    !isNullOrUndefined(selectedAutomationData) && !isEdit
                  }
                  stage={stage}
                  setStage={setStage}
                  errors={errors}
                  setErrors={setErrors}
                />
              </>
            )}
            {stage >= 4 && (
              <>
                <div></div>
                <InstaCommentToDMAutomationMessage
                  automationData={automationData}
                  handleSetAutomationData={handleSetAutomationData}
                  isEdit={isEdit}
                  handleCheckCommentAutomationValidations={
                    handleCheckCommentAutomationValidations
                  }
                  disabled={
                    !isNullOrUndefined(selectedAutomationData) && !isEdit
                  }
                  stage={stage}
                  setStage={setStage}
                  errors={errors}
                  setErrors={setErrors}
                />
              </>
            )}
            {stage >= 5 && (
              <>
                <div></div>
                <InstaCommentToDMOtherAutomations
                  automationData={automationData}
                  handleSetAutomationData={handleSetAutomationData}
                  isEdit={isEdit}
                  handleCheckCommentAutomationValidations={
                    handleCheckCommentAutomationValidations
                  }
                  disabled={
                    !isNullOrUndefined(selectedAutomationData) && !isEdit
                  }
                  stage={stage}
                  setStage={setStage}
                  errors={errors}
                  setErrors={setErrors}
                />
                <AutomationTags
                  disabled={
                    !isNullOrUndefined(selectedAutomationData) && !isEdit
                  }
                  automationData={automationData}
                  handleSetAutomationData={handleSetAutomationData}
                />
              </>
            )}
          </AddFlex>
        </AddFlex>
        {!isMobileView() && (
          <AddFlex position="relative" grow={1}>
            <AddFlex
              gap="10px"
              padding="10px 30px"
              alignItems="center"
              style={{
                position: "absolute",
                top: "0px",
                right: "0%",
                width: "100%",
                zIndex: 1111111,
                backgroundColor: "#F6F7F9",
              }}
            >
              {selectedAutomationData && (
                <>
                  <Tabs
                    selectedTabIndex={selectedTab}
                    setSelectedTabIndex={setSelectedTab}
                    tabs={[{ name: "Preview" }, { name: "Contacts" }]}
                  />
                </>
              )}
              <Gap />
              {getTopBarButtons()}
            </AddFlex>
            {selectedTab === 1 ? (
              <AddFlex width="100%">
                <ContactListTable
                  margin={"60px 30px"}
                  mediaId={selectedAutomationData?.mediaData?.mediaId}
                  automationStats={selectedAutomationData.stats}
                  // list={getTempList()}
                  showContactInformation={selectedAutomationData.otherAutomations.some(
                    (automation) =>
                      automation.type === automationTypes.REQUEST_EMAIL ||
                      automation.type === automationTypes.REQUEST_MOBILE_NUMBER
                  )}
                  otherAutomations={selectedAutomationData.otherAutomations}
                  automationId={selectedAutomationData.id}
                  partial={true}
                />
              </AddFlex>
            ) : (
              <InstagramDMPreview automationData={automationData} />
            )}
          </AddFlex>
        )}
      </AddFlex>
      <Dialog
        open={isDialogOpen}
        onClose={closeDialog}
        PaperProps={{
          sx: {
            borderRadius: "8px",
          },
        }}
      >
        <DialogHeader
          mainText={"ATTENTION!"}
          subText={"Please be careful, this operation cannot be undone"}
          onClose={closeDialog}
          padding={"10px 20px"}
        />
        <AddFlex flexDirection="column" gap="20px" padding="20px">
          <CustomText color="black">
            There is an automation for the post you selcted, do you want to
            replce it?
          </CustomText>
          <AddFlex gap="10px">
            <Gap />
            <SecondaryActionButton padding="5px 10px" onClick={closeDialog}>
              Cancel
            </SecondaryActionButton>
            <SecondaryActionButton
              padding="5px 10px"
              onClick={() => {
                handleGoLive(true);
                closeDialog();
              }}
            >
              Proceed
            </SecondaryActionButton>
          </AddFlex>
        </AddFlex>
      </Dialog>
    </>
  );
}

export default CreateUpdateInstaCommentAutomation;
