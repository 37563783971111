import React, { useEffect, useState } from "react";
import { AddFlex, DialogHeader } from "../../reusableStyles";
import styled from "styled-components";
import { Check } from "@mui/icons-material";
import PurchaseCreditsButton from "./PurchaseCreditsButton";
import { primaryActionColor } from "../../constants";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchInstaCreditsPlansApi } from "../../Api/ApiEndPoints";
import { formatPrice, logMetaPixelEvent } from "../../utils";

const Badge = styled.span`
  background: ${(props) => (props.variant === "blue" ? "#dbeafe" : "#dcfce7")};
  color: ${(props) => (props.variant === "blue" ? "#1e40af" : "#166534")};
  padding: 0.25rem 0.75rem;
  border-radius: 9999px;
  font-size: 0.875rem;
`;

const TabGroup = styled.div`
  display: flex;
  justify-content: center;
`;

const TabContainer = styled.div`
  display: inline-flex;
  border-radius: 0.5rem;
  border: 1px solid #e2e8f0;
  padding: 0.25rem;
`;

const Tab = styled.div`
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  background: ${(props) => (props.active ? primaryActionColor : "transparent")};
  color: ${(props) => (props.active ? "white" : "#4b5563")};
  transition: all 0.2s;
  cursor: pointer;

  &:hover {
    background: ${(props) => (props.active ? primaryActionColor : "#f3f4f6")};
  }
`;

const CardGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 2rem;
  max-width: 1000px;
  margin: 0 auto;
`;

const Card = styled.div`
  border: ${(props) =>
    props.featured ? "2px solid #3b82f6" : "1px solid #e2e8f0"};
  border-radius: 0.5rem;
  padding: 1.5rem;
  background: white;
`;

const CardHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const PlanName = styled.h3`
  font-size: 1.25rem;
  font-weight: bold;
`;

const Price = styled.div`
  margin-top: 1rem;

  span.amount {
    font-size: 1.875rem;
    font-weight: bold;
  }

  span.period {
    color: #666;
    margin-left: 0.25rem;
  }
`;

const FeatureList = styled.ul`
  margin-top: 1.5rem;
  space: 1rem;
`;

const Feature = styled.li`
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;

  svg {
    color: #22c55e;
    margin-right: 0.5rem;
  }
`;

function InstaCreditsPricingDialog({ onClose, currentSubscription }) {
  const [activeTab, setActiveTab] = useState("monthly");
  const [plan, setPlan] = useState({});
  const [loading, setLoading] = useState(true);
  const features = plan.features;
  // const offer = plan.offer;
  // const monthlyPrice = plan.monthlyPrice["INR"];
  // const monthlyStrikeOff = plan.monthlyStrikeOff["INR"];
  // const yearlyPriceStrikeOff = plan.yearlyPriceStrikeOff["INR"];
  // const yearlyPrice = plan.yearlyPrice["INR"]; // 75% off

  const handleSetCreditPlan = async () => {
    //fetch credit plans
    try {
      const response = await callNonStoreApi(FetchInstaCreditsPlansApi);
      const plans = response.data.plans;
      setPlan(plans[0]);
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSetCreditPlan();
  }, []);

  return (
    !loading && (
      <AddFlex flexDirection="column" gap="20px">
        <DialogHeader
          mainText={"Simple, Transparent Pricing"}
          subText={"Choose the plan that works best for you"}
          onClose={onClose}
        />

        <TabGroup>
          <TabContainer>
            <Tab
              active={activeTab === "monthly"}
              onClick={() => {
                logMetaPixelEvent("ButtonClick", true, {
                  button_name: "month",
                });
                setActiveTab("monthly");
              }}
            >
              Monthly
            </Tab>
            <Tab
              active={activeTab === "yearly"}
              onClick={() => {
                logMetaPixelEvent("ButtonClick", true, {
                  button_name: "yearly",
                });
                setActiveTab("yearly");
              }}
            >
              Yearly ({plan.offer * 100}% off)
            </Tab>
          </TabContainer>
        </TabGroup>

        <CardGrid>
          <Card featured>
            <CardHeader>
              <div>
                <PlanName>{plan.name}</PlanName>
                <p>Unlimited credits</p>
              </div>
              <Badge variant="blue">Popular</Badge>
            </CardHeader>
            <Price>
              <span
                className="amount"
                style={{
                  textDecoration: "line-through",
                  fontWeight: "400",
                  color: "red",
                  fontSize: "20px",
                }}
              >
                {formatPrice(
                  activeTab === "monthly"
                    ? plan.monthlyStrikeOff["INR"]
                    : Math.round(plan.yearlyPriceStrikeOff["INR"] / 12),
                  "INR"
                )}
              </span>{" "}
              <span className="amount">
                {formatPrice(
                  activeTab === "monthly"
                    ? plan.monthlyPrice["INR"]
                    : Math.round(plan.yearlyPrice["INR"] / 12),
                  "INR"
                )}
              </span>
              <span className="period">/month</span>
            </Price>
            <FeatureList>
              {features.map((feature, index) => (
                <Feature key={index}>
                  <Check size={20} />
                  <span>{feature}</span>
                </Feature>
              ))}
            </FeatureList>
            <PurchaseCreditsButton
              disabled={
                activeTab === "monthly" &&
                currentSubscription?.billingCycle === "monthly"
              }
              planDetails={{
                billingCycle: activeTab,
                prices: {
                  INR:
                    activeTab === "monthly"
                      ? plan.monthlyPrice["INR"]
                      : plan.yearlyPrice["INR"],
                },
              }}
              successCallback={onClose}
            />
          </Card>
        </CardGrid>
      </AddFlex>
    )
  );
}

export default InstaCreditsPricingDialog;
