import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  clearPosts,
  fetchInstagramMedia,
  fetchStories,
  setStories,
} from "../../Store/instagramSlice";
import { useEffect } from "react";
import {
  AddFlex,
  DialogHeader,
  Gap,
  PrimaryActionButton,
} from "../../reusableStyles";
import Loader from "../../Pages/Components/Loader";
import styled from "styled-components";
import { primaryActionColor } from "../../constants";

const Image = styled.img`
  overflow: hidden;
  transition: transform 0.3s ease-in-out; /* Smooth scaling */
  &:hover {
    border-radius: 8px;
    transform: scale(1.2);
  }
`;

const PostsContent = ({ closeDialog, isStory, mediaData, setMediaData }) => {
  const { posts, stories, loading, otherData } = useSelector(
    (state) => state.instagram
  );
  const { integrations } = useSelector((state) => state.user.integrations);
  const instaIntegration = integrations.find(
    (item) => item.id === "instagram_connect"
  );
  const dispatch = useDispatch();

  const handleInitial = async () => {
    if (isStory) {
      const stories = await fetchStories(
        instaIntegration.instaUserId,
        instaIntegration.accessToken
      );
      dispatch(setStories(stories));
      return;
    }
    dispatch(clearPosts()); // Clear posts when the component mounts
    dispatch(
      fetchInstagramMedia({
        userId: instaIntegration.instaUserId,
        accessToken: instaIntegration.accessToken,
      })
    ); // Fetch initial data
  };

  useEffect(() => {
    handleInitial();
  }, [dispatch]);

  return loading ? (
    <Loader />
  ) : (
    <AddFlex flexDirection="column" height="80vh">
      <DialogHeader
        padding={"10px 20px"}
        mainText={"Pick any post or reel to automate"}
        subText={"When someone comments they'll get your custom DM."}
        onClose={closeDialog}
      />
      <AddFlex
        padding="10px"
        gap="10px"
        wrap="wrap"
        height="100%"
        style={{ overflow: "scroll" }}
      >
        {(isStory ? stories : posts).map((media) => (
          <div
            style={{
              width: "180px",
              height: "180px",
              borderRadius: "8px",
              overflow: "hidden",
              border:
                mediaData.mediaId !== media.id
                  ? "2px solid transparent"
                  : `2px solid ${primaryActionColor}`,
            }}
          >
            <Image
              src={
                media.media_type === "VIDEO"
                  ? media.thumbnail_url
                  : media.media_url
              }
              style={{
                width: "100%",
                height: "100%",
                borderRadius: "8px",
                cursor: "pointer",
                objectFit: "cover",
              }}
              onClick={() => {
                setMediaData((prev) => ({
                  ...prev,
                  mediaId: media.id,
                  media_type: media.media_type,
                  mediaUrl:
                    media.media_type === "VIDEO"
                      ? media.thumbnail_url
                      : media.media_url,
                }));
              }}
            />
          </div>
        ))}
      </AddFlex>
      <Gap />
      <AddFlex padding="10px" style={{ borderTop: "1px solid #ececec" }}>
        <PrimaryActionButton
          onClick={() => {
            closeDialog();
          }}
          style={{ margin: "0 auto" }}
        >
          Done
        </PrimaryActionButton>
      </AddFlex>
    </AddFlex>
  );
};

export default PostsContent;
