import React, { useState } from "react";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import { capitalizeWords, formatPrice, generateUUID } from "../../utils";
import { format } from "date-fns";
import { Chip } from "../../Pages/Components/TimelineCards/PhoneCallTimelineCard";

function InstaCreditsTransactionsHistory({ transactionHistory }) {
  return (
    <AddFlex flexDirection="column" gap="20px">
      {transactionHistory?.map((transaction) => (
        <>
          <CreditTransactionCard transaction={transaction} />
        </>
      ))}
    </AddFlex>
  );
}

export default InstaCreditsTransactionsHistory;

const CreditTransactionCard = ({ transaction }) => {
  return (
    <AddFlex
      flexDirection="column"
      gap="13px"
      style={{
        width: "max-content",
        paddingBottom: "20px",
        borderBottom: "1px dashed #9B9B9B",
      }}
    >
      <AddFlex>
        <CustomText fontSize="18px" fontWeight="500" color="black">
          Pro | {capitalizeWords(transaction.plan.billingCycle)} |{" "}
          {formatPrice(transaction.plan.prices["INR"], "INR")}
        </CustomText>
        <Gap />
        <Chip>Success</Chip>
      </AddFlex>
      <CustomText fontSize="16px" fontWeight="500" color="#475467">
        Trxn Ref : {transaction.id} |{" "}
        {format(new Date(transaction.saleDate), "dd MMM yyyy")}
      </CustomText>
    </AddFlex>
  );
};
