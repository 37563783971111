import styled from "styled-components";
import { CustomText } from "../reusableStyles";
import IconButton from "@mui/material/IconButton";
import { Link } from "react-router-dom";
import { primaryActionColor, primaryActionColorLight } from "../constants";
import React from "react";

export const SELECTED_SIDENAV_ITEM_BG = "#EDF4FF";
export const SELECTED_SIDENAV_ITEM_TEXT_COLOR = primaryActionColor;

export const StyledLink = styled(Link)`
  color: ${(props) => (props.color ? props.color : "white")};
  font-size: ${(props) => props.fontSize && props.fontSize};
  display: flex;
  align-items: center;
  padding: ${(props) => (props.padding ? props.padding : "6px 6px 6px 14px")};
  margin: ${(props) => (props.margin ? props.margin : "2px 0")};
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : "transparent"};
  border-radius: 20px 0 0px 20px;
  font-weight: 400;
  border: ${(props) =>
    props.isSelected ? "1px solid #b9d6ff" : "1px solid transparent"};
  border-right: none;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? SELECTED_SIDENAV_ITEM_TEXT_COLOR : "black"};
  text-decoration: none;
  &:hover {
    background-color: ${(props) => !props.isSelected && "#e5ebf980"};
    color: ${primaryActionColor};
  }
`;

export const UnselectedColor = "#012B67";

const SidebarNavLink = ({ item, isSelected, setSelectedTabRoute, onClick }) => {
  if (item.component) {
    return React.cloneElement(item.component, {
      item,
      isSelected,
      onClick: () => {
        setSelectedTabRoute(item.route);
        onClick();
      },
    });
  }

  return (
    <StyledLink
      // padding="5px"
      to={`${item.route.split("/")[1]}`}
      onClick={() => {
        setSelectedTabRoute(item.route);
        onClick();
      }}
      style={{
        margin: "0 0 5px 0",
        borderRight: "none",
      }}
      isSelected={isSelected}
      backgroundColor={isSelected ? SELECTED_SIDENAV_ITEM_BG : "transparent"}
    >
      <div
        style={{
          margin: "0 10px 0 0",
          // height: "30px",
          display: "flex",
          alignItems: "center",
          color: isSelected
            ? SELECTED_SIDENAV_ITEM_TEXT_COLOR
            : UnselectedColor,
        }}
      >
        {React.cloneElement(item.icon, {
          sx: {
            fontSize: "18px",
          },
        })}
      </div>

      <CustomText
        color={isSelected ? SELECTED_SIDENAV_ITEM_TEXT_COLOR : UnselectedColor}
        fontWeight={isSelected ? "700" : "400"}
        fontSize="14px"
        style={{ lineHeight: "17px" }}
      >
        {item.name}
      </CustomText>
    </StyledLink>
  );
};

export default SidebarNavLink;
