// src/components/InstaDm.js
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { saveKeywordReply, clearPosts } from "../../Store/instagramSlice";
import SideBarSetup from "./SideBarSetup";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchInstaCommentToDmPosts } from "../../Api/ApiEndPoints";
import { AddFlex, CustomText, Gap } from "../../reusableStyles";
import useWindowSize from "../../Hooks/useWindowSize";
import CreateUpdateInstaCommentAutomation from "./CreateUpdateInstaCommentAutomation";
import { Button, Empty, Skeleton } from "antd";
import Tabs from "../../Tabs";
import SectionHeader from "../../CreateOffering/Components/SectionHeader";
import { automationTypes } from "./InstaCommentToDMOtherAutomations";
import {
  capitalizeFirstLetter,
  isArrayNullOrUndefined,
  isNullOrUndefined,
  logMetaPixelEvent,
} from "../../utils";
import { enqueueSnackbar } from "notistack";
import {
  Add,
  EmailOutlined,
  Instagram,
  PersonAddOutlined,
  PhoneOutlined,
} from "@mui/icons-material";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { CircularProgress } from "@mui/material";
import CreditsTopbarButton from "./CreditsTopbarButton";
import { useLayout } from "../../CustomHooks.js/LayoutContext";
import { PlusOutlined } from "@ant-design/icons";
import AutomationCard from "./AutomationCard";
import useIntegrationHooks from "../../CustomHooks/useIntegrationHooks";
import InstaStoriesAutomation from "./InstaStoriesAutomation";

const PostGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;

  @media screen and (max-width: 1000px) {
    gap: 10px;
    padding: 0 20px;
  }
`;

const PostItem = styled.div`
  border: 1px solid #ddd;
  border-radius: 6px;
  overflow: hidden;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: ${(props) => (props.type === "VIDEO" ? "150px" : "150px")};
  height: ${(props) =>
    props.type === "VIDEO" ? "calc(150px * (16 / 12))" : "150px"};
  background: #f9f9f9;
  position: relative;
  cursor: pointer;
  @media screen and (max-width: 1000px) {
    width: ${(props) => (props.type === "VIDEO" ? "33.33%" : "33.34vw")};
    height: ${(props) =>
      props.type === "VIDEO" ? "calc(33.34% * (16 / 9))" : "33.34vw"};
    border-radius: 0px;
    box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.25) inset;
  }
`;

const PostImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const getAutomationId = (automationType) => {
  switch (automationType) {
    case automationTypes.REQUEST_MOBILE_NUMBER:
      return 2;
    case automationTypes.REQUEST_EMAIL:
      return 3;
    case automationTypes.REQUEST_TO_FOLLOW:
      return 4;
  }
};

const getOtherAutomations = (flow) => {
  if (isNullOrUndefined(flow)) return [];
  const basicKeys = ["start", "end"];
  const automationKeys = Object.keys(flow).filter(
    (item) => !basicKeys.includes(item)
  );

  const otherAutomationData = automationKeys
    .map((item) => ({
      id: getAutomationId(item),
      type: item,
      body:
        item === "followCheck"
          ? flow["followPrompt"].message.text
          : flow[item].message.text,
    }))
    .filter((item) => item.type !== "followPrompt")
    .sort((a, b) => a.id - b.id);

  return otherAutomationData;
};

export const handleGetAutomationData = (automation) => {
  const automationData = {};
  automationData.id = automation.id;
  automationData.status = automation.status;
  automationData.tags = automation.data.tags;
  automationData.mediaData = automation.mediaData;
  automation.mediaData.isMediaUpdated = false;
  automationData.keywords = automation.data.keywords;
  automationData.stats = automation.stats;
  automationData.openingMessage = {
    message: automation.data.flow.start.message.text,
    buttonText: automation.data.flow.start.message.buttons[0].title,
  };
  let link;
  if (
    automation.data.flow.end.message.buttons &&
    automation.data.flow.end.message.buttons[0].url
  ) {
    link = new URL(
      automation.data.flow.end.message.buttons[0].url
    ).searchParams.get("link");
    if (isNullOrUndefined(link)) {
      link = automation.data.flow.end.message.buttons[0].url;
    }
  }
  automationData.dmMessage = {
    message: automation.data.flow.end.message.text,
    link: link,
    buttonText:
      automation.data.flow.end.message.buttons &&
      automation.data.flow.end.message.buttons[0].title,
  };
  automationData.otherAutomations = getOtherAutomations(automation.data.flow);
  if (!isArrayNullOrUndefined(automation.data.commentReplies)) {
    automationData.otherAutomations.unshift({
      id: 1,
      type: automationTypes.REPLY_TO_COMMENTS,
      body: automation.data.commentReplies,
    });
  }
  return automationData;
};

function InstaDm() {
  const dispatch = useDispatch();
  const { setLayoutComponent, clearLayoutComponent } = useLayout();
  const { isMobileView } = useWindowSize();
  const { comments } = useSelector((state) => state.instagram);
  const [isApiLoading, setIsApiLoading] = useState(true);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [selectedMedia, setSelectedMedia] = React.useState(null);
  const [presetPosts, setPresetPosts] = useState();
  const [seggregatedPresetPosts, setSeggregatedPresetPosts] = useState({});
  const [selectedAutomationData, setSelectedAutomationData] = useState();
  const [showAutomationDetails, setShowAutomationDetails] = useState(false);
  const [forceRefresh, setForceRefesh] = useState(false);
  const { apiLoading, isIntegrationActivated } = useIntegrationHooks(
    "instagram_connect",
    () => {},
    () => {}
  );

  const fetchPresetPosts = async () => {
    const response = await callNonStoreApi(FetchInstaCommentToDmPosts);
    if (response.status === 200) {
      let presetPosts = response.data;

      const posts = { reels: [], posts: [], stories: [] };
      Object.values(presetPosts).forEach((item) =>
        item.mediaData.isStory
          ? posts.stories.push(item)
          : item.mediaData.media_type === "VIDEO"
          ? posts.reels.push(item)
          : posts.posts.push(item)
      );
      setSeggregatedPresetPosts(posts);
      setPresetPosts(presetPosts);
      setIsApiLoading(false);
    }
  };

  useEffect(() => {
    dispatch(clearPosts()); // Clear posts when the component mounts
    fetchPresetPosts();
  }, [dispatch]);

  const handleRefresh = async () => {
    try {
      setIsApiLoading(true);
      dispatch(clearPosts()); // Clear existing posts
      await fetchPresetPosts();
    } catch (error) {
      enqueueSnackbar("Something went wrong", {
        variant: "error",
      });
    } finally {
      setIsApiLoading(false);
      setForceRefesh(false);
    }
  };

  const handleSaveMapping = (mapping) => {
    dispatch(saveKeywordReply(mapping));
  };

  const getPresetPostsForTab = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        return seggregatedPresetPosts.reels;
      case 1:
        return seggregatedPresetPosts.posts;
      case 2:
        return seggregatedPresetPosts.stories;
    }
  };

  useEffect(() => {
    if (forceRefresh) handleRefresh();
  }, [forceRefresh]);

  useEffect(() => {
    setLayoutComponent(CreditsTopbarButton);
    return () => {
      clearLayoutComponent();
    };
  }, []);

  if (showAutomationDetails)
    return (
      <CreateUpdateInstaCommentAutomation
        onClose={() => {
          setShowAutomationDetails(false);
          setSelectedAutomationData(null);
        }}
        selectedAutomationData={selectedAutomationData}
        setSelectedAutomationData={setSelectedAutomationData}
        setPresetPosts={setPresetPosts}
        handleRefresh={fetchPresetPosts}
      />
    );

  return (
    <AddFlex
      flexDirection="column"
      padding={isMobileView() ? "0 0px" : "0 48px"}
    >
      <AddFlex
        alignItems="flex-start"
        gap="10px"
        margin={isMobileView() ? "20px" : "20px 0"}
      >
        <AddFlex gap="20px" flexDirection="column" grow={1}>
          <AddFlex alignItems="center">
            <Tabs
              tabs={[
                {
                  name: (
                    <AddFlex alignItems="center" gap="6px">
                      Reels
                      {seggregatedPresetPosts.reels?.length ? (
                        <AddFlex
                          padding="2px"
                          borderRadius="50%"
                          alignItems="center"
                          justify="center"
                          backgroundColor={primaryActionColorLight}
                          style={{
                            color: primaryActionColor,
                            fontSize: "14px",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          {seggregatedPresetPosts.reels?.length}
                        </AddFlex>
                      ) : (
                        <></>
                      )}
                    </AddFlex>
                  ),
                },
                {
                  name: (
                    <AddFlex alignItems="center" gap="6px">
                      Posts
                      {seggregatedPresetPosts.posts?.length ? (
                        <AddFlex
                          padding="2px"
                          borderRadius="50%"
                          alignItems="center"
                          justify="center"
                          backgroundColor={primaryActionColorLight}
                          style={{
                            color: primaryActionColor,
                            fontSize: "14px",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          {seggregatedPresetPosts.posts?.length}
                        </AddFlex>
                      ) : (
                        <></>
                      )}
                    </AddFlex>
                  ),
                },
                {
                  name: (
                    <AddFlex alignItems="center" gap="6px">
                      Stories
                      {seggregatedPresetPosts.stories?.length ? (
                        <AddFlex
                          padding="2px"
                          borderRadius="50%"
                          alignItems="center"
                          justify="center"
                          backgroundColor={primaryActionColorLight}
                          style={{
                            color: primaryActionColor,
                            fontSize: "14px",
                            width: "20px",
                            height: "20px",
                          }}
                        >
                          {seggregatedPresetPosts.stories?.length}
                        </AddFlex>
                      ) : (
                        <></>
                      )}
                    </AddFlex>
                  ),
                },
              ]}
              selectedTabIndex={tabIndex}
              setSelectedTabIndex={(index) => {
                setTabIndex(index);
              }}
            />
            <Gap />
            {isNullOrUndefined(presetPosts) ||
              (Object.values(presetPosts).length !== 0 && (
                <AddFlex gap="10px" alignItems="center">
                  <Button
                    onClick={() => {
                      logMetaPixelEvent("ButtonClick", true, {
                        button_name: "CreateAutomation",
                      });
                      if (!isIntegrationActivated()) {
                        enqueueSnackbar(
                          "Please integrate an instagram account",
                          {
                            variant: "error",
                          }
                        );
                        return;
                      }
                      setShowAutomationDetails(true);
                    }}
                    type="primary"
                    style={{ backgroundColor: primaryActionColor }}
                  >
                    <Add sx={{ fontSize: "14px" }} />
                    New Automation
                  </Button>
                </AddFlex>
              ))}
          </AddFlex>

          <SectionHeader
            headerText={`${
              tabIndex === 0 ? "Reels" : "Posts"
            } with automations`}
            maxSubTextWidth={"100%"}
            style={{ paddingLeft: "10px", borderLeft: "4px solid #FFA500" }}
            subHeaderText={
              <CustomText color="black">
                <b>Click details</b> to view details / edit
              </CustomText>
            }
          />
        </AddFlex>
      </AddFlex>
      {isApiLoading ? (
        isMobileView() ? (
          <AddFlex
            flexDirection="column"
            padding="48px"
            alignItems="center"
            gap="10px"
            justify="center"
          >
            <CircularProgress />
            <CustomText>Please wait</CustomText>
          </AddFlex>
        ) : (
          <AddFlex gap="15px" style={{ width: "60%" }} wrap="wrap">
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
            <Skeleton.Node
              active
              style={{
                width: "200px",
                height: "200px",
              }}
            />
          </AddFlex>
        )
      ) : (
        <PostGrid>
          {Object.values(presetPosts).length === 0 ? (
            <Empty
              style={{ width: "100%", margin: "10px" }}
              description={"No automations found"}
            >
              <Button
                type="primary"
                onClick={() => {
                  logMetaPixelEvent("ButtonClick", true, {
                    button_name: "CreateAutomation",
                  });
                  if (!isIntegrationActivated()) {
                    enqueueSnackbar("Please integrate an instagram account", {
                      variant: "error",
                    });
                    return;
                  }
                  setShowAutomationDetails(true);
                }}
                style={{ backgroundColor: primaryActionColor }}
                icon={<PlusOutlined />}
              >
                Create New Automation
              </Button>
            </Empty>
          ) : (
            getPresetPostsForTab(tabIndex)?.map((media) => (
              <AutomationCard
                media={media}
                onClick={() => {
                  setShowAutomationDetails(true);
                  const automationData = handleGetAutomationData(media);
                  setSelectedAutomationData(automationData);
                }}
              />
            ))
          )}
        </PostGrid>
      )}
      <SideBarSetup
        selectedMedia={selectedMedia}
        presetDetails={
          selectedMedia && presetPosts && presetPosts[selectedMedia.id]
        }
        onClose={() => setSelectedMedia(null)}
        onSaveMapping={handleSaveMapping}
        existingComments={comments[selectedMedia?.id]}
        updateBackConfirmedData={(data) => {
          setPresetPosts((prevState) => ({
            ...prevState, // Spread the existing state
            [data.mediaId]: {
              // Spread the existing `id1` data
              ...data, // Merge the new data into `id1`
            },
          }));
        }}
      />
    </AddFlex>
  );
}

export default InstaDm;
