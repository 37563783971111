import { useSnackbar } from "notistack";
import { useState } from "react";
import {
  AddFlex,
  ContentHeader,
  CustomInput,
  CustomText,
  Group,
  PrimaryActionButton,
} from "../reusableStyles";
import { CircularProgress, Divider, MenuItem, Select } from "@mui/material";
import { useDispatch } from "react-redux";
import { usersApiCall } from "../Store/userSlice";
import { getApiCallParams, isNullOrUndefined } from "../utils";
import {
  UpdateContactDetails,
  UpdateUserAdditionalDetails,
} from "../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { THUNK_API_STATES } from "../constants";
import AdvancedTabToggleTab from "../CreateOffering/AdvancedTabToggleTab";
import MobileNumber from "../MobileNumber";
import useWindowSize from "../Hooks/useWindowSize";

function ContactDetails({ countries }) {
  const { apiUserData } = useSelector((state) => state.user);
  const { isMobileView } = useWindowSize();
  const [userAdditionalDetails, setUserAdditionalDetails] = useState(
    apiUserData.UserAdditionalDetails
  );
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const handleSubmit = async () => {
    setIsLoading(true);
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateContactDetails, userAdditionalDetails)
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        enqueueSnackbar("Contact details saved", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        enqueueSnackbar("Something went wrong, please try again", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 6000,
        });
      }
      setIsLoading(false);
    });
  };

  const handleShareContactDetailsToCustomersAfterBookings = (checked) => {
    if (isNullOrUndefined(userAdditionalDetails.userWhatsappNumber)) {
      return enqueueSnackbar("Please enter whatsapp number");
    }
    setUserAdditionalDetails((prev) => ({
      ...prev,
      shareContactDetailsToCustomers: checked,
    }));
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateUserAdditionalDetails, {
          key: "shareContactDetailsToCustomers",
          value: checked,
        })
      )
    );
  };

  const handleBookingRecieveUpdatesOnWhatsApp = (checked) => {
    if (isNullOrUndefined(userAdditionalDetails.userWhatsappNumber)) {
      return enqueueSnackbar("Please enter whatsapp number");
    }
    setUserAdditionalDetails((prev) => ({
      ...prev,
      recieveBookingUpdatesOnWhatsapp: checked,
    }));
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateUserAdditionalDetails, {
          key: "recieveBookingUpdatesOnWhatsapp",
          value: checked,
        })
      )
    );
  };

  const getUserCountryCode = (phoneNumber) => {
    return phoneNumber?.split("-")[0];
  };
  const getPhoneNumber = (phoneNumber) => {
    return phoneNumber?.split("-")[1];
  };
  const handleChangeContactDetails = (key, value) => {
    // if (key === "userWhatsaapCountryCode") {
    //   value =
    //     value + "-" + userAdditionalDetails?.userWhatsappNumber?.split("-")[1];
    //   key = "userWhatsappNumber";
    // } else if (key === "userWhatsappNumber") {
    //   value =
    //     userAdditionalDetails?.userWhatsappNumber?.split("-")[0] + "-" + value;
    // } else if (key === "userPhoneCountryCode") {
    //   value =
    //     value + "-" + userAdditionalDetails?.userPhoneNumber?.split("-")[1];
    //   key = "userPhoneNumber";
    // } else {
    //   value =
    //     userAdditionalDetails?.userPhoneNumber?.split("-")[0] + "-" + value;
    // }
    console.log(key, value);
    setUserAdditionalDetails({
      ...userAdditionalDetails,
      [key]: value,
    });
  };
  return (
    <>
      <AddFlex
        padding="20px"
        backgroundColor="white"
        borderRadius="12px"
        style={{ height: "max-content", width: "100%" }}
        flexDirection="column"
      >
        <AddFlex flexDirection="column" width={isMobileView() ? "100%" : "50%"}>
          <AddFlex flexDirection="column" margin="0 0 24px 0">
            <MobileNumber
              title={"Phone Number"}
              number={userAdditionalDetails?.userPhoneNumber}
              handleGetMobileNumber={(number) => {
                handleChangeContactDetails("userPhoneNumber", number);
              }}
            />
          </AddFlex>
          <AddFlex flexDirection="column" margin="0 0 24px 0">
            <MobileNumber
              title={"Whatsapp Number"}
              number={userAdditionalDetails?.userWhatsappNumber}
              handleGetMobileNumber={(number) => {
                handleChangeContactDetails("userWhatsappNumber", number);
              }}
            />
          </AddFlex>
          <AdvancedTabToggleTab
            checked={Boolean(
              userAdditionalDetails.shareContactDetailsToCustomers
            )}
            tabName={"Share contact details with customers after bookings."}
            margin={"0px 0 24px 0"}
            onChange={(e) => {
              handleShareContactDetailsToCustomersAfterBookings(
                e.target.checked
              );
            }}
          ></AdvancedTabToggleTab>
          <AdvancedTabToggleTab
            checked={Boolean(
              userAdditionalDetails.recieveBookingUpdatesOnWhatsapp
            )}
            tabName={"Sync bookings to whatsapp"}
            margin={"0px 0 24px 0"}
            onChange={(e) => {
              handleBookingRecieveUpdatesOnWhatsApp(e.target.checked);
            }}
          ></AdvancedTabToggleTab>
          <PrimaryActionButton onClick={handleSubmit}>
            {isLoading ? (
              <CircularProgress size={14} sx={{ color: "white" }} />
            ) : (
              "Save changes"
            )}
          </PrimaryActionButton>
        </AddFlex>
      </AddFlex>
    </>
  );
}

export default ContactDetails;
