import React, { useEffect } from "react";
import { AddFlex } from "../../reusableStyles";
import { BoltOutlined } from "@mui/icons-material";
import { useNavigate } from "react-router";
import { INSTAGRAM_CREDITS } from "../../OnboardingPage/routeNames";
import { useSelector } from "react-redux";
import { callNonStoreApi } from "../../NonStoreApis";
import { FetchUserInstaCredits } from "../../Api/ApiEndPoints";
import { clientInstaPlan } from "../../Store/instagramSlice";
import { useDispatch } from "react-redux";
import { isNullOrUndefined } from "../../utils";

function CreditsTopbarButton() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { credits } = useSelector((state) => state.instagram.otherData);

  const handleSetClientUserCredits = async () => {
    try {
      const response = await callNonStoreApi(FetchUserInstaCredits);
      dispatch(clientInstaPlan(response.data));
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    handleSetClientUserCredits();
  }, []);

  return (
    <AddFlex
      backgroundColor="#2D8F4D"
      alignItems="center"
      gap="10px"
      style={{
        color: "white",
        padding: "8px 10px",
        borderRadius: "25px",
        fontWeight: "500",
        cursor: "pointer",
      }}
      onClick={() => {
        navigate(INSTAGRAM_CREDITS);
      }}
    >
      <AddFlex backgroundColor="white" padding="5px" borderRadius="50%">
        <BoltOutlined
          sx={{ fontSize: "16px", color: "#2D8F4D", cursor: "pointer" }}
        />
      </AddFlex>
      {credits?.totalCreditsRemaining === -1
        ? ""
        : credits?.totalCreditsRemaining}
    </AddFlex>
  );
}

export default CreditsTopbarButton;
