import React from "react";
import { Image } from "./UserInstaPosts";
import { primaryActionColor } from "../../constants";
import { AddFlex, SecondaryActionButton } from "../../reusableStyles";

function InstaResentPostsOrStories({
  items,
  disabled,
  setMediaData,
  mediaData,
  isStory,
  openDialog,
}) {
  if (mediaData.mediaId) {
    return (
      <AddFlex flexDirection="column">
        <div
          key={mediaData.mediaId}
          style={{
            width: "23%",
            aspectRatio: 0.7,
            overflow: "hidden",
            borderRadius: "8px",
            border: "3px solid transparent",
          }}
        >
          <Image
            src={mediaData.mediaUrl}
            style={{
              width: "100%",
              height: "100%",
              cursor: "pointer",
              borderRadius: "6px",
            }}
            onClick={() => {
              if (disabled) return;
            }}
          />
        </div>
        <SecondaryActionButton
          textOnly
          style={{
            width: "max-content",
            margin: "10px 10px 0px 10px",
          }}
          onClick={() => {
            if (disabled) return;
            openDialog();
          }}
        >
          Show More
        </SecondaryActionButton>
      </AddFlex>
    );
  }

  return (
    <AddFlex flexDirection="column">
      <AddFlex>
        {items.map((media) => (
          <div
            key={media.id}
            style={{
              width: "23%",
              aspectRatio: 0.7,
              overflow: "hidden",
              borderRadius: "8px",
              border:
                mediaData.mediaId !== media.id
                  ? "3px solid transparent"
                  : `3px solid ${primaryActionColor}`,
            }}
          >
            <Image
              src={
                media.media_type === "VIDEO"
                  ? media.thumbnail_url
                  : media.media_url
              }
              style={{
                width: "100%",
                height: "100%",
                cursor: "pointer",
                borderRadius: "6px",
              }}
              onClick={() => {
                if (disabled) return;
                setMediaData((prev) => ({
                  ...prev,
                  mediaId: media.id,
                  media_type: media.media_type,
                  mediaUrl:
                    media.media_type === "VIDEO"
                      ? media.thumbnail_url
                      : media.media_url,
                  //   isStory: true,
                }));
              }}
            />
          </div>
        ))}
      </AddFlex>
      {items.length >= 3 && (
        <SecondaryActionButton
          textOnly
          style={{
            width: "max-content",
            margin: "10px 10px 0px 10px",
          }}
          onClick={() => {
            if (disabled) return;
            openDialog();
          }}
        >
          Show More
        </SecondaryActionButton>
      )}
    </AddFlex>
  );
}

export default InstaResentPostsOrStories;
