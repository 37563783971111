import { storage } from "./firebase-config";
import axios, { getAdminFrontendURL, getLoginURL } from "./axios";
import { saveAs } from "file-saver";
import { toZonedTime } from "date-fns-tz";

import { addDays, differenceInDays, format, set } from "date-fns";
import { NEW_PAGE_ROUTE } from "./OnboardingPage/routeNames";
import {
  LeadState,
  primaryActionColor,
  primaryActionColorLight,
  primaryTextColor,
} from "./constants";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { v4 } from "uuid";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { CircularProgress } from "@mui/material";
import moment from "moment-timezone";
import ShortUniqueId from "short-unique-id";
import { callNonStoreApi } from "./NonStoreApis";
import { LogInstaAnalyticsEvent, LogoutUser } from "./Api/ApiEndPoints";
import { Delete, Edit, Error } from "@mui/icons-material";
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined";
import UpgradeOutlinedIcon from "@mui/icons-material/UpgradeOutlined";
import JSZip from "jszip";
import html2canvas from "html2canvas";
import { enqueueSnackbar } from "notistack";

let getPersistorRef = () => ({});
export const setPersistorRef = (newPersistorRef) => {
  getPersistorRef = newPersistorRef;
};

export const FeaturesKeys = {
  event: {
    id: "featureeb5aefdc-f7d4-4cab-8c27-5cfa6c06209a",
    name: "Event",
  },
  oneonone: {
    id: "feature49b723cd-32d2-4cf6-99d0-0e688dfd57cf",
    name: "oneonone",
  },
  course: {
    id: "feature_7c11a4b9-c6b0-4902-9028-8c9b31c7f7ab",
    name: "Course",
  },
  testimonial: {
    id: "feature_03d589d5-d667-4981-9b00-1ae4d6f7ec6f",
    name: "Testimonial",
  },
  travel: {
    id: "feature_8752ae24-4f3b-45a5-89a4-ceef20c15be7",
    name: "Travel",
  },
  webinar: {
    id: "feature_f44a407e-e5e1-4f5d-825b-dbd486482d94",
    name: "Webinar",
  },
  subscription: {
    id: "feature_47aeb979-c2a5-45ef-a15e-445e41abca17",
    name: "Subscription",
  },
};

export const DashboardLinkIcons = [
  {
    id: "icon_3",
    icon: (
      <UpgradeOutlinedIcon sx={{ color: primaryTextColor, fontSize: "18px" }} />
    ),
    action: "moveOut",
    isSection: true,
    tooltipText: "Move this link out of the section",
  },
  {
    id: "icon_0",
    icon: (
      <ContentCopyOutlinedIcon
        sx={{ color: primaryTextColor, fontSize: "18px" }}
      />
    ),
    action: "copy",
    tooltipText: "Copy public url",
  },
  {
    id: "icon_1",
    icon: <Edit sx={{ fontSize: "18px" }} />,
    action: "edit",
    tooltipText: "Edit",
  },
  {
    id: "icon_4",
    icon: <Delete sx={{ fontSize: "18px" }} />,
    action: "delete",
    tooltipText: "Remove from live",
  },
  {
    id: "icon_2",
    action: null,
  },
];

export function isValidLink(text) {
  const urlRegex = /^(https?:\/\/)?([\w.-]+)\.([a-z]{2,})([\/\w.-]*)*\/?$/i;
  return urlRegex.test(text);
}

export const isValidURL = (url) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};

export const Bodhi_Login_Domain = "coachlogin.bodhischoolofyoga.com";

export const logout = async (store, params) => {
  const userId = store.getState().user.apiUserData.userId;
  var loginDomain = null;
  if (!isNullOrUndefined(store.getState().user.apiUserData.subdomainClient)) {
    loginDomain =
      "https://" +
      store.getState().user.apiUserData.subdomainClient.data.domain;
  } else if (!isNullOrUndefined(localStorage.getItem("loginDomain"))) {
    loginDomain = "https://" + localStorage.getItem("loginDomain");
  } else {
    loginDomain = "https://login.growezy.club";
  }
  if (process.env.NODE_ENV !== "production") {
    loginDomain = "http://localhost:3000";
  }

  if (!isNullOrUndefined(params)) {
    let params_str = "";
    const paramsLength = Object.entries(params).length;
    Object.entries(params).forEach(([key, value], index) => {
      params_str += `${key}=${value}`;
      if (index !== paramsLength - 1) {
        params_str += "&";
      }
    });
    loginDomain += `?${params_str}`;
  }

  getPersistorRef().purge();
  store.dispatch({ type: "RESET_DATA" });
  if (isNullOrUndefined(userId)) {
    //User has been logged out.
    const response = await callNonStoreApi(LogoutUser, { uid: userId });
    if (!isNullOrUndefined(response)) {
      if (response.data.action) {
        window.location.href = loginDomain;
      }
    }
    return;
  }
  const response = await callNonStoreApi(LogoutUser, { uid: userId });
  if (!isNullOrUndefined(response)) {
    if (response.data.action) {
      window.location.href = loginDomain;
    }
  }
};

export const handleAdminOnbordingRedirectURL = async (userId, email) => {
  return await axios
    .get(`/public/adminUserOrboardingRedirectURL`, {
      headers: {
        userid: userId,
        email: email,
      },
    })
    .then((res) => {
      const { isUserPresent, isUserOnboardApproved, navURL, email } = res.data;
      return {
        isUserPresent: isUserPresent,
        isOnboardApproved: isUserOnboardApproved,
        email: email,
        navURL,
      };
    })
    .catch((err) => {
      console.log(err);
    });
};

export const isStringNullOrUndefined = (obj) => {
  return obj === null || obj === undefined || obj.length <= 0;
};

export const isNullOrUndefined = (obj) => {
  return obj === null || obj === undefined;
};

export const isArrayNullOrUndefined = (arr) => {
  return arr === null || arr === undefined || arr.length === 0;
};

export const containsSpecialCharacters = (str) => {
  // Regular expression pattern to match special characters
  const specialCharsPattern = /[!@#$%^&*(),.?":{}|<>]/;

  // Check if the string matches the pattern
  return specialCharsPattern.test(str);
};

export const capitalizeFirstLetter = (string) => {
  if (isNullOrUndefined(string)) return "";
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const getPageName = () => {
  switch (window.location.pathname) {
    case NEW_PAGE_ROUTE: {
      return "Create new offering";
    }
  }
};

export const getDuration = (hours, mins) => {
  let duration = "";
  const _hours = isNullOrUndefined(hours) ? 0 : parseInt(hours);
  const _mins = isNullOrUndefined(mins) ? 0 : parseInt(mins);
  if (_hours !== 0) {
    duration += `${_hours} h `;
  }

  if (_mins !== 0) duration += `${_mins} m`;

  return duration;
};

export const generateUUID = () => {
  const uuid = new ShortUniqueId({ length: 7 });
  return uuid.rnd(7);
};

export const getApiCallParams = (endpoint, payload, extraHeaders) => {
  return {
    endpoint: endpoint,
    payload: payload,
    extraHeaders,
  };
};

export const get24HourTime = (date) => {
  const now = new Date(date);

  // Get hours and minutes from the Date object
  const hours = now.getHours(); // 0-23
  const minutes = now.getMinutes(); // 0-59

  // Format hours and minutes to ensure two digits (leading zero, if necessary)
  const formattedHours = hours.toString().padStart(2, "0");
  const formattedMinutes = minutes.toString().padStart(2, "0");

  // Concatenate hours and minutes to get time in HH:MM format
  const timeIn24HourFormat = `${formattedHours}:${formattedMinutes}`;
  return timeIn24HourFormat;
};

export const getCurrentTime = () => {
  const today = new Date();
  var hours = today.getHours();
  var mins = today.getMinutes();
  var hoursStr = hours > 9 ? hours : "0" + hours;
  var minsStr = mins > 9 ? mins : "0" + mins;
  return `${hoursStr}:${minsStr}`;
};

export const getTimeInTwelveHourForm = (time) => {
  if (isNullOrUndefined(time)) return "";
  var hours = Number(time.slice(0, 2));
  var amOrPm = "AM";
  var mins = time.slice(3);
  if (hours > 12) {
    hours = hours % 12;

    amOrPm = "PM";
  } else if (hours === 12) {
    amOrPm = "PM";
  } else {
    amOrPm = "AM";
  }
  if (hours < 10) {
    hours = "0" + hours;
  }
  return `${hours}:${mins} ${amOrPm}`;
};

export const getLeadStateString = (state) => {
  switch (state) {
    case LeadState.NewLead:
      return "New Lead";
    case LeadState.InProgress:
      return "In Progress";
    case LeadState.Payments:
      return "Awaiting Payments";
    case LeadState.Closed:
      return "Closed";
    default:
      return "";
  }
};

export const getNoticePeriodOptions = () => {
  return [
    {
      id: "notice_period_id_1",
      name: "15 Minutes",
      value: 15,
    },
    {
      id: "notice_period_id_2",
      name: "30 Minutes",
      value: 30,
    },
    {
      id: "notice_period_id_3",
      name: "1 Hour",
      value: 60,
    },
    {
      id: "notice_period_id_4",
      name: "2 hours",
      value: 60,
    },
    {
      id: "notice_period_id_5",
      name: "1 Day",
      value: 1440,
    },
    {
      id: "notice_period_id_6",
      name: "2 Days",
      value: 1440 * 2,
    },
    {
      id: "notice_period_id_7",
      name: "3 Days",
      value: 1440 * 3,
    },
    {
      id: "notice_period_id_8",
      name: "1 Week",
      value: 24 * 60 * 7,
    },
  ];
};

// export const Currency = {
//   INR: "INR",
//   USD: "USD",
// };

export const FullCurrency = {
  INR: "Indian Rupee (INR)",
  USD: "United States Dollar (USD)",
  GBP: "Great British Pound (GBP)",
  AUD: "Australian Dollar (AUD)",
  CAD: "Canadian Dollar (CAD)",
};

export const Currency = {
  INR: "INR",
  USD: "USD",
  GBP: "GBP",
  AUD: "AUD",
  CAD: "CAD",

  // AED: "AED",
  // AFN: "AFN",
  // ALL: "ALL",
  // AMD: "AMD",
  // ANG: "ANG",
  // AOA: "AOA",
  // ARS: "ARS",
  // AWG: "AWG",
  // AZN: "AZN",
  // BAM: "BAM",
  // BBD: "BBD",
  // BDT: "BDT",
  // BGN: "BGN",
  // BIF: "BIF",
  // BMD: "BMD",
  // BND: "BND",
  // BOB: "BOB",
  // BRL: "BRL",
  // BSD: "BSD",
  // BWP: "BWP",
  // BYN: "BYN",
  // BZD: "BZD",
  // CDF: "CDF",
  // CHF: "CHF",
  // CLP: "CLP",
  // CNY: "CNY",
  // COP: "COP",
  // CRC: "CRC",
  // CVE: "CVE",
  // CZK: "CZK",
  // DJF: "DJF",
  // DKK: "DKK",
  // DOP: "DOP",
  // DZD: "DZD",
  // EGP: "EGP",
  // ETB: "ETB",
  // EUR: "EUR",
  // FJD: "FJD",
  // FKP: "FKP",
  // GEL: "GEL",
  // GIP: "GIP",
  // GMD: "GMD",
  // GNF: "GNF",
  // GTQ: "GTQ",
  // GYD: "GYD",
  // HKD: "HKD",
  // HNL: "HNL",
  // HTG: "HTG",
  // HUF: "HUF",
  // IDR: "IDR",
  // ILS: "ILS",
  // ISK: "ISK",
  // JMD: "JMD",
  // JPY: "JPY",
  // KES: "KES",
  // KGS: "KGS",
  // KHR: "KHR",
  // KMF: "KMF",
  // KRW: "KRW",
  // KYD: "KYD",
  // KZT: "KZT",
  // LAK: "LAK",
  // LBP: "LBP",
  // LKR: "LKR",
  // LRD: "LRD",
  // LSL: "LSL",
  // MAD: "MAD",
  // MDL: "MDL",
  // MGA: "MGA",
  // MKD: "MKD",
  // MMK: "MMK",
  // MNT: "MNT",
  // MOP: "MOP",
  // MUR: "MUR",
  // MVR: "MVR",
  // MWK: "MWK",
  // MXN: "MXN",
  // MYR: "MYR",
  // MZN: "MZN",
  // NAD: "NAD",
  // NGN: "NGN",
  // NIO: "NIO",
  // NOK: "NOK",
  // NPR: "NPR",
  // NZD: "NZD",
  // PAB: "PAB",
  // PEN: "PEN",
  // PGK: "PGK",
  // PHP: "PHP",
  // PKR: "PKR",
  // PLN: "PLN",
  // PYG: "PYG",
  // QAR: "QAR",
  // RON: "RON",
  // RSD: "RSD",
  // RUB: "RUB",
  // RWF: "RWF",
  // SAR: "SAR",
  // SBD: "SBD",
  // SCR: "SCR",
  // SEK: "SEK",
  // SGD: "SGD",
  // SHP: "SHP",
  // SLE: "SLE",
  // SOS: "SOS",
  // SRD: "SRD",
  // STD: "STD",
  // SZL: "SZL",
  // THB: "THB",
  // TJS: "TJS",
  // TOP: "TOP",
  // TRY: "TRY",
  // TTD: "TTD",
  // TWD: "TWD",
  // TZS: "TZS",
  // UAH: "UAH",
  // UGX: "UGX",
  // UYU: "UYU",
  // UZS: "UZS",
  // VND: "VND",
  // VUV: "VUV",
  // WST: "WST",
  // XAF: "XAF",
  // XCD: "XCD",
  // XOF: "XOF",
  // XPF: "XPF",
  // YER: "YER",
  // ZAR: "ZAR",
  // ZMW: "ZMW",
};

export const planColorCodes = [
  {
    backgroundColor: "#F9F5FF",
    border: "#E9D7FE",
    textColor: primaryActionColor,
  },
  {
    backgroundColor: "#EFF8FF",
    border: "#B2DDFF",
    textColor: "#175CD3",
  },
  {
    backgroundColor: "#EEF4FF",
    border: "#C7D7FE",
    textColor: "#3538CD",
  },
];

const getDefaultPricesMap = () => {
  const prices = {};
  prices[Currency.INR] = 99900;
  prices[Currency.USD] = 1200;
  return prices;
};

export const defaultPlan = () => {
  const defultPlanObj = {
    id: generateUUID(),
    ticketName: "Early Bird",
    totalSeats: -1,
    active: true,
    isFree: false,
    prices: getDefaultPricesMap(),
    planColorCodes: planColorCodes[0],
  };
  return [defultPlanObj];
};
export const daysOfWeek = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];
export const getToday = () => {
  const date = new Date();
  date.setHours(0);
  date.setMinutes(0);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
};

export const Status = {
  LIVE: "live",
  CREATED: "created",
  DRAFT: "draft",
  DELETED: "deleted",
  CANCELLED: "cancelled",
  PUBLISHED: "published",
  UNPUBLISHED: "unpublished",
  ARCHIVED: "archived",
};

export function compareTimeStrings(timeString1, timeString2) {
  const [hours1, minutes1] = timeString1.split(":").map(Number);
  const [hours2, minutes2] = timeString2.split(":").map(Number);

  if (hours1 !== hours2) {
    return hours1 - hours2;
  } else {
    return minutes1 - minutes2;
  }
}

export const getCurrencySymbol = (currency) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });

  // Format a temporary value and extract the symbol by removing the number part
  return numberFormat.formatToParts(1).find((part) => part.type === "currency")
    .value;
};

export const formatPrice = (amount, currency) => {
  const numberFormat = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency,
    currencyDisplay: "symbol",
  });

  const parts = numberFormat.formatToParts(amount);
  let zeroDecimalCurrency = true;
  for (let part of parts) {
    if (part.type === "decimal") {
      zeroDecimalCurrency = false;
    }
  }
  amount = zeroDecimalCurrency ? amount : amount / 100;
  const total = Number(amount).toFixed(2);
  return numberFormat.format(total);
};

export const convertToFixedPointDecimal = (input) => {
  if (input === 0) return 0;
  // Check if the parsed value is a valid number (not NaN)
  // const input = parseFloat(_input).toFixed(2);
  input = parseFloat(parseFloat(input).toFixed(2));
  if (!isNaN(input)) {
    // Round the parsed value to two decimal places
    const storedPrice = Math.round(input * 100);
    if (storedPrice >= 0) return storedPrice;
  }

  // Return null for invalid input
  return null;
};

const getRawPrice = (ticket, userCurrency) => {
  return ticket.prices[userCurrency] / 100;
};

export const defaultPlanColorCode = {
  backgroundColor: "#FFF8EB",
  border: "#FFE6B9",
  textColor: "#763C02",
};

export const isNullOrZero = (number) => {
  // Check if the number is null or approximately equal to 0
  return isNullOrUndefined(number) || Math.abs(number) < 0.0001; // You can adjust the tolerance (0.0001) as needed
};

export const handleImageUpload = async (
  file,
  userName,
  isProfileImage,
  _storage,
  path
) => {
  if (file == null || file === undefined) return "";
  const storageRef = ref(
    _storage ? _storage : storage,
    path ? path : `${userName}/${generateUUID() + file.name}`
  );

  return await uploadBytes(storageRef, file).then(async (snapshot) => {
    return await getDownloadURL(snapshot.ref).then((url) => {
      return url;
    });
  });
};

export const isINR = (currency) => {
  return currency === Currency.INR;
};

export const PGCharges = {
  Growezy: {
    INR: 0.025,
    USD: 0.071,
  },
  Razorpay: {
    INR: 0.025,
    USD: 0.034,
  },
};

export const GROWEZY_COMMISSION = 0.02;
export const GROWEZY_MAX_COMMISSION = 25000;
export const PhonePe_Convenience = 0.025;
export const Stripe_Convenience = 0.071;
export const GST = 0.18;

export const getTimezones = () => {
  return moment.tz.names().map((zone) => {
    return {
      id: `${zone} (${moment.tz(zone).format("Z")})_id`,
      name: `${zone} (${moment.tz(zone).format("Z")})`,
      value: zone,
    };
  });
};

export const getAdvanceBookingsSlots = () => {
  return [
    {
      id: "1_week_id",
      name: "1 Week",
      value: 7,
    },
    {
      id: "2_week_id",
      name: "2 Week",
      value: 14,
    },
    {
      id: "3_week_id",
      name: "3 Week",
      value: 21,
    },
    {
      id: "1_month_id",
      name: "1 Month",
      value: 30,
    },
    {
      id: "2_months_id",
      name: "2 Months",
      value: 60,
    },
  ];
};

export const handleGetDateAfterXDays = (_date, days) => {
  var date = new Date(_date);
  // Add the specified number of days
  date.setDate(date.getDate() + Number(days) - 1);

  // Return the updated Date object
  return date;
};

export const admins = {
  growezy: "5sQQejJKkRMdr8R70DovobMUq2h1",
};

export const getFeatureKeyFromId = (featureId) => {
  return Object.values(FeaturesKeys).find(
    (featureKey) => featureKey.id === featureId
  );
};

export const dashboardFeatureIdentifierBackgroundColor = {
  [FeaturesKeys.event.id]: "#FFE6B9",
  [FeaturesKeys.oneonone.id]: "#fff",
  [FeaturesKeys.course.id]: primaryActionColorLight,
  [FeaturesKeys.travel.id]: "#ECFDF3",
  [FeaturesKeys.subscription.id]: "#ECFDF3",
  [FeaturesKeys.webinar.id]: "#ECFDF3",
};
export const dashboardFeatureIdentifierTextColor = {
  "featurecb083043-0d62-443b-821f-0bbc17e418a8": "#fff",
  [FeaturesKeys.event.id]: "#753c03",
  [FeaturesKeys.oneonone.id]: "#fff",
  [FeaturesKeys.course.id]: primaryActionColor,
  [FeaturesKeys.travel.id]: "#067647",
  [FeaturesKeys.subscription.id]: "#067647",
  [FeaturesKeys.webinar.id]: "#067647",
};

export const getDefaultCourse = (commission, isConvenienceIncluded) => {
  return {
    id: generateUUID(),
    notes: [{ title: "", description: "" }],
    coverImage: [],
    imageGallery: [],
    isConvinienceIncluded: isConvenienceIncluded,
    commission: commission,
    description: "",
    skillLevel: "beginner",
    days: 2,
    isMultiday: false,
    status: Status.UNPUBLISHED,
    ctas: [
      {
        key: "collect_payments",
        name: "Register Now",
        actionName: "Collect Payments",
        type: CTA_Button_Types.PAYMENTS,
      },
    ],
    name: "",
    oneLineSummary: "",
    loctionDetails: {},
    timezone: getClientTimezone(),
    primaryCurrency: Currency.INR,
    // weekdays: [0, 1, 0, 1, 0, 1, 0],
    scheduleDetails: [
      {
        dateId: generateUUID(),
        date: getToday(),
        endDate: handleGetDateAfterXDays(getToday(), 14),
        weekdays: [0, 0, 0, 0, 0, 0, 0],
        timeSlots: [
          {
            id: generateUUID(),
            time: getCurrentTime(),
            tickets: [],
            totalSeats: -1,
          },
        ],
      },
    ],
  };
};

export const getDefault = (commission, isConvenienceIncluded) => {
  return {
    id: generateUUID(),
    notes: [],
    coverImage: [],
    imageGallery: [],
    isConvinienceIncluded: isConvenienceIncluded,
    commission: commission,
    description: "",
    skillLevel: "beginner",
    days: 2,
    primaryCurrency: Currency.INR,

    isMultiday: false,
    status: Status.UNPUBLISHED,
    ctas: [
      {
        key: "collect_payments",
        name: "Register Now",
        actionName: "Collect Payments",
        type: CTA_Button_Types.PAYMENTS,
      },
    ],
    name: "",
    oneLineSummary: "",
    loctionDetails: {
      isOnline: false,
    },
    timezone: getClientTimezone(),
    scheduleDetails: [
      {
        dateId: generateUUID(),
        date: getToday(),
        timeSlots: [
          {
            id: generateUUID(),
            time: getCurrentTime(),
            tickets: [],
            totalSeats: -1,
          },
        ],
      },
    ],
  };
};

export const getDefaultWebinar = (commission, isConvenienceIncluded) => {
  return {
    id: generateUUID(),
    notes: [],
    coverImage: [],
    imageGallery: [],
    isConvinienceIncluded: isConvenienceIncluded,
    commission: commission,
    primaryCurrency: Currency.INR,
    description: "",
    skillLevel: "beginner",
    days: 2,
    isMultiday: false,
    status: Status.UNPUBLISHED,
    ctas: [
      {
        key: "collect_payments",
        name: "Register Now",
        actionName: "Collect Payments",
        type: CTA_Button_Types.PAYMENTS,
      },
    ],
    name: "",
    oneLineSummary: "",
    locationDetails: {
      isOnline: true,
    },
    timezone: getClientTimezone(),
    scheduleDetails: [
      {
        dateId: generateUUID(),
        date: getToday(),
        timeSlots: [
          {
            id: generateUUID(),
            time: getCurrentTime(),
            tickets: [],
            totalSeats: -1,
          },
        ],
      },
    ],
  };
};

export const getDefaultOnetoOneOffering = (
  commission,
  isConvenienceIncluded
) => {
  return {
    id: generateUUID(),
    notes: [],
    coverImage: [],
    imageGallery: [],
    isConvinienceIncluded: isConvenienceIncluded,
    commission: commission,
    primaryCurrency: Currency.INR,
    description: "",
    skillLevel: "beginner",
    days: 2,
    isMultiday: false,
    status: Status.UNPUBLISHED,
    ctas: [
      {
        key: "collect_payments",
        name: "Register Now",
        actionName: "Collect Payments",
        type: CTA_Button_Types.PAYMENTS,
      },
    ],
    name: "",
    oneLineSummary: "",
    locationDetails: {
      isOnline: true,
    },
    themeColors: {
      mode: "light",
      buttonColors: "#000000",
      buttonTextColors: "#ffffff",
      accentColors: "#8C5301",
    },
    timezone: getClientTimezone(),
    scheduleDetails: [],
  };
};

export const getSubscriptionOffering = (commission, isConvenienceIncluded) => {
  return {
    id: generateUUID(),
    notes: [],
    coverImage: [],
    imageGallery: [],
    isConvinienceIncluded: isConvenienceIncluded,
    commission: commission,
    description: "",
    skillLevel: "beginner",
    days: 2,
    status: Status.UNPUBLISHED,
    ctas: [
      {
        key: "collect_payments",
        name: "Register Now",
        actionName: "Collect Payments",
        type: CTA_Button_Types.PAYMENTS,
      },
    ],
    name: "",
    oneLineSummary: "",
    primaryCurrency: Currency.INR,
    loctionDetails: {},
    timezone: getClientTimezone(),
    scheduleDetails: [
      {
        weekdays: [0, 0, 0, 0, 0, 0, 0],
        timeSlots: [
          {
            id: generateUUID(),
            time: getCurrentTime(),
            tickets: [],
            totalSeats: -1,
          },
        ],
      },
    ],
  };
};

export const getTravelDefaultOffering = (commission, isConvenienceIncluded) => {
  return {
    id: generateUUID(),
    notes: [],
    coverImage: [],
    imageGallery: [],
    isConvinienceIncluded: isConvenienceIncluded,
    commission: commission,
    description: "",
    skillLevel: "beginner",
    days: 2,
    status: Status.UNPUBLISHED,
    ctas: [
      {
        key: "collect_payments",
        name: "Register Now",
        actionName: "Collect Payments",
        type: CTA_Button_Types.PAYMENTS,
      },
    ],
    name: "",
    oneLineSummary: "",
    primaryCurrency: Currency.INR,
    loctionDetails: {},
    timezone: getClientTimezone(),
    scheduleDetails: [
      {
        dateId: generateUUID(),
        date: getToday(),
        endDate: addDays(getToday(), 7),
        hours: "0",
        mins: "0",
        tickets: [],
      },
    ],
  };
};

export const defaultJoiningQuestions = [
  {
    id: "default_JQ_1",
    label: "What is your full name?",
    placeholder: "ex: Jhon Doe",
    type: "text",
    isRequired: true,
  },
  {
    id: "default_JQ_2",
    label: "How old are you? ",
    placeholder: "ex: 19",
    type: "number",
    isRequired: true,
  },
];

export const getSavedStateData = (state) => {
  switch (state) {
    case "saved": {
      return {
        icon: <CheckCircleIcon sx={{ color: "green", fontSize: "16px" }} />,
        text: "Saved",
        color: "green",
        variant: "success",
      };
    }
    case "unsaved": {
      return {
        icon: <WarningAmberIcon sx={{ color: "orange", fontSize: "16px" }} />,
        text: "Unsaved",
        color: "Orange",
        variant: "default",
      };
    }
    case "saving": {
      return {
        icon: <CircularProgress size={14} sx={{ color: primaryActionColor }} />,
        text: "Saving Changes",
        color: primaryActionColor,
        variant: "warning",
      };
    }
    case "error": {
      return {
        icon: <Error sx={{ color: "red", fontSize: "16px" }} />,
        text: "Cannot Save..",
        color: "red",
        variant: "error",
      };
    }
    default:
      return <></>;
  }
};

export const LeadPipelines = () => {
  return [
    {
      name: "New Leads",
      type: LeadState.NewLead,
    },
    {
      name: "In Progress",
      type: LeadState.InProgress,
    },
    {
      name: "Payments",
      type: LeadState.Payments,
    },
    {
      name: "Closed",
      type: LeadState.Closed,
    },
  ];
};

export function hasTimePassed(time24hrs) {
  // Split the time string into hours and minutes
  const [hours, minutes] = time24hrs.split(":").map(Number);

  // Create a Date object for today
  const now = new Date();

  // Create a Date object for the specific time today
  const specificTimeToday = new Date(
    now.getFullYear(),
    now.getMonth(),
    now.getDate(),
    hours,
    minutes
  );

  // Compare the current time with the specific time
  return now > specificTimeToday;
}

export const getRemainingDaysString = (date) => {
  const _date = new Date(date).setHours(0, 0, 0, 0);
  const today = new Date().setHours(0, 0, 0, 0);
  return differenceInDays(_date, today) === 1
    ? `1 Day to go`
    : differenceInDays(_date, today) > 1
    ? `${differenceInDays(_date, today)} days to go`
    : "Today";
};

export const handleOpenInNew = (url) => {
  window.open(url, "_blank");
};

export const openAdminFrontendUrl = (path) => {
  return handleOpenInNew(getAdminFrontendURL() + path);
};

export const OfferingTypes = [
  {
    id: "offering_type_1",
    name: "Events / Workshops",
    // description: "For in-person interactions",
    features: [
      "For Offline activities",
      "Single session activities",
      // "Multiple ticketing plans.",
      // "Manage availability of slots",
    ],
    pageType: FeaturesKeys.event.name,
  },
  {
    id: "offering_type_3",
    name: "Webinar",
    // description: "For online interactions",
    features: [
      "Online Activity",
      // "Integrate zoom / online",
      // "Send meeting links automatically",
      "Single session activities",
    ],
    pageType: FeaturesKeys.webinar.name,
  },
  {
    id: "offering_type_2",
    name: "Live Course",
    // description:
    //   "Best suited for live interactive programs with a start & end date.",
    features: ["Can be online/offline", "Multiple sessions"],
    pageType: FeaturesKeys.course.name,
  },

  {
    id: "offering_type_4",
    name: "Group Tours / Camps",
    // description: "For group tours or residential camps",
    features: ["Host residential camps", "Host group tours"],
    pageType: FeaturesKeys.travel.name,
  },
  {
    id: "offering_type_5",
    name: "Regular classes",
    // description: "Best suited for managing recurring payments",
    features: ["Can be online / offline", "Automate payment renewals"],
    pageType: FeaturesKeys.subscription.name,
  },
  {
    id: "offering_type_6",
    name: "1-1 Consultation",
    // description:
    //   "Best suited for creating and managing One to One Consultations",
    features: ["Can be online / offline", "Integrate zoom / g-meet"],
    pageType: FeaturesKeys.oneonone.name,
  },
];

export const CTA_Button_Types = {
  WHATSAPP: "whatsapp",
  PAYMENTS: "payments",
  GOOGLEMEET: "meet",
  CALL: "call",
  FORM: "form",
};

export const isCustomerOutofIndia = (userCurrency) => {
  return userCurrency !== Currency.INR;
};

export const getDefaultOffering = (UserAdditionalDetails, type) => {
  const commission = {
    isCommissionIncluded: UserAdditionalDetails.commission.isCommissionIncluded,
  };
  switch (type) {
    case FeaturesKeys.course.name:
      return getDefaultCourse(
        commission,
        UserAdditionalDetails.isConvenienceIncluded
      );
    case FeaturesKeys.event.name:
      return getDefault(
        commission,
        UserAdditionalDetails.isConvenienceIncluded
      );
    case FeaturesKeys.webinar.name:
      return getDefaultWebinar(
        commission,
        UserAdditionalDetails.isConvenienceIncluded
      );
    case FeaturesKeys.travel.name:
      return getTravelDefaultOffering(
        commission,
        UserAdditionalDetails.isConvenienceIncluded
      );
    case FeaturesKeys.subscription.name:
      return getSubscriptionOffering(
        commission,
        UserAdditionalDetails.isConvenienceIncluded
      );
    case FeaturesKeys.oneonone.name:
      return getDefaultOnetoOneOffering(
        commission,
        UserAdditionalDetails.isConvenienceIncluded
      );
    default:
      return {};
  }
};

export const getFormatedDateType1 = (date) => {
  return date
    .toLocaleString("en-US", {
      day: "2-digit",
      month: "short",
      year: "numeric",
      hour: "numeric",
      minute: "2-digit",
      hour12: true,
    })
    .replace(/,/g, "");
};

export const getOfferingBookingsHeaderList = (pageType) => {
  switch (pageType) {
    case FeaturesKeys.webinar.name:
    case FeaturesKeys.event.name: {
      return [
        ["10%", "Customer Name"],
        ["10%", "Booking Date"],
        ["10%", "Ticket"],
        ["10%", "Joining Question"],
        ["10%", "Amount Paid"],
        ["10%", "Actions"],
      ];
    }
    case FeaturesKeys.course.name: {
      return [
        ["10%", "Customer Name"],
        ["10%", "Booking Date"],
        ["10%", "Plan"],
        ["10%", "Joining Question"],
        ["10%", "Amount Paid"],
      ];
    }
    case FeaturesKeys.oneonone.name:
    case FeaturesKeys.travel.name: {
      return [
        ["10%", "Customer Name"],
        ["10%", "Booking Date"],
        ["10%", "Ticket"],
        ["10%", "Amount Paid"],
      ];
    }
    case FeaturesKeys.subscription.name: {
      return [
        ["20%", "Customer Name"],
        ["10%", "First Sale"],
        ["10%", "Plan"],
        ["20%", "Duration"],
        ["10%", "Status"],
        ["10%", "Amount Paid"],
        // ["10%", "Details"],
      ];
    }

    default:
      return [];
  }
};

export const getOfferingSharableURL = (
  username,
  offeringType,
  offeringId,
  offeringName
) => {
  const baseUrl = getFrontendBaseURL(username);
  return `${baseUrl}/${offeringType}/${offeringId}/${offeringName}`;
};

export const getFrontendBaseURL = (username) => {
  if (process.env.NODE_ENV === "production") {
    if (isNullOrUndefined(username)) {
      return `https://growezy.club`;
    }
    return `https://${username}.growezy.club/`;
  } else {
    if (isNullOrUndefined(username)) {
      return `http://localhost:3003`;
    }
    return `http://${username}.localhost:3003/`;
  }
};

export const formatDate = (date, formatString, timeZone) => {
  const zonedDate = toZonedTime(date, timeZone);
  return format(zonedDate, formatString);
};

export const setUTCDate = (date, hours, minutes, seconds) => {
  // Set the time on the date object in local time (will be used for conversion to UTC)
  const localDate = set(date, { hours, minutes, seconds, milliseconds: 0 });

  // Convert local date to UTC
  const utcDate = toZonedTime(localDate, "GMT");

  return utcDate;
};

export const handleCopyToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const PayoutStatus = {
  Bill: "Bill",
  Paid: "Paid",
  Cancelled: "Cancelled",
  Processing: "Processing",
};

export const getStatusBgColor = (status) => {
  if (status === PayoutStatus.Bill) return "orange";
  if (status === PayoutStatus.Processing) return "blue";
  if (status === PayoutStatus.Paid) return "green";
  if (status === PayoutStatus.Cancelled) return "red";
};

export const getOfferings = (features) => {
  if (isNullOrUndefined(features)) return [];
  const offerings = [];
  const featureIds = Object.values(FeaturesKeys);
  for (let featureId of featureIds) {
    if (featureId.id === FeaturesKeys.testimonial.id) continue;
    features[featureId.id]?.items?.forEach((item) => {
      offerings.push({ name: item.name, id: item.id });
    });
  }
  return offerings;
};

export function filterArray(firstArray, secondArray) {
  if (isArrayNullOrUndefined(firstArray) || isArrayNullOrUndefined(secondArray))
    return [];
  // Create a Set of ids from the first array for efficient lookup
  const firstArrayIds = new Set(firstArray.map((item) => item.id));

  // Filter the second array based on the condition
  const filteredArray = secondArray.filter((element) => {
    // Check if the offering.id exists in the firstArrayIds

    if (firstArrayIds.has(element.id)) {
      return true;
    }

    // Check if any of the tags' ids exist in the firstArrayIds
  });
  return filteredArray;
}

export const getFeatureKeyFromFeatureId = (featureId) => {
  return Object.values(FeaturesKeys).find(
    (feature) => feature.id === featureId
  );
};

export function isURL(str) {
  const urlPattern =
    /^(?:(?:https?|ftp):\/\/)?(?:\S+(?::\S*)?@)?(?:[\w-]+(?:\.[\w-]+)+)(?::\d{1,5})?(?:\/\S*)?$/i;
  return urlPattern.test(str);
}

export const httpsUrlGenerator = (url) => {
  if (url.slice(0, 12) === "https://www." || url.slice(0, 6) === "https:") {
    return url;
  } else if (
    url.slice(0, 12) === "http://www." ||
    url.slice(0, 5) === "http:"
  ) {
    return url;
  } else if (url.slice(0, 4) === "www.") {
    return `https://${url}`;
  } else {
    let str = "";
    str = "https://www." + url;
    return str;
  }
};
export const getLiveFeatureItem = (features, featureId, linkId) => {
  return features[featureId].publishedItems?.find((item) => item.id === linkId);
};

export const isFormReponse = (previewLevel) => {
  return previewLevel === 1;
};

export function extractTextFromRichText(richText) {
  // Create a new DOMParser instance
  const parser = new DOMParser();

  // Parse the rich text as HTML
  const doc = parser.parseFromString(richText, "text/html");

  // Use textContent to get the plain text, stripping out any HTML tags
  return doc.body.textContent;
}

export const sortDates = (scheduleDetails) => {
  return scheduleDetails.slice().sort((a, b) => {
    const dateA = new Date(a.date);
    const dateB = new Date(b.date);
    return dateB - dateA;
  });
};

export const rupee = () => {
  return "\u20B9";
};

export const isPlanPricesEmpty = (ticket) => {
  if (isNullOrUndefined(ticket)) return true;
  return (
    !ticket.isFree &&
    (isArrayNullOrUndefined(Object.values(ticket.prices)) ||
      Object.values(ticket.prices).includes(0))
  );
};

const checkHasEventCourseWebinarSubscriptionPlans = (scheduleDetails) => {
  let isPricingPlansPresent = true;
  for (let schedule of scheduleDetails) {
    if (isNullOrUndefined(schedule.timeSlots)) {
      isPricingPlansPresent = false;
      return;
    }
    for (let slot of schedule.timeSlots) {
      if (isArrayNullOrUndefined(slot.tickets)) {
        isPricingPlansPresent = false;
        break;
      } else {
        for (let ticket of slot.tickets) {
          if (isPlanPricesEmpty(ticket)) {
            isPricingPlansPresent = false;
            break;
          }
        }
      }
    }
  }
  return isPricingPlansPresent;
};
const checkHasTravePlans = (scheduleDetails) => {
  let isPricingPlansPresent = true;
  for (let schedule of scheduleDetails) {
    if (isArrayNullOrUndefined(schedule.tickets)) {
      isPricingPlansPresent = false;
      break;
    }
  }
  return isPricingPlansPresent;
};

export const SettlementStatus = {
  Pending: "Pending",
  Success: "Settled",
  ReadyToProcess: "Ready To Process",
  Processing: "Processing",
  Failed: "Failed",
  Refunded: "Refunded",
};

export const FILE_TYPES = {
  VIDEOS: "video",
  IMAGES: "image",
  DOCS: "application",
};

export const isOfferingPublishable = (offering, offeringType) => {
  if (isNullOrUndefined(offering)) return { complete: 0, errors: {} };
  const total_points = 10;
  let points = 0;
  let errors = {};

  //offering name validation.
  if (!isStringNullOrUndefined(offering.name.trim())) {
    points += 1;
  } else
    errors["name"] = { id: "name", err: "Offering name cannot be empty. " };
  //offering descripiton validation.
  if (
    !isStringNullOrUndefined(
      extractTextFromRichText(offering.description).trim()
    )
  ) {
    points += 1;
  } else {
    errors["description"] = {
      id: "description",
      err: "Offering description cannot be empty. ",
    };
  }

  //checking url-key
  if (!isStringNullOrUndefined(offering.urlKey)) {
    points += 1;
  } else {
    errors["urlKey"] = {
      id: "urlKey",
      err: "URL key cannot be empty. ",
    };
  }
  // cover image validation.
  if (!isArrayNullOrUndefined(offering.coverImage)) {
    points += 1;
  } else {
    errors["coverImage"] = {
      id: "coverImage",
      err: "Upload a cover image for your offering. ",
    };
  }

  //offering location details validations.
  if (!isArrayNullOrUndefined(offering.locationDetails)) {
    if (offering.locationDetails.isOnline) {
      if (isNullOrUndefined(offering.locationDetails.meet_id)) {
        errors["locationDetails"] = {
          id: "locationDetails",
          err: "Please choose the meeting platform",
        };
      } else if (
        offering.locationDetails.meet_id === "custom_video_call" &&
        isNullOrUndefined(offering.locationDetails.custom_joining_url) &&
        isNullOrUndefined(offering.locationDetails.custom_joining_meeting_id) &&
        isNullOrUndefined(
          offering.locationDetails.custom_joining_meeting_password
        )
      ) {
        errors["locationDetails"] = {
          id: "locationDetails",
          err: "Please enter the meeting details",
        };
      } else {
        points += 1;
      }
    } else {
      if (
        isNullOrUndefined(offering.locationDetails.venue_name) ||
        offering.locationDetails.venue_name.trim().length === 0
      ) {
        errors["locationDetails"] = {
          id: "locationDetails",
          err: "Add the venue details for your offering. ",
        };
      } else if (
        !offering.locationDetails.isOnline &&
        (isNullOrUndefined(offering.locationDetails.address) ||
          offering.locationDetails.address.trim().length === 0)
      ) {
        errors["locationDetails"] = {
          id: "locationDetails",
          err: "Add the venue address ",
        };
      } else {
        points += 1;
      }
    }
  } else {
    errors["locationDetails"] = {
      id: "locationDetails",
      err: "Add Location details for your offering .",
    };
  }

  // page goals validation.
  if (!isArrayNullOrUndefined(offering.ctas)) {
    if (offering.ctas.some((cta) => cta.type === CTA_Button_Types.PAYMENTS)) {
      if (
        checkHasEventCourseWebinarSubscriptionPlans(offering.scheduleDetails)
      ) {
        points += 1;
      } else if (checkHasTravePlans(offering.scheduleDetails)) {
        points += 1;
      } else {
        errors["pricing"] = {
          id: "pricing",
          err: "Please add pricing for this offering",
        };
      }
    } else {
      points += 1;
    }
  } else {
    errors["ctas"] = {
      id: "ctas",
      err: "Select atleast one action for your offering. ",
    };
  }

  if (
    offeringType === FeaturesKeys.subscription.name.toLowerCase() ||
    offeringType === FeaturesKeys.course.name.toLowerCase()
  ) {
    let isEmpty;
    for (let schedule of offering.scheduleDetails) {
      let sum = 0;
      schedule.weekdays.forEach((weekday) => (sum += weekday));
      if (sum === 0) {
        isEmpty = true;
        break;
      }
    }
    if (isEmpty) {
      errors["weekdays"] = {
        id: "weekdays",
        err: "Please select all the weekdays",
      };
    } else {
      points += 1;
    }
  } else {
    points += 1;
  }

  if (offeringType === FeaturesKeys.subscription.name.toLowerCase()) {
    if (isArrayNullOrUndefined(offering.scheduleDetails[0].timeSlots)) {
      errors["schedule"] = {
        id: "schedule",
        err: "Select atleast one session for your offering.",
      };
    }
  } else {
    if (isArrayNullOrUndefined(offering.scheduleDetails)) {
      errors["schedule"] = {
        id: "schedule",
        err: "Select atleast one session for your offering",
      };
    }
  }

  if (
    (offeringType !== FeaturesKeys.travel.name.toLowerCase() &&
      offeringType !== FeaturesKeys.oneonone.name.toLowerCase() &&
      isNullOrUndefined(offering.durationInMins)) ||
    offering.durationInMins <= 0
  ) {
    errors["schedule"] = { id: "schedule", err: "Please enter a duration." };
  } else {
    points += 1;
  }

  if (offering.notes) {
    let isEmptyTitle = false;
    offering.notes.forEach((note) => {
      if (note.title.length === 0) {
        isEmptyTitle = true;
      }
    });
    if (isEmptyTitle) {
      errors["notes"] = {
        id: "notes",
        err: "Title cannot be empty.",
      };
    } else {
      points += 1;
    }
  } else {
    points += 1;
  }

  if (offering.youtubeLink) {
    const regex =
      /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/|shorts\/|.+\?v=)?([a-zA-Z0-9_-]{11})$/;
    if (regex.test(offering.youtubeLink)) {
      points += 1;
    } else {
      errors["youtubeLink"] = {
        id: "youtubeLink",
        err: "invalid youtube link.",
      };
    }
  } else {
    points += 1;
  }

  return {
    complete: (points / total_points) * 100,
    errors,
  };
};

export const getQueryParams = (params) => {
  const queryParams = new URLSearchParams(params).toString();
  return queryParams;
};

export const getOfferingFromOfferingId = (features, offeringId) => {
  let offering;
  Object.values(features).forEach((feature) =>
    feature.items?.forEach((item) => {
      if (item.id === offeringId) {
        offering = item;
      }
    })
  );
  return offering;
};

export function capitalizeWords(str) {
  if (isNullOrUndefined(str)) return;
  return str
    .split(" ")
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    })
    .join(" ");
}

export const toCamelCase = (text) => {
  return text
    .toLowerCase()
    .split(" ")
    .map((word, index) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};
export const returnContentInArray = (content, divWidth, linesPerPage) => {
  if (linesPerPage < 0) return [];
  var charWidth = 10;
  const charsPerLine = Math.floor(divWidth / charWidth);

  // Calculate total characters per page
  const totalCharsPerPage = charsPerLine * linesPerPage;

  // Prepare the array to hold each page's content
  let pages = [];
  let startIndex = 0;

  while (startIndex < content.length) {
    // Check if the remaining content is less than a full page
    if (startIndex + totalCharsPerPage >= content.length) {
      pages.push(content.substring(startIndex));
      break;
    }

    // Find the end index respecting word boundaries
    let endIndex = startIndex + totalCharsPerPage;
    while (
      endIndex > startIndex &&
      content[endIndex] !== " " &&
      content[endIndex] !== "\n"
    ) {
      endIndex--;
    }

    // If no spaces found, revert to original endIndex
    if (endIndex === startIndex) {
      endIndex = startIndex + totalCharsPerPage;
    }

    // Add substring to pages
    pages.push(content.substring(startIndex, endIndex));

    // Update startIndex to be just after the last character we added to the page
    startIndex = endIndex + 1;
  }

  return pages;
};

export const downloadFiles = async (dataUrls, fileName) => {
  try {
    if (isArrayNullOrUndefined(dataUrls)) return { error: "No File found" };
    if (dataUrls.length === 1) {
      //Download single file
      const link = document.createElement("a");
      link.download = fileName + ".jpg";
      link.href = dataUrls[0];
      link.click();
      return { status: true };
    } else {
      //Download as zip.
      const zip = JSZip();
      for (let url of dataUrls) {
        zip.file(
          `image${dataUrls.indexOf(url) + 1}.png`,
          url.split("base64,")[1],
          {
            base64: true,
          }
        );
      }
      return await zip
        .generateAsync({ type: "blob" })
        .then(function (content) {
          saveAs(content, `${fileName}.zip`);
          return { status: true };
        })
        .catch((error) => {
          return { error: error };
        });
    }
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const convertComponentToImages = async (componentRefs) => {
  try {
    if (isArrayNullOrUndefined(componentRefs)) {
      return { error: "Nothing to download" };
    }
    const dataUrls = [];
    for (let ref of componentRefs) {
      if (ref) {
        try {
          const canvas = await html2canvas(ref, {
            scale: 3,
            imageTimeout: 0,
            useCORS: true,
            logging: true, // Enables logging for debugging
            letterRendering: 1,
            backgroundColor: "none",
          });
          dataUrls.push(canvas.toDataURL("image/png"));
        } catch (error) {
          console.log(error);
          return { error: error };
        }
      }
    }
    return { urls: dataUrls };
  } catch (error) {
    console.log(error);
    return { error: error };
  }
};

export const showErrorSnackbar = (errorMsg) => {
  enqueueSnackbar("Error : " + errorMsg, { variant: "error" });
};

export const BillingCycle = {
  Monthly: "Monthly",
  Annualy: "Yearly",
};

export const getDifferenceInDates = (date) => {
  return differenceInDays(new Date(date), new Date());
};

export const setEndofDay = (epochTime) => {
  const endDate = new Date();
  endDate.setDate(new Date(epochTime).getDate());
  endDate.setHours(23, 59, 59, 999);
  return endDate;
};
export const getFeatureIdFromName = (featureName) => {
  switch (featureName) {
    case FeaturesKeys.event.name:
      return FeaturesKeys.event.id;
    case FeaturesKeys.course.name:
      return FeaturesKeys.course.id;
    case FeaturesKeys.webinar.name:
      return FeaturesKeys.webinar.id;
    case FeaturesKeys.travel.name:
      return FeaturesKeys.travel.id;
    case FeaturesKeys.subscription.name:
      return FeaturesKeys.subscription.id;
    case FeaturesKeys.oneonone.name:
      return FeaturesKeys.oneonone.id;
    default:
      return "";
  }
};

export const getFeatureNameFromId = (featureId) => {
  switch (featureId) {
    case FeaturesKeys.event.id:
      return FeaturesKeys.event.name;
    case FeaturesKeys.course.id:
      return FeaturesKeys.course.name;
    case FeaturesKeys.webinar.id:
      return FeaturesKeys.webinar.name;
    case FeaturesKeys.travel.id:
      return FeaturesKeys.travel.name;
    case FeaturesKeys.subscription.id:
      return FeaturesKeys.subscription.name;
    default:
      return "";
  }
};

export const DateFilters = {
  Today: "Today",
  Yesterday: "Yesterday",
  LastWeek: "Last Week",
  LastMonth: "Last Month",
  AllTime: "All Time",
};

export const PaymentStatus = {
  Processing: "processing",
  Success: "PAYMENT_SUCCESS",
  Failed: "PAYMENT_FAILED",
};

export const FetchPaymentApiState = {
  Calling: "calling",
  Success: "success",
  Failed: "failed",
};

export const getClientTimezone = () => {
  return "Asia/Kolkata";
};

export const imageTypes = [
  "jpg",
  "jpeg",
  "png",
  "gif",
  "bmp",
  "webp",
  "svg",
  "tiff",
];
export const videoTypes = ["mp4", "avi", "mov", "wmv", "flv", "mkv", "webm"];
export const documentTypes = [
  "pdf",
  "doc",
  "docx",
  "xls",
  "xlsx",
  "ppt",
  "pptx",
  "txt",
  "rtf",
  "csv",
];

export const clientLoginSources = {
  INSTA_DM: "instaDm",
  BUSINESS_SUITE: "businessSuite",
};

export const getClientId = () => {
  return new Promise((resolve) => {
    window.gtag("get", "G-5HF0W52QKK", "client_id", (clientId) => {
      resolve(clientId);
    });
  });
};

export const logAnalytics = async (event, eventFields) => {
  const clientId = await getClientId();
  const userId = sessionStorage.getItem("uid");
  const username = sessionStorage.getItem("username");
  const utmSource = sessionStorage.getItem("utm_source") || "direct";
  const utmMedium = sessionStorage.getItem("utm_medium") || "none";
  const utmCampaign = sessionStorage.getItem("utm_campaign") || "none";
  await callNonStoreApi(
    LogInstaAnalyticsEvent,
    {
      userId,
      clientId,
      username,
      event,
      utmSource,
      utmMedium,
      utmCampaign,
      eventFields,
    },
    {
      // withCredentials: true,
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};

//this function logs the meta pixel event.
export const logMetaPixelEvent = (event, isCustom, params = {}) => {
  const fbclid = sessionStorage.getItem("fbclid") || "";
  const utmSource = sessionStorage.getItem("utm_source") || "direct";
  const utmMedium = sessionStorage.getItem("utm_medium") || "none";
  const utmCampaign = sessionStorage.getItem("utm_campaign") || "none";
  window.fbq(isCustom ? "trackCustom" : "track", event, {
    ...params,
    fbc: fbclid ? `fb.${1}.${Date.now()}.${fbclid}` : null,
    external_id: sessionStorage.getItem("uid"),
    content_name: window.location.pathname,
    utm_source: utmSource,
    utm_medium: utmMedium,
    utm_campaign: utmCampaign,
  });
};
