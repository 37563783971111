import React from "react";

import { ChevronRight, WhatsApp } from "@mui/icons-material";
import { AddFlex, CustomText, Group } from "../reusableStyles";
import { handleOpenInNew } from "../utils";

function ContactGrowezyWhatsappButton({ whatsAppText }) {
  return (
    <Group
      style={{ cursor: "pointer" }}
      padding="15px"
      backgroundColor="#128c7e"
      marginTop="20px"
      onClick={() =>
        handleOpenInNew(
          `https://api.whatsapp.com/send?phone=+91${
            process.env.REACT_APP_WHATSAPP_PHONE_NUMBER
          }&text=${whatsAppText.replaceAll(" ", "%20")}`
        )
      }
    >
      <AddFlex alignItems="center">
        <WhatsApp sx={{ fontSize: "20px", color: "white" }} />
        <AddFlex grow="1" alignItems="center">
          <CustomText
            margin="0 0 0 20px"
            fontSize="16px"
            color="white"
            fontWeight="500"
          >
            Contact Growezy.club
          </CustomText>
        </AddFlex>
        <ChevronRight sx={{ color: "white" }} />
      </AddFlex>
    </Group>
  );
}

export default ContactGrowezyWhatsappButton;
