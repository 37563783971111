// src/store/instagramSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import { isNullOrUndefined } from "../utils";

// Create an async thunk for fetching Instagram media
export const fetchInstagramMedia = createAsyncThunk(
  "instagram/fetchMedia",
  async ({ userId, accessToken, limit, nextPageOffset, cursor }) => {
    let url = `https://graph.instagram.com/${userId}/media?fields=id,media_type,media_url,thumbnail_url&access_token=${accessToken}`;
    if (limit) {
      url += `&limit=${limit}`;
    }
    if (cursor) {
      url += `&after=${cursor}`;
    }

    const response = await axios.get(url);
    return response.data;
  }
);

export const fetchStories = async (userId, accessToken) => {
  let url = `https://graph.instagram.com/${userId}/stories?fields=id,media_type,media_url,thumbnail_url&access_token=${accessToken}`;
  const response = await axios.get(url);
  return response.data.data;
};

const instagramSlice = createSlice({
  name: "instagram",
  initialState: {
    postsWithAutomations: [],
    posts: [],
    reels: [],
    stories: [],
    comments: {}, // { mediaId: [{ keyword, reply }] }
    loading: false,
    error: null,
    otherData: {},
  },
  reducers: {
    clientInstaPlan(state, action) {
      state.otherData.plan = action.payload;
    },
    saveKeywordReply(state, action) {
      const { mediaId, keyword, reply } = action.payload;
      if (!state.comments[mediaId]) {
        state.comments[mediaId] = [];
      }
      state.comments[mediaId].push({ keyword, reply });
    },
    clearComments(state, action) {
      delete state.comments[action.payload];
    },
    setStories(state, action) {
      const stories = action.payload;
      state.stories = stories;
    },
    clearPosts(state) {
      state.posts = [];
      state.reels = [];
      state.stories = [];
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInstagramMedia.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchInstagramMedia.fulfilled, (state, action) => {
        const newPosts = action.payload.data || [];
        const existingPostIds = new Set(state.posts.map((post) => post.id));
        state.otherData.nextPostsSet = action.payload?.paging?.cursors?.after;
        // Filter out duplicates
        const uniquePosts = newPosts.filter(
          (post) => !existingPostIds.has(post.id)
        );

        state.posts.push(...uniquePosts);
        state.loading = false;
      })
      .addCase(fetchInstagramMedia.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

// Export actions
export const {
  clientInstaPlan,
  saveKeywordReply,
  clearComments,
  setStories,
  clearPosts,
} = instagramSlice.actions;

// Export the reducer
export default instagramSlice.reducer;
