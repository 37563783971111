import React from "react";
import useWindowSize from "../Hooks/useWindowSize";
import styled from "styled-components";
import { Backdrop } from "@mui/material";
import {
  getFrontendBaseURL,
  handleOpenInNew,
  isNullOrUndefined,
  isStringNullOrUndefined,
  logout,
} from "../utils";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import { AddFlex, CustomText } from "../reusableStyles";
import { headerTextColor, primaryActionColor } from "../constants";
import { ACCOUNT_CENTER_ROUTE } from "../OnboardingPage/routeNames";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";
import { DoorBack, OpenInNew } from "@mui/icons-material";
import user from "../ImageAssets/user.jpg";
import SidebarSection from "./SidebarSection";
import { getStoreRef } from "../Store/persiststore";
const SideView = styled.div`
  display: flex;
  width: 18%;
  flex-shrink: 0;
  flex-direction: column;
  justify-content: space-between;
  transition: transform 0.3s ease-in-out;
  background-color: white;
  transform: ${({ shouldDisplay }) =>
    shouldDisplay ? "translateX(0)" : "translateX(-100%)"};
  /* border-right: 1px solid #ececec; */
  box-shadow: 2px 0 8px 1px rgba(46, 93, 205, 0.1);
  @media (max-width: 1000px) {
    width: 65%; // Adjust based on your preference for mobile
  }
  z-index: 1111111;
`;

function LeftSideView({
  isVisible,
  setSelectedTabRoute,
  sideNavTabs,
  invertSideBarVisibility,
  selectedTabRoute,
}) {
  const apiUserData = useSelector((state) => state.user.apiUserData);
  const { isDesktopView, isMobileView } = useWindowSize();
  const navigate = useNavigate();

  const getSideNavTabs = (sideNavTabs) => {
    if (sideNavTabs[2]) {
      sideNavTabs[2].items = sideNavTabs[2]?.items.filter(
        (item) => isNullOrUndefined(item.hide) || !item.hide
      );
    }
    return sideNavTabs;
  };

  return (
    <>
      <Backdrop
        open={isMobileView && isVisible}
        onClick={() => invertSideBarVisibility()}
        style={{ zIndex: 2 }}
      />
      <SideView shouldDisplay={isVisible || isDesktopView()}>
        <AddFlex
          flexDirection="column"
          padding="1.5% 0px 1% 16px"
          style={{ flexGrow: 1, marginTop: "20px", overflow: "scroll" }}
          shouldDisplay={isVisible || isDesktopView()}
        >
          {apiUserData.UserAdditionalDetails &&
            apiUserData.UserAdditionalDetails.onboardingSource !==
              "instaDm" && (
              <AddFlex alignItems="center" style={{ marginBottom: "20px" }}>
                <img
                  width={"48px"}
                  height={"48px"}
                  src={
                    isStringNullOrUndefined(apiUserData.photoURL)
                      ? user
                      : apiUserData.photoURL
                  }
                  style={{
                    marginRight: "10px",
                    flexShrink: 0,
                    objectFit: "cover",
                    borderRadius: "100%",
                  }}
                  alt={`growezy-client${apiUserData.Name}`}
                />
                <AddFlex gap="5px" flexDirection="column">
                  <h4 style={{ fontWeight: "500", fontSize: "16px" }}>
                    {apiUserData.Name}
                  </h4>
                  <AddFlex
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      handleOpenInNew(getFrontendBaseURL(apiUserData.userName));
                    }}
                    gap="6px"
                    alignItems="center"
                  >
                    <CustomText
                      fontSize="10px"
                      lineClamp="2"
                      fontWeight="600"
                      color="black"
                    >
                      VIEW WEBSITE
                    </CustomText>
                    <OpenInNew sx={{ fontSize: "12px" }} />
                  </AddFlex>
                </AddFlex>
              </AddFlex>
            )}
          <AddFlex
            style={{ flexGrow: 1, overflow: "scroll" }}
            flexDirection="column"
          >
            {getSideNavTabs(Object.values(sideNavTabs)).map((item, index) => (
              <SidebarSection
                item={item}
                selectedTabRoute={selectedTabRoute}
                setSelectedTabRoute={setSelectedTabRoute}
                invertSideBarVisibility={invertSideBarVisibility}
                isDesktopView={isDesktopView}
              />
            ))}
          </AddFlex>
        </AddFlex>
        <AddFlex
          flexDirection="column"
          style={{
            cursor: "pointer",
            borderTop: "1px solid #FFCC4D",
          }}
        >
          {(apiUserData.domain === "login.blissarte.shop" ||
            apiUserData.domain === "coachlogin.bodhischoolofyoga.com") && (
            <AddFlex
              alignItems="center"
              justify="flex-start"
              gap="10px"
              padding="10px 25px"
              // margin="10px 0 0px 28px"
            >
              <CustomText color="black" fontSize="14px" fontWeight={500}>
                {apiUserData.domain === "login.blissarte.shop" ||
                apiUserData.domain === "coachlogin.bodhischoolofyoga.com"
                  ? "Powered by"
                  : ""}
              </CustomText>
              <img
                src={apiUserData.subdomainClient?.data?.small_logo}
                style={{
                  height: "35px",
                  objectFit: "cover",
                  aspectRatio: "2.6:1",
                }}
              />
            </AddFlex>
          )}
          {apiUserData.UserAdditionalDetails &&
          apiUserData.UserAdditionalDetails.onboardingSource !== "instaDm" ? (
            <AddFlex
              padding="14px"
              gap="10px"
              alignItems="center"
              style={{ borderTop: "1px solid #FFCC4D" }}
              onClick={() => {
                if (!isDesktopView()) {
                  invertSideBarVisibility();
                }
                navigate(ACCOUNT_CENTER_ROUTE);
              }}
            >
              <AccountCircleIcon sx={{ color: primaryActionColor }} />
              <AddFlex gap="5px" flexDirection="column">
                <CustomText fontSize="14px" fontWeight="500" color={"black"}>
                  Account Center
                </CustomText>
                <CustomText fontSize="10px" color={headerTextColor}>
                  {isNullOrUndefined(apiUserData.pricingPlan)
                    ? "NO ACTIVE PLAN"
                    : apiUserData.pricingPlan.name}
                </CustomText>
              </AddFlex>
            </AddFlex>
          ) : (
            <AddFlex
              padding="14px"
              gap="10px"
              alignItems="center"
              style={{ borderTop: "1px solid #FFCC4D" }}
              onClick={() => {
                if (!isDesktopView()) {
                  invertSideBarVisibility();
                }
                logout(getStoreRef());
              }}
            >
              <LogoutIcon />
              <CustomText color="black">Logout</CustomText>
            </AddFlex>
          )}
        </AddFlex>
      </SideView>
    </>
  );
}

export default LeftSideView;
