import React, { useEffect } from "react";
import { AddFlex, CustomText } from "../reusableStyles";
import {
  SELECTED_SIDENAV_ITEM_BG,
  SELECTED_SIDENAV_ITEM_TEXT_COLOR,
  StyledLink,
  UnselectedColor,
} from "./SidebarNavLink";
import { callNonStoreApi } from "../NonStoreApis";
import { clientInstaPlan } from "../Store/instagramSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { FetchUserInstaCredits } from "../Api/ApiEndPoints";

function InstaCreditsComponent({ item, isSelected, onClick }) {
  const { plan: userPlan } = useSelector((state) => state.instagram.otherData);
  const dispatch = useDispatch();
  const handleSetClientUserCredits = async () => {
    try {
      const response = await callNonStoreApi(FetchUserInstaCredits);
      dispatch(clientInstaPlan(response.data));
    } catch (error) {
      console.log(error);
    } finally {
    }
  };

  const getRemainingCredits = () => {
    return userPlan?.planType === "free"
      ? userPlan?.totalCreditsRemaining
      : "UNLIMITED";
  };
  useEffect(() => {
    handleSetClientUserCredits();
  }, []);
  return (
    <StyledLink
      // padding="5px"
      to={`${item.route.split("/")[1]}`}
      onClick={() => {
        onClick();
      }}
      style={{
        margin: "0 0 5px 0",
        borderRight: "none",
      }}
      isSelected={isSelected}
      backgroundColor={isSelected ? SELECTED_SIDENAV_ITEM_BG : "transparent"}
    >
      <div
        style={{
          margin: "0 10px 0 0",
          // height: "30px",
          display: "flex",
          alignItems: "center",
          color: isSelected
            ? SELECTED_SIDENAV_ITEM_TEXT_COLOR
            : UnselectedColor,
        }}
      >
        {React.cloneElement(item.icon, {
          sx: {
            fontSize: "18px",
          },
        })}
      </div>
      <AddFlex flexDirection="column">
        <CustomText
          color={
            isSelected ? SELECTED_SIDENAV_ITEM_TEXT_COLOR : UnselectedColor
          }
          fontWeight={isSelected ? "700" : "400"}
          fontSize="14px"
          style={{ lineHeight: "17px" }}
        >
          {item.name}
        </CustomText>
        <CustomText
          color={
            isSelected ? SELECTED_SIDENAV_ITEM_TEXT_COLOR : UnselectedColor
          }
          fontSize="12px"
        >
          Credits Remaining: {getRemainingCredits()}
        </CustomText>
      </AddFlex>
    </StyledLink>
  );
}

export default InstaCreditsComponent;
