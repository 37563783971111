import React from "react";
import { AddFlex, CustomText } from "../../reusableStyles";
import TrendingUpOutlinedIcon from "@mui/icons-material/TrendingUpOutlined";
function InstagramContactsStats({ isAllContactsView, statsCards, statsData }) {
  return (
    <AddFlex gap="8px" style={{ overflowX: "scroll", flexShrink: 0 }}>
      {statsCards.map((stat, index) => (
        <AddFlex
          padding="20px"
          border="1px solid #ececec"
          borderRadius={"24px"}
          backgroundColor={"white"}
          flexDirection="column"
          gap="10px"
          style={{ flexShrink: 0 }}
        >
          <AddFlex gap="10px" alignItems="center">
            {stat.icon && (
              <AddFlex
                width="30px"
                height="30px"
                alignItems="center"
                justify="center"
                backgroundColor={stat.backgroundColor}
                style={{
                  borderRadius: "50%",
                  color: stat.color,
                  flexShrink: 0,
                }}
              >
                {stat.icon}
              </AddFlex>
            )}
            <CustomText color="black" fontSize="12px" fontWeight={"500"}>
              {stat.name}
            </CustomText>
          </AddFlex>
          <AddFlex gap="5px" alignItems="flex-start">
            <CustomText fontSize={"24px"} color={stat.color} fontWeight={"500"}>
              {statsData[stat.key]?.total?.count || 0}
            </CustomText>
            {!isAllContactsView &&
              stat.key === "comments" &&
              statsData[`unique_intertaction_${stat.key}`]?.total?.count >
                0 && (
                <AddFlex
                  alignItems="center"
                  gap="5px"
                  padding="2px 8px"
                  backgroundColor={"#DCFCE8"}
                  borderRadius="25px"
                >
                  <TrendingUpOutlinedIcon
                    sx={{ fontSize: "12px", color: "#166534" }}
                  />
                  <CustomText
                    fontSize={"12px"}
                    color={"#166534"}
                    fontWeight={"500"}
                  >
                    {statsData[`unique_intertaction_${stat.key}`]?.total
                      ?.count || 0}{" "}
                    New
                  </CustomText>
                </AddFlex>
              )}
          </AddFlex>
        </AddFlex>
      ))}
    </AddFlex>
  );
}

export default InstagramContactsStats;
