import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { fetchStories, setStories } from "../../Store/instagramSlice";
import { useDispatch } from "react-redux";
import { isArrayNullOrUndefined } from "../../utils";
import { AddFlex, CustomInputLabel } from "../../reusableStyles";
import { Radio, Skeleton } from "antd";
import { Image } from "./UserInstaPosts";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import PostsContent from "./PostsContent";
import { Dialog, Drawer } from "@mui/material";
import useWindowSize from "../../Hooks/useWindowSize";
import InstaResentPostsOrStories from "./InstaResentPostsOrStories";

function AutomationMediaTypeStories({
  mediaData,
  setMediaData,
  disabled,
  isIntegrationActivated,
}) {
  const dispatch = useDispatch();
  const { isMobileView } = useWindowSize();
  const { isDialogOpen, openDialog, closeDialog } = useDialogActionsHook();
  const [loading, setLoading] = useState(true);
  const { stories } = useSelector((state) => state.instagram);
  const { integrations } = useSelector((state) => state.user.integrations);
  const instaIntegration = integrations.find(
    (item) => item.id === "instagram_connect"
  );

  const handleSetInitial = async () => {
    if (!isArrayNullOrUndefined(stories)) {
      console.log("logged");
      setLoading(false);
      return;
    }
    const _stories = await fetchStories(
      instaIntegration.instaUserId,
      instaIntegration.accessToken
    );
    dispatch(setStories(_stories));
    setLoading(false);
  };

  const handleGetSelectedStory = (mediaId) => {
    return stories.find((media) => media.id === mediaId);
  };

  useEffect(() => {
    handleSetInitial();
  }, []);

  return (
    <>
      <AddFlex flexDirection="column" borderRadius="5px" gap="10px">
        <AddFlex
          flexDirection="column"
          borderRadius="5px"
          backgroundColor="#f6f7f9"
          gap="0px"
        >
          <AddFlex
            padding="10px 10px 10px 10px"
            gap="10px"
            alignItems="center"
            onClick={() => {
              if (disabled) return;
              setMediaData((prev) => ({
                ...prev,
                type: "specific",
                mediaId: null,
              }));
            }}
            style={{ cursor: "pointer" }}
          >
            <Radio
              disabled={disabled}
              type="radio"
              checked={mediaData.type === "specific"}
            />
            <CustomInputLabel
              style={{ margin: 0, fontWeight: "500", fontSize: "15px" }}
              color="black"
            >
              A Specific Story
            </CustomInputLabel>
          </AddFlex>
          {mediaData.type === "specific" &&
            (isIntegrationActivated() ? (
              disabled ? (
                loading ? (
                  <Skeleton.Node style={{ width: "23%", aspectRatio: 0.7 }} />
                ) : (
                  <AddFlex padding="0 10px 10px 10px">
                    <div
                      style={{
                        width: "23%",
                        aspectRatio: 0.7,
                        overflow: "hidden",
                        borderRadius: "8px",
                        border: "3px solid transparent",
                      }}
                    >
                      <Image
                        src={
                          handleGetSelectedStory(mediaData.mediaId)
                            ?.media_type === "VIDEO"
                            ? handleGetSelectedStory(mediaData.mediaId)
                                ?.thumbnail_url
                            : handleGetSelectedStory(mediaData.mediaId)
                                ?.media_url
                        }
                        style={{
                          width: "100%",
                          height: "100%",
                          cursor: "pointer",
                          objectFit: "cover",
                          borderRadius: "6px",
                        }}
                      />
                    </div>
                  </AddFlex>
                )
              ) : (
                <>
                  <AddFlex gap="1%" padding="0 10px 10px 10px" width="100%">
                    {loading ? (
                      <>
                        {[0, 0, 0, 0].map(() => (
                          <Skeleton.Node
                            style={{ width: "100%", aspectRatio: 0.7 }}
                          />
                        ))}
                      </>
                    ) : (
                      <InstaResentPostsOrStories
                        items={stories.slice(0, 4)}
                        mediaData={mediaData}
                        disabled={disabled}
                        setMediaData={setMediaData}
                        openDialog={openDialog}
                      />
                    )}
                  </AddFlex>
                </>
              )
            ) : (
              <AddFlex
                padding="10px"
                margin="0 10px 10px 10px"
                backgroundColor={"#F72C5B"}
                borderRadius="4px"
                style={{ color: "white", fontSize: "14px" }}
              >
                No account linked .
              </AddFlex>
            ))}
        </AddFlex>
        <AddFlex
          flexDirection="column"
          borderRadius="5px"
          backgroundColor="#f6f7f9"
          padding="10px"
          onClick={() => {
            if (disabled) return;
            setMediaData((prev) => ({
              ...prev,
              type: "allStories",
              mediaId: "allStories",
              mediaUrl:
                "https://firebasestorage.googleapis.com/v0/b/growezy-frontend-396211.appspot.com/o/Screenshot%202024-12-03%20at%207.01.31%20PM-min.png?alt=media&token=70dd8fba-7950-4592-b9a4-00c02a33ef4a",
            }));
          }}
          style={{ cursor: "pointer" }}
        >
          <AddFlex gap="10px" alignItems="center">
            <Radio
              disabled={disabled}
              type="radio"
              checked={mediaData.type === "allStories"}
            />
            <CustomInputLabel
              style={{ margin: 0, fontWeight: "500", fontSize: "15px" }}
              color="black"
            >
              Any story
            </CustomInputLabel>
          </AddFlex>
        </AddFlex>
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          anchor="bottom"
          open={isDialogOpen}
          onClose={closeDialog}
          PaperProps={{
            sx: {
              borderRadius: "24px 24px 0 0",
            },
          }}
        >
          <PostsContent
            closeDialog={closeDialog}
            posts={stories}
            isStory={true}
            mediaData={mediaData}
            setMediaData={setMediaData}
          />
        </Drawer>
      ) : (
        <Dialog
          open={isDialogOpen}
          onClose={closeDialog}
          fullWidth
          PaperProps={{
            sx: {
              height: "80vh",
            },
          }}
        >
          <PostsContent
            closeDialog={closeDialog}
            posts={stories}
            mediaData={mediaData}
            isStory={true}
            setMediaData={setMediaData}
          />
        </Dialog>
      )}
    </>
  );
}

export default AutomationMediaTypeStories;
