import React, { useEffect, useState } from "react";
import { AddFlex, CustomText, Gap } from "../reusableStyles";
import { primaryActionColor } from "../constants";
import { Button } from "antd";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  isNullOrUndefined,
  logAnalytics,
  logMetaPixelEvent,
  logout,
} from "../utils";
import { getStoreRef } from "../Store/persiststore";
import { callNonStoreApi } from "../NonStoreApis";
import {
  InstagramIntegrationApi,
  UpdateUserOnboardingStatusForInstaDmLoginApi,
} from "../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { INSTAGRAM_FEATURE_ROUTE } from "./routeNames";
import instagramLogo from "../ImageAssets/insta_logo.png";
import metaLogo from "../ImageAssets/meta_default_logo.png";
import growezyLogo from "../ImageAssets/growezy_logo_blue.png";
import { CircularProgress } from "@mui/material";
import useWindowSize from "../Hooks/useWindowSize";
import ContactGrowezyWhatsappButton from "./ContactGrowezyWhatsappButton";

const integrationErrors = {
  ACCOUNT_ALREADY_LINKED: "accountAlreadyLinked",
};

function InstaDmIntegration() {
  const [searchParams] = useSearchParams();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const { isMobileView } = useWindowSize();
  const { apiUserData } = useSelector((state) => state.user);
  const navigate = useNavigate();

  const startInstaIntegration = async () => {
    setLoading(true);
    // logs a meta pixel event for unsuccessful instagram integration
    callNonStoreApi(InstagramIntegrationApi, null, {
      params: {
        source: !isNullOrUndefined(apiUserData?.userName)
          ? "integrations"
          : "onboarding",
        username: apiUserData?.userName,
      },
    })
      .then((response) => {
        window.location.href = response.data.instagramAuthUrl;
      })
      .catch((error) => {
        console.error(error);
        enqueueSnackbar("There is some error in connecting to Instagram", {
          variant: "error",
        });
      });
  };

  const getErrorText = (error) => {
    switch (error) {
      case integrationErrors.ACCOUNT_ALREADY_LINKED:
        return [
          "Seems link this instagram account is already linked with",
          <b>{searchParams.get("email")}</b>,
        ];

      default:
        return [error];
    }
  };

  const handleLogout = async () => {
    await logout(getStoreRef(), { source: "instaDm" });
  };

  useEffect(() => {
    //logs a pixel event
    if (isNullOrUndefined(searchParams.get("success"))) {
      logMetaPixelEvent("ViewContent");
    }

    if (searchParams.get("success") === "true") {
      //make a success api call
      setLoading(true);
      const user = searchParams.get("username");
      callNonStoreApi(UpdateUserOnboardingStatusForInstaDmLoginApi, {
        username: user,
        utms: {
          utm_source: sessionStorage.getItem("utm_source") || "direct",
          utm_medium: sessionStorage.getItem("utm_medium") || "none",
          utm_campaign: sessionStorage.getItem("utm_campaign") || "none",
        },
      }).then(async (response) => {
        sessionStorage.setItem("uid", response.data.user.userId);
        sessionStorage.setItem("username", user);
        logAnalytics("instagram_connection");
        logMetaPixelEvent("StartTrial", false, {
          free_credits: 1500,
        }); // logs a meta pixel event for successful instagram integration, which is a new lead.
        navigate(INSTAGRAM_FEATURE_ROUTE);
      });
    } else if (searchParams.get("success") === "false") {
      const error = getErrorText(searchParams.get("error"));
      logMetaPixelEvent("InstaIntegrationFailed", true); // logs a meta pixel event for unsuccessful instagram integration
      setError(error);
    }
  }, []);
  return (
    <AddFlex
      width="100%"
      height="100vh"
      flexDirection={isMobileView() && "column"}
    >
      <AddFlex
        width={isMobileView() ? "100%" : "48%"}
        padding={isMobileView() ? "40px" : "100px 50px 100px 80px"}
        style={{ backgroundColor: "#F6F7F9" }}
        height={"100%"}
        flexDirection="column"
      >
        <AddFlex gap="20px" flexDirection="column">
          <AddFlex padding="10px 10px 10px 0" alignItems="center">
            <img
              src={instagramLogo}
              style={{ width: "40px", height: "40px" }}
            />
          </AddFlex>
          <CustomText color="black" fontSize="44px" fontWeight="800">
            Connect your Instagram
          </CustomText>
          <CustomText fontSize="18px">
            Use your instagram to connect to growezy and engage your audience
            more effectively.
          </CustomText>
          <AddFlex
            marginTop="10px"
            alignItems="center"
            style={{
              width: "max-content",
              border: "1px solid " + primaryActionColor,
              borderRadius: "8px",
              cursor: "auto",
              paddingRight: "15px",
            }}
          >
            <img src={metaLogo} style={{ width: "80px" }} />
            <CustomText
              color={primaryActionColor}
              fontSize="14px"
              fontWeight="500"
            >
              Tech provider
            </CustomText>
          </AddFlex>
        </AddFlex>
        <Gap />
        <Button
          type="text"
          style={{ width: "max-content", color: primaryActionColor }}
          onClick={() => {
            handleLogout();
            logMetaPixelEvent("ButtonClick", true, {
              button_name: "CancelInstagramIntegrationPrimary",
            });
          }}
        >
          Cancel
        </Button>
      </AddFlex>
      <AddFlex
        grow={1}
        justify="center"
        alignItems="center"
        style={
          isMobileView()
            ? {
                position: "absolute",
                bottom: 0,
                left: 0,
                width: "100%",
                backgroundColor: "white",
                borderRadius: "30px 30px 0 0",
                padding: "40px",
                boxShadow: "0 0 4px 4px rgba(0,0,0,0.1)",
              }
            : {}
        }
      >
        {error ? (
          <AddFlex
            gap="10px"
            flexDirection="column"
            width={isMobileView() ? "100%" : "60%"}
          >
            <CustomText color="black" fontWeight="500">
              Oopps!
            </CustomText>
            <AddFlex wrap="wrap">
              {error?.map((text) => (
                <CustomText
                  fontWeight="400"
                  color="black"
                  style={{ wordWrap: "break-word", wordBreak: "break-all" }}
                >
                  {text}
                </CustomText>
              ))}
            </AddFlex>
            {searchParams.get("error") ===
            integrationErrors.ACCOUNT_ALREADY_LINKED ? (
              <AddFlex gap="10px" wrap="wrap">
                <Button
                  type="primary"
                  style={{ marginTop: "15px", flexGrow: 1 }}
                  onClick={() => {
                    startInstaIntegration();
                    logMetaPixelEvent("ButtonClick", true, {
                      button_name: "RetryInstagramIntegration",
                    });
                  }}
                >
                  {loading ? (
                    <CircularProgress size={14} sx={{ color: "white" }} />
                  ) : (
                    "Try with another Instagram account"
                  )}
                </Button>
                <Button
                  style={{ marginTop: "15px", width: "max-content" }}
                  onClick={() => {
                    handleLogout();
                    logMetaPixelEvent("ButtonClick", true, {
                      button_name: "CancelInstagramIntegrationSecondary",
                    });
                  }}
                >
                  Go to login page
                </Button>
              </AddFlex>
            ) : (
              <ContactGrowezyWhatsappButton
                whatsAppText={
                  "Hi, I am facing problem with instagram account integration."
                }
              />
            )}
          </AddFlex>
        ) : (
          <AddFlex
            gap="10px"
            flexDirection="column"
            width={isMobileView() ? "100%" : "60%"}
          >
            <CustomText color="black" fontWeight="500">
              A few steps left
            </CustomText>
            <CustomText fontWeight="400">
              Log in with Instagram and set your permissions. Once that's done,
              you're all set to go!
            </CustomText>
            <Button
              type="primary"
              style={{ marginTop: "15px" }}
              onClick={() => {
                startInstaIntegration();
                logMetaPixelEvent("ButtonClick", true, {
                  button_name: "InitiatedInstagramIntegration",
                });
              }}
            >
              {loading ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : (
                "Go to Instagram"
              )}
            </Button>
          </AddFlex>
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default InstaDmIntegration;
