import React, { useEffect, useState } from "react";
import {
  AddFlex,
  CustomInput,
  CustomText,
  SecondaryActionButton,
} from "../../reusableStyles";
import SectionHeader from "../../CreateOffering/Components/SectionHeader";
import ReportSummaryTableHeader from "../../ReportsTab/ReportSummaryTableHeader";
import { Item, Parent } from "../../ReportsTab/ReportsSummaryTableRow";
import ReportsMainTextSubText from "../../ReportsTab/ReportsMainTextSubText";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import {
  isArrayNullOrUndefined,
  isNullOrUndefined,
  isStringNullOrUndefined,
} from "../../utils";
import { isObject } from "lodash";
import { format } from "date-fns";
import InstagramContactNameAndUsername from "./InstagramContactNameAndUsername";
import InstagramContactEmailAndPhoneNumber from "./InstagramContactEmailAndPhoneNumber";
import { Empty } from "antd";
import { Mail, PersonAddRounded, Phone, Search } from "@mui/icons-material";
import { Parser } from "@json2csv/plainjs";
import { enqueueSnackbar } from "notistack";
import InstagramContactEngagedPostLinks from "./InstagramContactEngagedPostLinks";
import InstagramContactTags from "./InstagramContactTags";
import { FetchInstagramContacts } from "../../Api/ApiEndPoints";
import { callNonStoreApi } from "../../NonStoreApis";
import { useSelector } from "react-redux";
import BarChartRoundedIcon from "@mui/icons-material/BarChartRounded";
import { primaryActionColor, primaryActionColorLight } from "../../constants";
import { automationTypes } from "./InstaCommentToDMOtherAutomations";
import InstagramContactsStats from "./InstagramContactsStats";

export const formatPartialContactData = (mainList) => {
  return mainList.map((item, index) => ({
    "Sl. No.": index + 1,
    Name: item.name,
    Username: item.username,
    Email: item.email,
    "Phone Number": item.phoneNumber,
    Date: item.date ? format(new Date(item.date), "dd MMM yyyy") : "-",
  }));
};

function ContactListTable({
  automationStats,
  margin,
  subText,
  headerText,
  showContactInformation,
  automationId,
  otherAutomations,
  mediaId,
  partial,
}) {
  const [tempList, setTempList] = useState([]);
  const [mainList, setMainList] = useState([]);
  const [statsData, setStatsData] = useState(automationStats || {});
  const [statsCards, setStatsCards] = useState(
    isNullOrUndefined(automationId)
      ? [
          {
            name: "Total Comments",
            icon: <QuestionAnswerOutlinedIcon style={{ fontSize: "18px" }} />,
            color: primaryActionColor,
            backgroundColor: primaryActionColorLight,
            key: "comments",
          },
          {
            name: "Total People Engaged",
            icon: <BarChartRoundedIcon style={{ fontSize: "18px" }} />,
            color: primaryActionColor,
            backgroundColor: primaryActionColorLight,
            key: "end",
          },
          {
            name: "Followers gained",
            icon: <PersonAddRounded style={{ fontSize: "18px" }} />,
            color: "#6A1E55",
            key: "followers",
            backgroundColor: "#6A1E5533",
          },
          {
            name: "E-Mails collected",
            icon: <Mail style={{ fontSize: "18px" }} />,
            color: primaryActionColor,
            key: "email",
            backgroundColor: primaryActionColorLight,
          },
          {
            name: "Numbers collected",
            icon: <Phone style={{ fontSize: "18px" }} />,
            color: "#684EA6",
            key: "phoneNumber",
            backgroundColor: "#E2D9F7",
          },
        ]
      : []
  );
  const [isApiLoading, setIsApiLoading] = useState(true);
  const { integrations } = useSelector((state) => state.user.integrations);
  const clientInstaIntegration = integrations.find(
    (integration) => integration.id === "instagram_connect"
  );

  const handleSearch = (text) => {
    if (isStringNullOrUndefined(text)) return setTempList(mainList);

    const arr = mainList?.filter(
      (item) =>
        item.name?.toLowerCase()?.includes(text.toLowerCase()) ||
        item.username?.toLowerCase()?.includes(text.toLowerCase())
    );
    setTempList(arr);
  };

  const handleExportContacts = async () => {
    try {
      const parser = new Parser();
      const mainList = formatPartialContactData(tempList);
      if (mainList.length === 0) {
        return enqueueSnackbar("No data to export", {
          variant: "error",
        });
      }
      const csv = parser.parse(mainList);
      const element = document.createElement("a");
      element.setAttribute("href", `data:text/csv;charset=utf-8,${csv}`);
      element.setAttribute(
        "download",
        partial
          ? `Instagram_Comment_to_DM_Automation_Contacts`
          : `Instagram_Comment_to_DM_Automation_All_Contacts`
      );
      element.style.display = "none";
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  const contactListColumns = {
    SNo: {
      title: "S.No",
      spacing: "1%",
      maxWidth: "70px",
      value: (index) => {
        return index + 1;
      },
    },
    NameAndUsername: {
      title: "Name & Username",
      spacing: "25%",
      value: (index) => {
        return tempList[index].name;
      },
      component: (
        <InstagramContactNameAndUsername
          data={(index) => ({
            name: tempList[index].name,
            username: tempList[index].username,
            profile_pic: tempList[index].profile_pic,
            isNewFollower: tempList[index].isNewFollower,
            capturedDate: tempList[index].date,
          })}
        />
      ),
    },
    EmailAndPhoneNumber: {
      title: "Contact Information",
      spacing: "25%",
      value: (index) => {
        return tempList[index].email || "-";
      },
      component: (
        <InstagramContactEmailAndPhoneNumber
          data={(index) => ({
            email: tempList[index].email,
            phoneNumber: tempList[index].phoneNumber,
          })}
        />
      ),
    },
    DateCaptured: {
      title: "Date Captured",
      spacing: "10%",
      value: (index) => {
        return tempList[index].date
          ? format(new Date(tempList[index].date), "dd MMM yyyy")
          : "-";
      },
    },
    postLinks: {
      title: "Posts engaged",
      spacing: "10%",
      value: (index) => {},
      component: (
        <InstagramContactEngagedPostLinks
          data={(index) => tempList[index].mediaIds}
          username={(index) => tempList[index].username}
        />
      ),
    },
    Tags: {
      title: "Tags",
      spacing: "10%",
      component: (
        <InstagramContactTags data={(index) => tempList[index].tags} />
      ),
    },
  };

  const getColumns = () => {
    if (partial && showContactInformation) {
      return [
        contactListColumns.SNo,
        contactListColumns.NameAndUsername,
        contactListColumns.EmailAndPhoneNumber,
        contactListColumns.DateCaptured,
      ];
    } else if (partial) {
      return [
        contactListColumns.SNo,
        contactListColumns.NameAndUsername,
        contactListColumns.DateCaptured,
      ];
    }
    return [
      contactListColumns.SNo,
      contactListColumns.NameAndUsername,
      contactListColumns.EmailAndPhoneNumber,
      contactListColumns.DateCaptured,
      contactListColumns.Tags,
      contactListColumns.postLinks,
    ];
  };

  const handleSetStats = () => {
    const stats = [
      {
        name: "Total Comments",
        icon: <QuestionAnswerOutlinedIcon style={{ fontSize: "18px" }} />,
        color: primaryActionColor,
        key: "comments",
        backgroundColor: primaryActionColorLight,
      },
      {
        name: "People Engaged",
        icon: <BarChartRoundedIcon style={{ fontSize: "18px" }} />,
        color: primaryActionColor,
        key: "end",
        backgroundColor: primaryActionColorLight,
      },
    ];
    if (
      otherAutomations.some(
        (automation) => automation.type === automationTypes.REQUEST_TO_FOLLOW
      )
    ) {
      stats.push({
        name: "Followers gained",
        icon: <PersonAddRounded style={{ fontSize: "18px" }} />,
        color: "#6A1E55",
        key: "followers",
        backgroundColor: "#6A1E5533",
      });
    }
    if (showContactInformation) {
      stats.push({
        name: "E-Mails collected",
        icon: <Mail style={{ fontSize: "18px" }} />,
        color: primaryActionColor,
        key: "email",
        backgroundColor: primaryActionColorLight,
      });
    }
    if (showContactInformation) {
      stats.push({
        name: "Numbers collected",
        icon: <Phone style={{ fontSize: "18px" }} />,
        color: "#684EA6",
        key: "phoneNumber",
        backgroundColor: "#E2D9F7",
      });
    }

    setStatsCards(stats);
  };

  useEffect(() => {
    const handleGetContactList = async () => {
      const response = await callNonStoreApi(FetchInstagramContacts, {
        mediaId: mediaId,
        automationId: automationId,
        instaUserId: clientInstaIntegration.instaUserId,
      });
      setMainList(response.data.contacts);
      setTempList(response.data.contacts);
      if (isNullOrUndefined(automationStats)) {
        setStatsData(response.data.stats);
      }
      setIsApiLoading(false);
    };
    if (automationId) handleSetStats();
    handleGetContactList();
  }, []);

  return (
    <AddFlex margin={margin} flexDirection="column" width="100%" gap="12px">
      <InstagramContactsStats
        isAllContactsView={isNullOrUndefined(automationId)}
        statsData={statsData}
        statsCards={statsCards}
      />
      <AddFlex flexDirection="column" style={{ overflow: "hidden" }}>
        <AddFlex
          backgroundColor="white"
          padding="10px"
          width="100%"
          gap="10px"
          height="max-content"
          alignItems="center"
        >
          <SectionHeader
            headerText={headerText ? headerText : `${tempList.length} Contacts`}
            style={{
              width: "100%",
              paddingLeft: "10px",
              borderLeft: "4px solid #FFA500",
            }}
            subHeaderText={
              subText ? subText : "People who engaged with this automation"
            }
            maxSubTextWidth={"100%"}
          />
          <CustomInput
            width="70%"
            placeholder={"Search by name or username"}
            onChange={(text) => {
              handleSearch(text);
            }}
            leftWidget={<Search sx={{ color: "#888888" }} />}
          />
          <SecondaryActionButton
            style={{ height: "40px" }}
            onClick={handleExportContacts}
          >
            Export
          </SecondaryActionButton>
        </AddFlex>
        <ReportSummaryTableHeader
          columns={getColumns()}
          style={{
            textAlign: "none",
            justifyContent: "flex-start",
            padding: "0 10px",
          }}
        />
        {isApiLoading ? (
          <AddFlex padding="20px" justify="center" backgroundColor="white">
            <CustomText>Fetching Your Contacts...</CustomText>
          </AddFlex>
        ) : isArrayNullOrUndefined(tempList) ? (
          <AddFlex padding="20px" justify="center" backgroundColor="white">
            <Empty />
          </AddFlex>
        ) : (
          <AddFlex
            flexDirection="column"
            grow={1}
            style={{ overflow: "scroll" }}
          >
            {tempList?.map((item, index) => {
              return (
                <ContactListTableRow
                  isFirstCell={index === 0}
                  isLastCell={index === tempList.length - 1}
                  rowIndex={index}
                  columns={getColumns()}
                />
              );
            })}
          </AddFlex>
        )}
      </AddFlex>
    </AddFlex>
  );
}

export default ContactListTable;

const ContactListTableRow = ({ columns, rowIndex }) => {
  return (
    <Parent
      style={{
        borderLeft: "1px solid #ececec",
        borderBottom: "1px solid #ececec",
        borderRight: "1px solid #ececec",
      }}
    >
      {/* <Checkbox /> */}
      {columns.map((column, index) => {
        return !isNullOrUndefined(column.component) ? (
          React.cloneElement(column.component, {
            index: rowIndex,
            column,
          })
        ) : Array.isArray(column.value(rowIndex)) ? (
          <AddFlex
            flexDirection="column"
            gap="5px"
            style={{ flexGrow: "1", flexBasis: column.spacing }}
          >
            {column.value(rowIndex).map((_item) => (
              <Item
                percent={column.spacing}
                style={{
                  fontSize: "14px",
                  justifyContent: "flex-start",
                  padding: "0 10px",
                  maxWidth: column.maxWidth,
                }}
                fontWeight={"500"}
              >
                {_item}
              </Item>
            ))}
          </AddFlex>
        ) : isObject(column.value(rowIndex)) ? (
          <ReportsMainTextSubText column={column} index={rowIndex} />
        ) : (
          <Item
            percent={column.spacing}
            fontWeight={"500"}
            style={{
              justifyContent: "flex-start",
              padding: "0 10px",
              maxWidth: column.maxWidth,
            }}
          >
            {column.value(rowIndex)}
          </Item>
        );
      })}
    </Parent>
  );
};
