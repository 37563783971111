import React, { useEffect, useState } from "react";
import {
  AddFlex,
  DialogHeader,
  PrimaryActionButton,
} from "../../reusableStyles";
import {
  PaymentStatus,
  getApiCallParams,
  isStringNullOrUndefined,
  logAnalytics,
  logMetaPixelEvent,
} from "../../utils";
import { callNonStoreApi } from "../../NonStoreApis";
import {
  CashfreePaymentCallback,
  CreateInstaCreditsOrderApi,
  UpdateContactDetails,
} from "../../Api/ApiEndPoints";
import { enqueueSnackbar } from "notistack";
import { CircularProgress, Dialog, Drawer } from "@mui/material";

import { load } from "@cashfreepayments/cashfree-js";
import useDialogActionsHook from "../../CustomHooks.js/useDialogActionsHook";
import { DotLottieReact } from "@lottiefiles/dotlottie-react";
import FailedPayment from "./FailedPayment";
import { useDispatch } from "react-redux";
import { clientInstaPlan } from "../../Store/instagramSlice";
import useWindowSize from "../../Hooks/useWindowSize";
import { useSelector } from "react-redux";
import { isValidPhoneNumber } from "react-phone-number-input";
import MobileNumber from "../../MobileNumber";
import { usersApiCall } from "../../Store/userSlice";
import { THUNK_API_STATES } from "../../constants";

const PAYMENT_STAGES = {
  LOADING: "loading",
  INVALID_PHONE_NUMBER: "invalid-phone-number",
  SUCCESS: "success",
  FAILED: "failed",
};

function PurchaseCreditsButton({ planDetails, disabled, successCallback }) {
  const { isMobileView } = useWindowSize();
  const [loading, setLoading] = useState(false);
  const [updatingPhoneNumber, setUpdatingPhoneNumber] = useState(false);
  const [stage, setStage] = useState(null);
  const { isDialogOpen, closeDialog, openDialog } = useDialogActionsHook();
  const { UserAdditionalDetails } = useSelector(
    (state) => state.user.apiUserData
  );
  const [userAdditionalDetails, setUserAdditionalDetails] = useState(
    UserAdditionalDetails
  );

  const dispatch = useDispatch();
  let cashfree;
  var initializeSDK = async function () {
    cashfree = await load({
      mode: process.env.NODE_ENV === "production" ? "production" : "sandbox",
    });
  };
  initializeSDK();

  const checkIfUserHasValidMobileNumber = () => {
    if (isStringNullOrUndefined(UserAdditionalDetails.userPhoneNumber))
      return false;
    if (!isValidPhoneNumber(UserAdditionalDetails.userPhoneNumber)) {
      return false;
    }
    return true;
  };

  const handleSubmitPhoneNumber = async () => {
    setUpdatingPhoneNumber(true);
    if (!isValidPhoneNumber(userAdditionalDetails.userPhoneNumber)) {
      setUpdatingPhoneNumber(false);
      return enqueueSnackbar("Please enter a valid number", {
        variant: "error",
      });
    }
    dispatch(
      usersApiCall(
        getApiCallParams(UpdateContactDetails, userAdditionalDetails)
      )
    ).then((actionResult) => {
      if (actionResult.meta.requestStatus === THUNK_API_STATES.fulfilled) {
        enqueueSnackbar("Contact details saved", {
          variant: "success",
          hideIconVariant: true,
          autoHideDuration: 1500,
        });
      } else {
        enqueueSnackbar("Something went wrong, please try again", {
          variant: "error",
          hideIconVariant: true,
          autoHideDuration: 6000,
        });
      }
      setUpdatingPhoneNumber(false);
    });
  };

  const doPayment = async (payment_session_id, order_id) => {
    let checkoutOptions = {
      paymentSessionId: payment_session_id,
      redirectTarget: "_modal",
    };
    let url = `${CashfreePaymentCallback}?order_id=${order_id}`;
    if (sessionStorage.getItem("fbclid")) {
      url += `&fbclid=${sessionStorage.getItem("fbclid")}`;
    }
    cashfree
      .checkout(checkoutOptions)
      .then(async (result) => {
        if (result.error) {
          // This will be true whenever user clicks on close icon inside the modal or any error happens during the payment
          // This will be called whenever the payment is completed irrespective of transaction status

          const response = await callNonStoreApi(url);
          setStage("FAILED");
          dispatch(clientInstaPlan(response.data.doc));
          console.log(
            "User has closed the popup or there is some payment error, Check for Payment Status"
          );
          logMetaPixelEvent("PaymentAborted", true);
          console.log(result.error);
          closeDialog();
        }
        if (result.redirect) {
          // This will be true when the payment redirection page couldnt be opened in the same window
          // This is an exceptional case only when the page is opened inside an inAppBrowser
          // In this case the customer will be redirected to return url once payment is completed
          console.log("Payment will be redirected");
        }
        if (result.paymentDetails) {
          const response = await callNonStoreApi(url);
          if (response.data.order_status === PaymentStatus.Success) {
            setStage("SUCCESS");
            dispatch(clientInstaPlan(response.data.doc));
            enqueueSnackbar("Credits Purchase Successful", {
              variant: "success",
            });
            logAnalytics("purchase", {
              amount: response.data.order_amount,
              plan: "pro",
              currency: "INR",
            });
            closeDialog();
            successCallback();
          } else {
            setStage("FAILED");
            dispatch(clientInstaPlan(response.data.doc));
            enqueueSnackbar("Credits Purchase Failed!", {
              variant: "error",
              autoHideDuration: 6000,
            });
          }

          console.log("Payment has been completed, Check for Payment Status");
        }
      })
      .catch((error) => {
        setStage(PAYMENT_STAGES.FAILED);
        logMetaPixelEvent("PaymentFailed", true);
        console.log(error);
        enqueueSnackbar("Something went wrong", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getLottieLink = (stage) => {
    switch (stage) {
      case "SUCCESS":
        return "https://lottie.host/ecd9c0b1-954a-428c-a0c3-1927003cb110/qPn9i1QErc.lottie";
      case "LOADING":
        return "https://lottie.host/0673e324-26eb-45ff-a640-c3f7597596ac/zs0i9aAYCe.lottie";
      case "FAILED":
        return "";
      default:
        return "https://lottie.host/0673e324-26eb-45ff-a640-c3f7597596ac/zs0i9aAYCe.lottie";
    }
  };

  const handleCreateOrder = async () => {
    try {
      openDialog();
      setLoading(true);
      if (!checkIfUserHasValidMobileNumber()) {
        setStage(PAYMENT_STAGES.INVALID_PHONE_NUMBER);
        setLoading(false);
        return;
      }
      setStage(PAYMENT_STAGES.LOADING);
      const response = await callNonStoreApi(
        CreateInstaCreditsOrderApi,
        {
          plan: planDetails,
        },
        {
          params: {
            fbclid: sessionStorage.getItem("fbclid"),
          },
        }
      );
      await doPayment(response.data.payment_session_id, response.data.order_id);
    } catch (error) {
      setLoading(false);
      setStage(PAYMENT_STAGES.FAILED);
      enqueueSnackbar("Something went wrong");
      closeDialog();
    }
  };

  useEffect(() => {
    if (stage === PAYMENT_STAGES.INVALID_PHONE_NUMBER) {
      handleCreateOrder();
    }
  }, [UserAdditionalDetails]);

  const getDrawerOrDialogContent = () => {
    switch (stage) {
      case PAYMENT_STAGES.INVALID_PHONE_NUMBER:
        return (
          <AddFlex flexDirection="column" gap="30px" padding="20px">
            <DialogHeader
              mainText={"Enter phone number"}
              subText={
                "Due to the mandatory payment gateway policies, we need your phone number."
              }
            />
            <MobileNumber
              title={"Phone Number"}
              number={UserAdditionalDetails?.userPhoneNumber}
              handleGetMobileNumber={(number) => {
                setUserAdditionalDetails((prev) => ({
                  ...prev,
                  userPhoneNumber: number,
                }));
              }}
            />
            <PrimaryActionButton
              style={{ width: "max-content" }}
              onClick={handleSubmitPhoneNumber}
            >
              {updatingPhoneNumber ? (
                <CircularProgress size={14} sx={{ color: "white" }} />
              ) : (
                "Proceed to pay"
              )}
            </PrimaryActionButton>
          </AddFlex>
        );
      case PAYMENT_STAGES.FAILED:
        return <FailedPayment closeDialog={closeDialog} />;
      default:
        return <DotLottieReact src={getLottieLink(stage)} loop autoplay />;
    }
  };

  return (
    <>
      <AddFlex gap="14px" flexDirection="column">
        {/* <CustomText color="black" fontSize="18px" fontWeight="500">
          Purchasing {selectedCredit.credits} Credits at{" "}
          {formatPrice(selectedCredit.prices["INR"], "INR")}
        </CustomText> */}
        <PrimaryActionButton disabled={disabled} onClick={handleCreateOrder}>
          {loading ? (
            <CircularProgress size={14} sx={{ color: "white" }} />
          ) : disabled ? (
            "Current Plan"
          ) : (
            "Upgrade Now"
          )}
        </PrimaryActionButton>
      </AddFlex>
      {isMobileView() ? (
        <Drawer
          open={isDialogOpen}
          PaperProps={{
            sx: {
              borderRadius: "12px 12px 0 0",
            },
          }}
          anchor="bottom"
          fullWidth
        >
          {getDrawerOrDialogContent()}
        </Drawer>
      ) : (
        <Dialog
          open={isDialogOpen}
          PaperProps={{
            sx: {
              borderRadius: "12px",
              width: "30vw",
            },
          }}
        >
          {getDrawerOrDialogContent()}
        </Dialog>
      )}
    </>
  );
}

export default PurchaseCreditsButton;
