import React, { useState } from "react";

import styled from "styled-components";
import { callNonStoreApi } from "../../NonStoreApis";
import { InstagramIntegrationApi } from "../../Api/ApiEndPoints";
import { useSelector } from "react-redux";
import { AddFlex, FullPageParent, Gap } from "../../reusableStyles";
import instagramLogo from "../../ImageAssets/insta_logo.png";
import { StyledImage } from "../IntegrationCardItem";
import { enqueueSnackbar } from "notistack";
import { useSearchParams } from "react-router-dom";
import Loading from "../../Loading";
import useIntegrationHooks from "../../CustomHooks/useIntegrationHooks";
import useWindowSize from "../../Hooks/useWindowSize";

export const PageContainer = styled.div`
  font-family: "Roboto", sans-serif;
  width: 100%;
  padding: 40px;
  align-self: center;
  @media screen and (max-width: 1000px) {
    padding: 20px;
  }
`;

export const Header = styled(AddFlex)`
  gap: "20px";
  background-color: aliceblue;
  padding: 30px 30px;
  margin-bottom: 60px;
  @media screen and (max-width: 1000px) {
    margin-bottom: 20px;
  }
`;

export const Title = styled.div`
  color: #202124;
  font-size: 30px;
  font-weight: 600;
  margin-right: 30px;
`;

export const OverviewSection = styled.div`
  margin-top: 2rem;
  width: 70%;
  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`;

export const SectionTitle = styled.div`
  color: #000;
  font-size: 20px;
  margin-top: 15px;
  margin-bottom: 8px;
`;

export const Paragraph = styled.div`
  font-size: 18px;
  color: #0066da;
  margin-top: 20px;
  margin-bottom: 15px;
`;

export const BulletList = styled.ul`
  list-style: inside;
`;

export const ListItem = styled.li`
  margin-bottom: 0.5rem;
`;

export const InstallationSection = styled(OverviewSection)``;

export const Button = styled.button`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#1a73e8")};
  color: ${(props) => (props.color ? props.color : "white")};
  padding: 10px 15px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;

  &:hover {
    background-color: ${(props) => (props.isActivated ? "" : "#185abc")};
  }
`;

function InstagramIntegration() {
  const { isMobileView } = useWindowSize();
  const userState = useSelector((state) => state.user);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const getSuccesMsg = () => {
    return "Your Instagram is successfully linked";
  };

  const getFailureMsg = () => {
    return "";
    // "There is some error in connecting to Instagram \n" +
    //   (isNullOrUndefined(searchParams.get("error"))
    //     ? ""
    //     : searchParams.get("error"));
  };

  const { apiLoading, isIntegrationActivated, integrationData } =
    useIntegrationHooks("instagram_connect", getSuccesMsg, getFailureMsg);

  return apiLoading ? (
    <FullPageParent>
      <Loading />
    </FullPageParent>
  ) : (
    <AddFlex justify="flex-start">
      <PageContainer>
        <Header alignItems="center" gap="30px">
          <StyledImage
            src={instagramLogo}
            style={{ width: "40px", height: "40px" }}
          />
          {isMobileView() && <Gap />}
          <Button
            onClick={() => {
              if (isIntegrationActivated()) return;
              setLoading(true);
              callNonStoreApi(InstagramIntegrationApi, null, {
                params: {
                  username: userState.apiUserData.userName,
                  source: "integrations",
                },
              })
                .then((response) => {
                  window.location.href = response.data.instagramAuthUrl;
                })
                .catch((error) => {
                  console.error(error);
                  enqueueSnackbar(
                    "There is some error in connecting to Instagram",
                    { variant: "error" }
                  );
                  setLoading(false);
                });
            }}
            bgColor={isIntegrationActivated() ? "green" : "#101828"}
            color={isIntegrationActivated() ? "white" : "white"}
            isActivated={isIntegrationActivated()}
          >
            {loading
              ? "Connecting..."
              : isIntegrationActivated()
              ? `Connected`
              : "Connect Your Instagram Account"}
          </Button>
        </Header>
        <OverviewSection>
          <SectionTitle>Overview</SectionTitle>
          <div>
            Instagram DM automation enables you to automatically respond to
            messages using pre-defined keywords. By linking your Instagram
            account to Growezy, you can manage all your Instagram direct
            messages in one place and automate responses based on custom
            keywords. This integration simplifies communication with your
            followers and customers, ensuring prompt responses to inquiries or
            comments.
          </div>
          <Paragraph
            style={{
              fontSize: "20px",
              marginTop: "20px",
              marginBottom: "15px",
            }}
          >
            Why you should integrate your instagram?
          </Paragraph>
          <BulletList>
            <ListItem>
              Managing customer interactions can become overwhelming, especially
              when they are spread across multiple platforms. Linking your
              Instagram account to Growezy allows you to:
            </ListItem>
            <ListItem>
              Automate responses based on keywords to ensure no message goes
              unanswered.
            </ListItem>
            <ListItem>
              Streamline customer interactions and save time by using automated
              messaging.
            </ListItem>
          </BulletList>
        </OverviewSection>
        <InstallationSection>
          <Paragraph>How to automate DM replies with keywords</Paragraph>
          <BulletList>
            <ListItem>
              After Successful integration with your Instagram Account,
              "Instagram" Menu item will be visible on the left menu
            </ListItem>
            <ListItem>
              Create a trigger with appropriate keywords and a message that has
              to be sent when sender message contains those keywords
            </ListItem>
            <ListItem>You can create as many triggers as you want</ListItem>
            <ListItem>You can edit, delete those triggers</ListItem>
            <ListItem>
              {"Your life will be simpler with these triggers."}
            </ListItem>
          </BulletList>
        </InstallationSection>
      </PageContainer>
    </AddFlex>
  );
}

export default InstagramIntegration;
